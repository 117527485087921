import React from 'react';
import { aboutUsBigText } from '../../../helpers/constants';

const Leaders = () => {
  return (
    <div className=''>
      <img
        src='/assets/images/about-us/Evolve.svg'
        className='m-auto w-11/12 lg:w-3/5 pb-20 pt-[6.25rem]'
        alt='Evolve'
      />
      <div className={`${aboutUsBigText} pl-6 lg:pl-32 xl:pl-48 pb-[6.25rem]`}>
        <div className='lg:hidden'>
          <div>By merging together data analytics, next</div>
          <div>generation technologies, and a team of</div>
          <div>data experts from around the world,</div>
          <div>we empower you to overcome limits and</div>
          <div>face complexity head-on by designing</div>
          <div>Customised solutions and tools that offer</div>
          <div>leading-edge capabilities. Backed by</div>
          <div>the vigour of AI/ML, our products are</div>
          <div>simple tools that focus on driving down</div>
          <div>cost, significantly while rolling the</div>
          <div>wheels of innovation forward.</div>
        </div>
        <div className='hidden lg:block'>
          <div>
            By merging together data analytics, next generation technologies, and a team
            of data
          </div>
          <div>
            experts from around the world, we empower you to overcome limits and face
            complexity
          </div>
          <div>
            head-on by designing customized solutions and tools that offer leading-edge
            capabilities.
          </div>
          <div>
            Backed by the vigour of AI/ML, our products are simple tools that focus on
            driving down
          </div>
          <div>cost, significantly while rolling the wheels of innovation forward.</div>
        </div>
      </div>
    </div>
  );
};

export default Leaders;
