import React from 'react';

const Button = ({ title, primaryColor, secondaryColor, otherStyles, ...rest }) => {
  return (
    <button
      className={`uppercase border-2 rounded-full py-4 px-6 lg:py-4 lg:px-6 4k:px-12 4k:py-5 text-sm lg:text-base 4k:text-2xl lg:leading-4 lg:tracking-[0.050rem] w-max ${primaryColor} ${secondaryColor} ${otherStyles}`}
      {...rest}
    >
      {title}
    </button>
  );
};

export default Button;
