import React from 'react';
import { smallHeader } from '../../helpers/constants';
// import ImageSlider from '../Join-Us/ImageSlider';
import ImageSlider from '../landing-page/ImageSlider';

const TestiMonial = () => {
  const TestimoniaCard = ({
    width,
    height,
    name,
    children,
    designation = 'designation',
    imgSrc
  }) => {
    return (
      <div className='pl-6 lg:pl-72 4k:pl-left-space pt-16 lg:pt-0 pb-16 lg:pb-28 '>
        <div className={smallHeader + `text-white pt-32 `} style={{ color: 'white' }}>
          testimonial
        </div>
        <div className={'h-24 w-24 mt-6 bg-white rounded-full'}></div>
        <div className='mt-8 text-[1.375rem] leading-[1.875rem] text-white'>
          {children}
        </div>
        <div className='mt-8 lg:mt-16'>
          <div className='text-lg font-semibold text-white'>{name}</div>
          <div className='block lg:hidden'>
            <div className='text-sm  text-white'>
              {designation.split('|').map((desg, index) => {
                return <div key={`designation#${index}`}>{desg}</div>;
              })}
            </div>
          </div>
          <div className='hidden lg:block'>
            <div className='text-sm text-white'>{designation}</div>
          </div>
        </div>
      </div>
    );
  };

  const testimonial1 = (
    <>
      <TestimoniaCard
        width={'w-32 '}
        height={'h-32 '}
        name={'Olivier Fouret'}
        designation='MSD | Oncology Business Unit Director Belgium & Luxembourg'
        imgSrc={'/assets/images/landing-page/Eye.png'}
      >
        <div className='lg:hidden h-[360px]'>
          <div>Working with Solutionec was a </div>
          <div>truly great experience,</div>
          <div>the team is extremely dedicated</div>
          <div>and deliver highly qualitative work.</div>
          <div>I strongly recommend Solutionec</div>
          <div>for any company willing to ease</div>
          <div>their decision making by taking fact </div>
          <div>based decision through data </div>
          <div>visualization !</div>
        </div>
        <div className='hidden lg:block'>
          <div>Working with Solutionec was a truly great experience the</div>
          <div>the team is extremely dedicated and deliver highly qualitative work.</div>
          <div>I strongly recommend Solutionec for any company willing to ease</div>
          <div>their decision making by taking fact based decision through</div>
          <div>data visualization !</div>
        </div>
      </TestimoniaCard>
    </>
  );

  const testimonial2 = (
    <>
      <TestimoniaCard
        width={'w-32 '}
        height={'h-32 '}
        name={'Himed Zalegh'}
        designation='Sanofi | Performance and Strategic Project Director Asia'
        imgSrc={'/assets/images/landing-page/Connectivity.png'}
      >
        <div className='lg:hidden'>
          <div>What really sets Solutionec apart </div>
          <div>is that they always strive to</div>
          <div>understand your needs and make</div>
          <div>the digital journey as seamless</div>
          <div>as possible for your teams.</div>
          <div>Solutionec has a keen understanding</div>
          <div>of how to leverage data in the</div>
          <div>pharma & biotech space.</div>
          <div>Their expert consultants provide</div>
          <div>top-notch business intelligence and</div>
          <div>analytics services for both</div>
          <div>short-term and long-term projects.</div>
        </div>
        <div className='hidden lg:block'>
          <div>
            What really sets Solutionec apart is that they always strive to understand
            your
          </div>
          <div>
            needs and make the digital journey as seamless as possible for your teams.
          </div>
          <div>
            Solutionec has a keen understanding of how to leverage data in the pharma &
          </div>
          <div>
            biotech space. Their expert consultants provide top-notch business
            intelligence
          </div>
          <div>and analytics services for both short-term and long-term projects.</div>
        </div>
      </TestimoniaCard>
    </>
  );

  const testimonial3 = (
    <>
      <TestimoniaCard
        width={'w-28 '}
        height={'h-36 '}
        name={'Pedro DaCosta'}
        designation='Sanofi | Commercial Manager, Sarilumab Europe'
        imgSrc={'/assets/images/landing-page/Report.png'}
      >
        <>
          <div className='lg:hidden h-[360px] flex flex-col'>
            <div>Solutionec team were crucial</div>
            <div>in the execution of a much needed</div>
            <div>market report for our company.</div>
            <div>They are always willing to go</div>
            <div>the extra mile and bring vital</div>
            <div>analytical skills to the projects.</div>
            <div>I highly recommend!</div>
          </div>
          <div className='hidden lg:block h-[150px]'>
            <div>
              Solutionec team were crucial in the execution of a much needed market report
            </div>
            <div>
              for our company. They are always willing to go the extra mile and bring
              vital
            </div>
            <div> analytical skills to the projects. I highly recommend!</div>
          </div>
        </>
      </TestimoniaCard>
    </>
  );

  const Slide1 = (
    <div className='mt-8 grid lg:flex gap-12 lg:gap-8 xl:gap-12 text-white'>
      <div>
        <div className='w-72 lg:w-auto xl:w-72 4k:w-96'>
          <div className={'h-24 w-24 mt-2 bg-white rounded-full'}></div>
          <div className='text-lg lg:text-sm xl:text-lg xxl:text-xl 4k:text-3xl xl:leading-6 py-1 mt-1 xl:mt-4 4k:mt-12 '>
            <div>Working with Solutionec was a truly great experience the </div>
            <div>team is extremely dedicated and deliver highly qualitative work.</div>
            <div>
              I strongly recommend Solutionec for any company willing to ease their{' '}
            </div>
            <div>
              decision making by taking fact based decision through data visualization!
            </div>
          </div>
          <div className='text-sm xxl:text-2xl font-bold py-1 mt-10 lg:mt-4 xl:mt-10 4k:mt-36'>
            Name Of Employee
          </div>
          <div>Designation</div>
        </div>
      </div>
    </div>
  );

  const Slide2 = (
    <div className='mt-8 grid lg:flex gap-12 lg:gap-8 xl:gap-12 text-white'>
      <div>
        <div className='w-72 lg:w-auto xl:w-72 4k:w-96'>
          <div className={'h-24 w-24 mt-2 bg-white rounded-full'}></div>
          <div className='text-lg lg:text-sm xl:text-lg xxl:text-xl 4k:text-3xl xl:leading-6 py-1 mt-1 xl:mt-4 4k:mt-12 '>
            <div>
              What really sets Solutionec apart is that they alwaysstrive to understand
            </div>
            <div> your needs and make the digitaljourney as seamless as possible for</div>
            <div>
              your teams.Solutionec has a keen understanding of how to leverage data{' '}
            </div>
            <div>
              in the pharma and biotech . spaceTheir expert consultants provide top-notch
            </div>
            <div>
              businessintelligence and analytics services for both short- and long-term
              projects.
            </div>
          </div>
          <div className='text-sm xxl:text-2xl font-bold py-1 mt-10 lg:mt-4 xl:mt-10 4k:mt-36'>
            Name Of Employee
          </div>
          <div>Designation</div>
        </div>
      </div>
    </div>
  );

  const Slide3 = (
    <div className='mt-8 grid lg:flex gap-12 lg:gap-8 xl:gap-12 text-white'>
      <div>
        <div className='w-72 lg:w-auto xl:w-72 4k:w-96'>
          <div className={'h-24 w-24 mt-2 bg-white rounded-full'}></div>
          <div className='text-lg lg:text-sm xl:text-lg xxl:text-xl 4k:text-3xl xl:leading-6 py-1 mt-1 xl:mt-4 4k:mt-12 '>
            <div>
              Solutionec team were crucial in the execution of a much needed market{' '}
            </div>
            <div>
              report for our company.They are always willing to go the extra mile{' '}
            </div>
            <div>
              and bring vital analytical skills to the projects. I highly recommend!
            </div>
          </div>
          <div className='text-sm xxl:text-2xl font-bold py-1 mt-10 lg:mt-4 xl:mt-10 4k:mt-36'>
            Name Of Employee
          </div>
          <div>Designation</div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className='bg-cover bg-center bg-no-repeat'
      style={{
        backgroundImage: 'url("/assets/images/career/testimonial_bg.png")'
        // backgroundSize: '100vw 100vh'
      }}
    >
      <div className='pb-20 sol-dark-scroll'>
        <ImageSlider
          slides={[testimonial1, testimonial2, testimonial3, testimonial1]}
          maxWidth='max-w-[100vw] lg:max-w-[98vw]'
          dotPosition='flex items-center justify-end'
          alignment={
            'text-left relative bottom-16 pl-6 lg:pl-0 lg:text-right lg:pr-[14rem]'
          }
          indicatorType='hollow'
          theme='dark'
          autoPlay={true}
        />
      </div>
    </div>
  );
};

export default TestiMonial;
