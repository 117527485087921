import React, { useState, useEffect } from 'react';
import Section1 from './sections/Section1';
import ProfileInfoForm from './sections/ProfileInfoForm';

import axios from 'axios';

const ProfileInfo = ({ setStatus }) => {
  return (
    <>
      <Section1
        subTitle='step 2'
        title='Profile Information'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
      />
      <ProfileInfoForm setStatus={setStatus} />
    </>
  );
};

export default ProfileInfo;
