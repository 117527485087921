import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';
import CardSlider from '../../../components/landing-page/CardSlider';
import Contacts from '../../../components/contact-us/Contacts';

const WeAre = () => {
  return (
    <div className='pl-6 xl:pl-48 mt-12 xl:mt-52 light-bg'>
      <div className={smallHeader}>we are</div>
      <div className={headingTextClass + 'mt-4 xl:mt-10 capitalize'}>
        {/* <div>Here to support you holistically.</div> */}
        <div>Get in touch with us.</div>
      </div>
      <div>
        <div className='hidden mt-16 xl:mt-28 4k:flex flex-wrap gap-6'>
          <Contacts />
        </div>
        <div className='block 4k:hidden mt-16 xl:mt-28'>
          <CardSlider>
            <Contacts />
          </CardSlider>
        </div>
      </div>
    </div>
  );
};

export default WeAre;
