import React, { useState } from 'react';
import Section1 from './sections/Section1';
import ApplicationReviewHeader from './sections/ApplicationReviewHeader';
import ProfileInfoForm from './sections/ProfileInfoForm';
import EducationDetailsForm from './sections/EducationDetailsForm';
import EmploymentDetailsForm from './sections/EmploymentDetailsForm';
import CertificatesDetailsForm from './sections/CertificatesDetailsForm';
import DocumentsRequired from './sections/DocumentsRequired';
import AcknowledgeForm from './sections/AcknowledgeForm';

/**
 * @desc  This is the final page of job application form where user can submit or review the job application.
 * @param {function} setStatus It is a useState function used to update the current form name.
 * @param {object} editable   It is used to enable/disable the fields and edit button based on the respective section in the review page.
 * @param {function} setEditable  It is a useState function used to update respective section status in the review page.
 * @param {string} value Name of the current form
 */
const ApplicationReview = ({ setStatus, jobInfo }) => {
  // setEditable is a function to enable/disable the fields based on the respective section edit button in the review page.
  const [editable, setEditable] = useState({
    profile_info: true,
    education_details: true,
    employment_details: true,
    documents: true,
    certificate_details: true,
    acknowledge: true
  });

  return (
    <>
      <Section1
        subTitle='RE-CHECK'
        title='Application Review'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
      />
      <ApplicationReviewHeader
        subTitle='STEP-1'
        title='Documents Required'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
        setEditable={setEditable}
        value='documents'
        editable={editable}
      />
      <DocumentsRequired
        editable={editable}
        setEditable={setEditable}
        value='documents'
      />
      <ApplicationReviewHeader
        subTitle='STEP-2'
        title='Profile Information'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
        setEditable={setEditable}
        value='profile_info'
        editable={editable}
      />
      <ProfileInfoForm
        editable={editable}
        setEditable={setEditable}
        value='profile_info'
      />
      <ApplicationReviewHeader
        subTitle='STEP-3'
        title='Education Details'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
        setEditable={setEditable}
        value='education_details'
        editable={editable}
      />
      <EducationDetailsForm
        editable={editable}
        setEditable={setEditable}
        value='education_details'
      />
      <ApplicationReviewHeader
        subTitle='STEP-4'
        title='Employment Details'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
        setEditable={setEditable}
        value='employment_details'
        editable={editable}
      />
      <EmploymentDetailsForm
        editable={editable}
        setEditable={setEditable}
        value='employment_details'
      />
      <ApplicationReviewHeader
        subTitle='STEP-5'
        title='Certificates'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
        setEditable={setEditable}
        value='certificate_details'
        editable={editable}
      />
      <CertificatesDetailsForm
        editable={editable}
        setEditable={setEditable}
        value='certificate_details'
      />
      <ApplicationReviewHeader
        subTitle='STEP-6'
        title='Acknowledge & Proceed'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
        setEditable={setEditable}
        value='acknowledge'
        editable={editable}
      />
      <AcknowledgeForm
        setStatus={setStatus}
        editable={editable}
        setEditable={setEditable}
        value='acknowledge'
        jobInfo={jobInfo}
      />
    </>
  );
};

export default ApplicationReview;
