import React, { useState, useEffect } from 'react';

const CookiesPopup = ({ cookies, setCookie }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    document.body.style.overflow = 'unset';
  }, []);

  useEffect(() => {
    Object.keys(cookies).length === 0 ? setShow(true) : setShow(false);
  }, []);
  return (
    <div
      className={
        'm-0 h-screen w-screen z-50 fixed bg-black bg-opacity-75 ' +
        (show ? ' fixed' : ' hidden')
      }
    >
      <div className='relative m-0  '>
        <div className='xl:w-[45%] w-11/12 m-auto mt-8 bg-white  text-black rounded-2xl p-12 pb-8 '>
          <div className='mb-6 text-2xl font-bold '>Cookies</div>
          <div>
            This site uses cookies - small text files that are placed on your machine to
            help the site provide a better user experience. In general, cookies are used
            to retain user preferences, store information for things like shopping carts,
            and provide anonymised tracking data to third party applications like Google
            Analytics. As a rule, cookies will make your browsing experience better.
          </div>
          <div className='mt-4'>
            However, you may prefer to disable cookies on this site and on others. The
            most effective way to do this is to disable cookies in your browser. We
            suggest consulting the Help section of your browser or taking a look at the
            <a
              href='https://www.aboutcookies.org/'
              rel='noreferrer'
              target={'_blank'}
              className='hover:underline text-header-primary'
            >
              About Cookies website
            </a>
            which offers guidance for all modern browsers
          </div>
          <div className=' mt-6 xl:w-[38%] lg:w-[32%] w-[90%]  flex justify-between'>
            <button
              className='bg-black text-white p-2 px-6 rounded-full'
              onClick={() => {
                var month = new Date();
                month.setDate(month.getDate() + 30);
                setCookie('flag', true, {
                  path: '/',
                  expires: month // Will expire after 30 days from setting (value is in Date object)
                  //   maxAge: 60 // Will expire after 60s (value is in number of sec.)
                });
                setShow(!show);
              }}
            >
              Accept
            </button>
            <button
              className='bg-black text-white p-2 px-6 rounded-full'
              onClick={() => {
                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                setCookie('flag', false, {
                  path: '/',
                  expires: tomorrow // Will expire after 24hr from setting (value is in Date object)
                  //   maxAge: 60 // Will expire after 60s (value is in number of sec.)
                });
                setShow(!show);
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
