import React from 'react';
import MenuBar from './MenuBar';
import { getCurrentPageTheme } from '../helpers/util';

const Navbar = ({ show }) => {
  const darkTheme = getCurrentPageTheme() === 'dark';
  const darkThemeConditioned =
    darkTheme ||
    window.location.pathname === '/contact-us' ||
    window.location.pathname === '/' ||
    (window.location.pathname.split('/')[1] === 'blogs' &&
      window.location.pathname.split('/')[2] !== undefined) ||
    (window.location.pathname.split('/')[2] === 'blogs' &&
      window.location.pathname.split('/')[3] !== undefined);
  const logoSrc = darkThemeConditioned
    ? '/assets/images/logo_white.png'
    : '/assets/images/logo.png';

  return (
    <nav
      // className='sticky top-0 flex justify-between pt-16 pl-48 pr-16 select-none bg-transparent'
      className='absolute w-full top-0 flex justify-between px-2 py-8 lg:pt-16 4k:pt-32 lg:pl-32 xl:pl-48 4k:pl-72 lg:pr-12 xl:pr-20 4k:pr-36 select-none bg-transparent'
      // className='fixed lg:relative w-full top-0 flex justify-between px-2 py-8 lg:pt-16 4k:pt-32 lg:pl-32 xl:pl-48 4k:pl-72 lg:pr-12 xl:pr-20 4k:pr-36 select-none bg-white'
      style={{ zIndex: 16 }}
    >
      <div className='block lg:hidden fixed'>
        {/* only for mobile */}
        <MenuBar />
      </div>
      <div className='hidden lg:flex align-middle justify-center'>
        {/* only for tablet and above */}
        {/* show flag is used to display the header logo in the error page */}
        {show ? (
          <a href={`/`}>
            <div className='flex gap-2 items-center justify-center'>
              <img src={logoSrc} className='w-44 4k:w-48' alt='solutionec logo' />
            </div>
          </a>
        ) : null}
      </div>
      {/* <div className='hidden lg:block text-2xl 4k:text-5xl'>
        <span
          className={`mx-2 4k:mx-6 font-semibold ${
            darkThemeConditioned ? 'text-white' : ''
          }`}
        >
          EN
        </span>
        <span
          className={`mx-2 4k:mx-6 ${
            darkThemeConditioned ? 'text-gray-100' : 'text-gray-400'
          }`}
        >
          FR
        </span>
      </div> */}
    </nav>
  );
};

export default Navbar;
