import { useEffect, useState } from 'react';
import { headingTextClass, smallHeader } from '../helpers/constants';
import axios from 'axios';
import moment from 'moment';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';
import { Redirect, useHistory } from 'react-router-dom';
import { routes } from '../helpers/constants';
import { appConfig } from '../appConfig';
import { toast } from 'react-toastify';

const JobDescription = () => {
  const [data, setData] = useState({});
  const [current, setCurrent] = useState('');
  const [copied, setCopied] = useState(false);
  const [name, setName] = useState({ initial: '', lastname: '' });
  const [showFlag, setShowFlag] = useState(true);

  const history = useHistory();

  const copy = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  // Function to change the date format
  const dateConversion = (jobPostedDate) => {
    let value = moment(`${jobPostedDate}`, 'DDMMYYYY').format('Do MMM YYYY').split(' ');
    if (value[0].length === 4) {
      value[0] = `${value[0][0]}${value[0][1]}`;
    } else {
      value[0] = `0${value[0][0]}`;
    }
    let posted_date = value.join('-');
    return posted_date;
  };

  // It is used to set the respective dropdown content to be opened.
  const handleSetSection = (item) => {
    setCurrent(current === '' || current !== item ? item : '');
  };

  const jobInfo = [
    {
      name: 'Job Description',
      message: data?.job_description?.split('\r\n')
    },
    {
      name: 'Responsibilities',
      message: data?.responsibilities?.split('\r\n')
    },
    {
      name: 'Qualification',
      message: data?.qualification?.split('\r\n')
    },
    {
      name: 'Skills',
      message: data?.skills?.split('\r\n')
    },
    {
      name: 'Our People & Culture',
      message: data?.our_culture?.split('\r\n')
    },
    {
      name: 'Benefits',
      message: data?.benefits?.split('\r\n')
    }
  ];

  const labels = [
    { name: 'Status', value: data.status },
    {
      name: 'Job Type',
      value: data.jobtype,
      logo:
        data.jobtype === 'Full Time'
          ? '/assets/images/career/Full_Time.svg'
          : data.jobtype === 'Part Time'
          ? '/assets/images/career/Part_Time.svg'
          : data.jobtype === 'Contract'
          ? '/assets/images/career/Contract.svg'
          : data.jobtype === 'Freelance'
          ? '/assets/images/career/Freelance.svg'
          : null
    },
    {
      name: 'Location',
      value: data.location,
      logo: '/assets/images/career/Location.svg'
    },
    {
      name: 'Job Posted',
      value: dateConversion(data.post_date),
      logo: '/assets/images/career/Post.svg'
    }
    // { name: 'Status', value: 'Label' },
    // { name: 'Status', value: 'Label' },
    // { name: 'Status', value: 'Label' }
  ];

  useEffect(() => {
    let str = window.location.pathname;
    let type = str.split('/')[2];
    let s_no = str.split('/')[3];

    if (!type || !s_no || str.split('/').length > 4) {
      setShowFlag(false);
    } else {
      // Fetching openings from backend api.

      if (type === 'freshers') {
        axios
          .get(`${appConfig.api}/openings`)
          .then((res) => {
            let value = res.data.freshers.find((item, index) => item.s_no == s_no);
            value ? setData({ ...value, type: type }) : setShowFlag(false);
          })
          .catch((err) => {
            history.push(`/join-us/joblist/${type}`);
            toast.error(
              'Please try again later.Something went wrong while fetching openings in job description page.',
              { autoClose: 3000 }
            );
          });
      } else if (type === 'experts') {
        axios
          .get(`${appConfig.api}/openings`)
          .then((res) => {
            let value = res.data.experts.find((item, index) => item.s_no == s_no);
            value ? setData({ ...value, type: type }) : setShowFlag(false);
          })
          .catch((err) => {
            history.push(`/join-us/joblist/${type}`);
            toast.error(
              'Please try again later.Something went wrong while fetching openings in job description page.',
              { autoClose: 3000 }
            );
          });
      } else {
        setShowFlag(false);
      }
    }
  }, []);

  useEffect(() => {
    const splitNames = (title) => {
      if (title) {
        let str = title.split(' ');
        let firstname = str[0];
        let secondname = str.slice(1).join(' ');
        setName({ ...name, initial: firstname, lastname: secondname });
      }
    };
    splitNames(data.name);
  }, [data]);

  return (
    <>
      {showFlag ? (
        <div className='mt-40 text-black lg:mt-44 '>
          <div className='pl-6 lg:pl-32 xl:pl-48 pb-10'>
            <div className={smallHeader + 'uppercase pt-40 pb-30 text-slate-400'}>
              ID {data.id}
            </div>
            <div className={headingTextClass + 'mt-6 '}>
              <div className='lg:block pt-1  border-b-[2px] w-11/12 pb-6 border-black'>
                {/* <div>{data.name}</div> */}
                <div>{name.initial}</div>
                <div>{name.lastname}</div>
              </div>
            </div>
            {/* Social Icons */}
            <div className='mt-8 flex w-11/12 lg:w-2/5 h-10 justify-between  '>
              <div className='w-1/4 '>
                <FacebookShareButton
                  url={`https://solutionec-web.netlify.app${window.location.pathname}`}
                >
                  <div className=' w-full  h-full flex content-center items-center font-bold '>
                    <img
                      src='/assets/images/social-icons/Facebook_Icon.svg'
                      className='w-6 h-6 lg:w-8 lg:h-8'
                      alt='facebook'
                    />
                    <p className='text-xs pl-1 lg:pl-4 lg:text-base'>Share</p>
                  </div>
                </FacebookShareButton>
              </div>
              <div className='w-1/4 '>
                <LinkedinShareButton
                  title='Solutionec Carrers'
                  url={`https://solutionec-web.netlify.app${window.location.pathname}`}
                  hashtag={'#solutionec'}
                >
                  <div className='flex content-center h-full items-center justify-between font-bold'>
                    <img
                      src='/assets/images/social-icons/Linkedln_Icon.svg'
                      className='w-6 h-6 lg:w-8 lg:h-8'
                      alt='linkedin'
                    />
                    <p className='text-xs pl-1 lg:pl-4 lg:text-base'>LinkedIn</p>
                  </div>
                </LinkedinShareButton>
              </div>
              <div className='w-1/4 ml-2'>
                <TwitterShareButton
                  url={`https://solutionec-web.netlify.app${window.location.pathname}`}
                >
                  <div className='flex content-center h-full items-center justify-between font-bold'>
                    <img
                      src='/assets/images/social-icons/Twitter_Icon.svg'
                      className='w-6 h-6 lg:w-8 lg:h-8'
                      alt='twitter'
                    />
                    <p className='text-xs pl-1 lg:pl-4 lg:text-base'>Twitter</p>
                  </div>
                </TwitterShareButton>
              </div>

              <div className='w-1/4 '>
                <button onClick={copy} className='text-black font-bold'>
                  <div className='flex content-center h-full items-center justify-between'>
                    <img
                      src='/assets/images/social-icons/Copy.svg'
                      className='w-6 h-6 lg:w-8 lg:h-8'
                      alt='copy'
                    />
                    <p className='text-xs pl-1 lg:pl-4 lg:text-base'>
                      {!copied ? 'Copy' : 'Copied!'}
                    </p>
                  </div>
                </button>
              </div>
            </div>
            <div className='w-11/12 mt-16 flex'>
              {/* Left side container */}
              <div className='w-3/4 border-r-2 border-gray-500'>
                <div className='w-11/12 flex flex-col  text-[23px] font-semibold'>
                  {jobInfo.map((item, index) => (
                    <div className='flex flex-col mb-10' key={index}>
                      <div
                        className='flex justify-between cursor-pointer'
                        onClick={() => {
                          handleSetSection(`section${index + 1}`);
                        }}
                      >
                        <p>{item.name}</p>

                        {current === `section${index + 1}` ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={3}
                            stroke='currentColor'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M4.5 15.75l7.5-7.5 7.5 7.5'
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6 '
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth={3}
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M19 9l-7 7-7-7'
                            />
                          </svg>
                        )}
                      </div>

                      {current === `section${index + 1}` ? (
                        <div className='my-6 text-xl w-11/12 font-normal  text-justify'>
                          {item?.message?.map((content, index) => (
                            <div key={index}>{content}</div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              {/* Right side container */}
              <div className='w-3/12 flex flex-col items-center'>
                {labels.map((item, index) => (
                  <div
                    className='w-11/12 h-16 flex justify-evenly items-center mb-2'
                    key={index}
                  >
                    <div className='w-12 h-12 rounded-full  flex justify-center'>
                      {item.logo ? (
                        <img
                          src={item.logo}
                          alt=''
                          className='w-6 h-6 lg:w-8 lg:h-8 self-center'
                        />
                      ) : null}
                    </div>
                    <div className='w-7/12'>
                      <p className='text-xs m-0 p-0 text-slate-400'>{item.name}</p>
                      <div className='text-lg font-bold mt-[-4px] p-0 '>{item.value}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {data.status === 'Active' ? (
              <a href={`${routes.job_application}/${data.type}/${data.s_no}`}>
                <button className='mt-8 border-2 p-2 w-60 text-xl border-black rounded-full lg:py-4 lg:px-2 lg:w-80 lg:text-2xl  text-white bg-black'>
                  Apply for the position
                </button>
              </a>
            ) : null}
          </div>
        </div>
      ) : (
        <Redirect to='/404' />
      )}
    </>
  );
};

export default JobDescription;
