import React, { useState, useEffect } from 'react';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../helpers/util';

/**
 * @desc  This is the step-4 of job application form which contains certification details of the job applicant.
 * @param {function} setStatus It is a useState function used to update the current form name.
 * @param {object} editable   It is used to enable/disable the fields and edit button based on the respective section in the review page.
 * @param {function} setEditable  It is a useState function used to update respective section status in the review page.
 * @param {string} value Name of the current form
 */
const CertificatesDetailsForm = ({ setStatus, editable, setEditable, value }) => {
  const [details, setDetails] = useState([
    {
      certificateNumber: '',
      instituteName: '',
      effectiveDate: '',
      expirationDate: ''
    }
  ]);

  // Every time a field is changed, this function updates the local state value i.e  details.
  const handleDetailsChange = (index, event) => {
    let data = [...details];
    data[index][event.target.name] = event.target.value;
    setDetails(data);
  };

  // Every time a field is changed, this function updates the local state value i.e  details.
  const handleUpdateDetails = (index) => {
    let Data = [...details].filter((item, idx) => idx !== index);
    let LocalStorageData = getDataFromLocalStorage('formData');
    LocalStorageData.certificate_details = Data;
    setDataToLocalStorage('formData', LocalStorageData);
    setDetails(Data);
  };

  //  This function is used to save the current form data to session storage and redirect to the next page i.e. Acknowledgement
  const handleNext = () => {
    let Data = getDataFromLocalStorage('formData');
    Data.certificate_details = details;
    setDataToLocalStorage('formData', Data);
    setDataToLocalStorage('formStatus', 'Acknowledgement');
    setStatus('Acknowledgement');
  };

  // This function is used to save data to session storage once it has been modified in the review page.
  const handleSave = () => {
    let Data = getDataFromLocalStorage('formData');
    Data[value] = details;
    setDataToLocalStorage('formData', Data);
    setEditable({ ...editable, [value]: true });
  };

  // Function for displaying certificate card
  const certificateCard = (item, index) => (
    <div>
      <div className='h-[2px] w-11/12 bg-gray-200  my-6 lg:mt-12'></div>
      {details.length !== 1 ? (
        <div className='w-11/12 lg:w-[70%]'>
          <button
            className='float-right'
            onClick={() => {
              handleUpdateDetails(index);
            }}
            title='Close'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth={3}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>
      ) : null}
      <div className='w-11/12 m-auto  lg:w-[70%] lg:m-0 lg:mt-12'>
        <div className='flex flex-col justify-between mt-12 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>
              Certificate/License Number
            </label>
            <input
              type='text'
              value={item.certificateNumber}
              name='certificateNumber'
              className='border-2 text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg'
              onInput={(e) => {
                handleDetailsChange(index, e);
              }}
              disabled={editable?.[value]}
            />
          </div>
        </div>
        <div className='flex flex-col justify-between mt-12 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>Institute Name</label>
            <input
              type='text'
              value={item.instituteName}
              name='instituteName'
              className='border-2 text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg'
              onInput={(e) => {
                handleDetailsChange(index, e);
              }}
              disabled={editable?.[value]}
            />
          </div>
        </div>
        <div className='flex flex-col justify-between mt-12 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>Effective Date</label>
            <div className='w-full border-2  h-14 mt-6 rounded-full flex items-center  '>
              <input
                id='effectiveDateId'
                type='date'
                value={item.effectiveDate}
                name='effectiveDate'
                className='session-date text-small w-full border-gray h-full px-4 rounded-full  outline-none lg:text-lg'
                onChange={(e) => {
                  handleDetailsChange(index, e);
                }}
                disabled={editable?.[value]}
              />
            </div>
          </div>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>Expiration Date</label>
            <div className='w-full border-2  h-14 mt-6 rounded-full flex items-center  '>
              <input
                id='expirationDateId'
                type='date'
                value={item.expirationDate}
                name='expirationDate'
                className='session-date text-small w-full border-gray h-full px-4 rounded-full  outline-none lg:text-lg'
                onChange={(e) => {
                  handleDetailsChange(index, e);
                }}
                disabled={editable?.[value]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Initially we are fetching data from the session storage and we are setting the same to local state ie. details and we are defining max value for the calendar
  useEffect(() => {
    let startDateInput = document.getElementById('effectiveDateId');
    let endDateInput = document.getElementById('expirationDateId');
    startDateInput.max = new Date().toISOString().split('T')[0];

    let Data = getDataFromLocalStorage('formData');
    setDetails(Data.certificate_details);
  }, []);

  // Whenever any field is updated we are updating the same in the session storage as well.
  useEffect(() => {
    let LocalStorageData = getDataFromLocalStorage('formData');
    LocalStorageData.certificate_details = details;
    setDataToLocalStorage('formData', LocalStorageData);
  }, [details]);

  return (
    <div className='pb-12 xl:pl-48  text-black lg:pb-24  w-screen'>
      <button
        onClick={() => {
          setDetails([
            ...details,
            {
              certificateNumber: '',
              instituteName: '',
              effectiveDate: '',
              expirationDate: ''
            }
          ]);
        }}
        className='bg-black flex text-white text-xl  font-bold rounded-full px-8 py-4 lg:text-2xl '
      >
        Add ({details.length})
        <span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-8 w-8 ml-2'
            fill='none'
            viewBox='0 0 24 24'
            stroke='white'
            strokeWidth={1.5}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
        </span>
      </button>
      {details.map((item, index) => (
        <div key={index}>{certificateCard(item, index)}</div>
      ))}

      {setStatus ? (
        <div className='flex w-[330px]  h-full justify-between'>
          <button
            onClick={() => {
              setDataToLocalStorage('formStatus', 'Employment Details');
              setStatus('Employment Details');
            }}
            className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
          >
            Previous
          </button>
          <button
            onClick={() => {
              // setStatus('Acknowledgement');
              handleNext();
            }}
            className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
          >
            Next
          </button>
        </div>
      ) : (
        <button
          onClick={() => {
            handleSave();
          }}
          disabled={editable[value]}
          className={
            'text-white text-xl mt-8 font-bold rounded-full px-10 py-3 lg:text-2xl lg:mt-16' +
            (editable[value] ? ' bg-gray-400' : ' bg-black')
          }
        >
          Save
        </button>
      )}
    </div>
  );
};

export default CertificatesDetailsForm;
