import React from 'react';
import '../../styles/image-slider.css';

const ImageSlider = ({
  slides,
  delay = 3500,
  autoPlay = true,
  maxWidth = 'max-w-none',
  dotPosition = 'absolute',
  alignment = 'text-center',
  indicatorType = 'dot',
  theme = 'light'
}) => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  const indicatorTypeMapping = {
    dot: 'slideshowDot',
    dashed: 'slideshowDash',
    hollow: 'slideshowHollow'
  };
  const themeMapping = {
    dark: 'border-1 border-black',
    light: 'border-none'
  };

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const setTimer = () => {
    if (autoPlay) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === (slides?.length || 0) - 1 ? 0 : prevIndex + 1
          ),
        index === slides?.length - 1 ? 1000 : delay
      );

      return () => {
        resetTimeout();
      };
    }
  };

  const setIndicatorClasses = (idx) => {
    const defaultClasses = `${indicatorTypeMapping[indicatorType]} ${themeMapping[theme]} `;
    let resultClasses = defaultClasses;

    if (index === idx) {
      resultClasses += theme === 'dark' ? ' bg-gray-800 ' : ' bg-blue-600 ';
    }

    if (indicatorType === 'hollow' && index !== idx) {
      resultClasses += 'bg-[#ededed] shadow-none w-[1rem] h-[1rem] border-none';
    }

    return resultClasses;
  };

  React.useEffect(() => {
    setTimer();
    // eslint-disable-next-line

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className={`slideshow relative ${maxWidth}`}>
      <div
        className='slideshowSlider'
        style={{
          transform: `translate3d(${-index * 100}%, 0, 0)`,
          transition: `${index % slides.length == 0 ? '0s' : '1s'}`
        }}
      >
        {slides?.length ? (
          slides.map((slide, index) => (
            <div className={'w-full m-auto relative inline-block'} key={`slide#${index}`}>
              {slide}
            </div>
          ))
        ) : (
          <div className='w-full h-screen grid items-center justify-center relative text-2xl'>
            No Slides Found
          </div>
        )}
      </div>
      {slides?.length > 1 ? (
        <div className={`${alignment} w-full bottom-2 ${dotPosition}`}>
          {slides.map((_, idx) => {
            if (idx !== slides.length - 1) {
              return (
                <div
                  key={`slideshowDot#${idx}`}
                  className={setIndicatorClasses(idx)}
                  onClick={() => {
                    setIndex(idx);
                  }}
                ></div>
              );
            }
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ImageSlider;
