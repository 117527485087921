import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../helpers/constants';
import { headingTextClass, smallHeader } from '../helpers/constants';

const NotFound = ({ setShow }) => {
  useEffect(() => {
    console.log(document.referrer, 'hey');
    setShow(false);
  }, []);
  return (
    <>
      <div className='bg-cover bg-right-top h-vision-card xl:h-screen grid place-items-center text-black'>
        <div className=' xl:w-[70%] lg:w-4/5 w-11/12 flex flex-col  xl:flex-row lg:flex-row xl:justify-between lg:justify-between'>
          <div className='w-3/4 xl:w-[36%] lg:w-[40%] m-auto '>
            <img src='/assets/images/error-page/emoji.png' alt='' />
          </div>
          <div className='mt-4 w-full xl:w-[50%] lg:w-[50%] xl:relative lg:relative'>
            <div className='xl:absolute lg:absolute xl:bottom-16 lg:bottom-20 text-center xl:text-left lg:text-left'>
              <p className='xl:text-4xl  text-2xl font-medium m-0'>Oops,</p>
              <p className='xl:text-4xl  text-2xl font-medium m-0'>
                that page cannot be found.
              </p>
              <p className='xl:text-lg font-medium text-lg leading-3'>
                404, That's an error
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
