import React from 'react';
import '../../styles/image-slider.css';

const ImageSlider = ({
  slides,
  delay = 3500,
  autoPlay = true,
  maxWidth = 'max-w-none',
  dotPosition = 'absolute',
  width = 'w-full',
  status = 'active'
}) => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  // const delay = 2500;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const setTimer = () => {
    if (autoPlay) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === (slides?.length || 0) - 1 ? 0 : prevIndex + 1
          ),
        index === slides?.length - 1 ? 1000 : delay
      );

      return () => {
        resetTimeout();
      };
    }
  };

  React.useEffect(() => {
    setTimer();
    // eslint-disable-next-line
  }, [index]);

  return (
    <div className={`slideshow relative ${maxWidth}`}>
      <div
        className='slideshowSlider'
        style={{
          transform: `translate3d(${-index * 100}%, 0, 0)`,
          transition: `${index % slides.length == 0 ? '0s' : '1s'}`
        }}
      >
        {slides?.length ? (
          slides.map((slide, index) => (
            <div className={'w-full m-auto relative inline-block'} key={`slide#${index}`}>
              {slide}
            </div>
          ))
        ) : (
          <div className='w-full h-screen grid items-center justify-center relative text-2xl'>
            No Slides Found
          </div>
        )}
      </div>
      {slides?.length ? (
        <div className={`${width} bottom-2  text-center ${dotPosition} `}>
          {slides.map((_, idx) => {
            if (idx !== slides.length - 1) {
              return (
                <div
                  key={`slideshowDot#${idx}`}
                  className={`slideshowDot ${index === idx ? `${status}` : ''}`}
                  onClick={() => {
                    setIndex(idx);
                  }}
                ></div>
              );
            }
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ImageSlider;
