import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';
import Maps from '../../../components/contact-us/Maps';
import CardSlider from '../../../components/landing-page/CardSlider';

const OurPresense = () => {
  return (
    <div
      className='relative bg-cover bg-no-repeat bg-center'
      style={{
        backgroundImage: 'url("/assets/images/contacts-page/our_presence-OPT.jpg")'
        // 'linear-gradient(0deg, rgba(24,109,252,1) 0%, rgba(57,125,250,1) 48%, rgba(116,170,247,1) 100%)'
      }}
    >
      <div className='mt-32 xl:ml-auto xl:w-55rem pl-6 xl:pl-0'>
        <div className={smallHeader + 'pt-28 xl:pt-52 text-light'}>our presense</div>
        <div className={headingTextClass + 'mt-4 xl:mt-10 text-light'}>
          <div>Around the Globe</div>
          <div>To Stay Connect Always</div>
          {/* <div>Around the globe</div>
          <div>we always stay connected</div> */}
          {/* <div>to stay connected always</div> */}
        </div>
        <div>
          <div className='hidden mt-12 xl:mt-24 xl:flex flex-wrap gap-6 pb-28'>
            <Maps />
          </div>
          <div className='block xl:hidden mt-12 xl:mt-24 pb-16 xl:pb-0'>
            <CardSlider>
              <Maps />
            </CardSlider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPresense;
