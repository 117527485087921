import React, { useState } from 'react';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';

const Description = () => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };
  return (
    <div className='text-black   bg-[#f1f1f1] lg:py-24'>
      <div className='mt-6 lg:ml-48 lg:mt-0 lg:w-4/5  w-11/12 m-auto '>
        <div className='lg:text-3xl lg:mt-10  '>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ea in porro
          illum, sit est magnam praesentium, omnis sunt rerum blanditiis consequatur ut
          laborum voluptate placeat iure quidem inventore recusandae! Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Saepe ea in porro illum, sit est magnam
          praesentium, omnis sunt rerum blanditiis consequatur ut laborum voluptate
          placeat iure quidem inventore recusandae!
        </div>
        <div className='lg:text-3xl lg:mt-10 '>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ea in porro
          illum, sit est magnam praesentium, omnis sunt rerum blanditiis consequatur ut
          laborum voluptate placeat iure quidem inventore recusandae! Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Saepe ea in porro illum, sit est magnam
          praesentium, omnis sunt rerum blanditiis consequatur ut laborum voluptate
          placeat iure quidem inventore recusandae!
        </div>
      </div>
      <div className='mt-6 lg:ml-48 lg:mt-16 flex w-11/12 lg:w-1/3 h-10 justify-between   '>
        <div className='w-1/4 '>
          <FacebookShareButton
            url={`https://solutionec-web.netlify.app${window.location.pathname}`}
          >
            <div className=' w-full  h-full flex content-center items-center '>
              <img
                src='/assets/images/social-icons/Facebook_Icon.svg'
                className='w-6 h-6 lg:w-6 lg:h-6'
                alt='facebook'
              />
              <p className='text-xs pl-1 lg:pl-4 lg:text-base'>Share</p>
            </div>
          </FacebookShareButton>
        </div>
        <div className='w-1/4 '>
          <LinkedinShareButton
            title='Solutionec Carrers'
            url={`https://solutionec-web.netlify.app${window.location.pathname}`}
            hashtag={'#solutionec'}
          >
            <div className='flex content-center h-full items-center justify-between'>
              <img
                src='/assets/images/social-icons/Linkedln_Icon.svg'
                className='w-6 h-6 lg:w-6 lg:h-6'
                alt='linkedin'
              />
              <p className='text-xs pl-1 lg:pl-4 lg:text-base'>LinkedIn</p>
            </div>
          </LinkedinShareButton>
        </div>
        <div className='w-1/4 ml-2'>
          <TwitterShareButton
            url={`https://solutionec-web.netlify.app${window.location.pathname}`}
          >
            <div className='flex content-center h-full items-center justify-between'>
              <img
                src='/assets/images/social-icons/Twitter_Icon.svg'
                className='w-6 h-6 lg:w-6 lg:h-6'
                alt='twitter'
              />
              <p className='text-xs pl-1 lg:pl-4 lg:text-base'>Twitter</p>
            </div>
          </TwitterShareButton>
        </div>
        <div className='w-1/4 '>
          <button onClick={copy} className='text-black'>
            <div className='flex content-center h-full items-center justify-between'>
              <img
                src='/assets/images/social-icons/Copy.svg'
                className='w-6 h-6 lg:w-6 lg:h-6'
                alt='copy'
              />
              <p className='text-xs pl-1 lg:pl-4 lg:text-base'>
                {!copied ? 'Copy' : 'Copied!'}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Description;
