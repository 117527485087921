import React from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';

const FeaturedSection = () => {
  return (
    <>
      <div>
        <div className='pl-6 lg:pl-32 xl:pl-48 pb-10 ml-36 '>
          <div className={smallHeader + 'uppercase pt-40 pb-30 text-dark-3'}>
            Featured
          </div>
          <div className={headingTextClass + 'mt-6 '}>
            <div className='lg:block pt-5'>
              <div>Souvenir 2021:</div>
              <div> Inspiration at Work </div>
            </div>
          </div>
          <div className='mt-8 text-base lg:text-xl'>
            <div className='lg:block'>
              <div>A small sneak-peek into the lives of Team Solutionec. </div>
            </div>
            <div className='flex flex-wrap py-2 mb-8'>
              <div className='px-0'>
                <img
                  src='/assets/images/insight-page/featured-img.png'
                  className='featured-image-section'
                  alt='featured'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedSection;
