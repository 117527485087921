import React, { useState, useEffect } from 'react';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../helpers/util';
import { flagOptions } from '../../../helpers/constants';
import Dropdown from './Dropdown';

/**
 * @desc  This is the step-3 of job application form which contains education details of the job applicant.
 * @param {function} setStatus It is a useState function used to update the current form name.
 * @param {object} editable   It is used to enable/disable the fields and edit button based on the respective section in the review page.
 * @param {function} setEditable  It is a useState function used to update respective section status in the review page.
 * @param {string} value Name of the current form
 */
const EmploymentDetailsForm = ({ setStatus, editable, setEditable, value }) => {
  const [details, setDetails] = useState([
    {
      flag: '',
      companyName: '',
      designation: '',
      skillSet: '',
      department: '',
      startDate: '',
      endDate: '',
      noticePeriod: ''
    }
  ]);
  const [error, setError] = useState(false);

  // This function will return no of words.
  const countWords = (string) => {
    var words = string?.split(/[, ]+/);
    var numWords = words?.length;
    return numWords;
  };

  // Function to validate the form values
  const validateFormValues = () => {
    let Data = details;
    let flag = false;

    Data.forEach((obj, index) => {
      if (flag) return true;
      // key !== 'skillSet' ? !obj[key] : !obj[key] || countWords(obj[key]) < 3
      for (let key in obj) {
        if (
          (key !== 'endDate' || obj['flag']['value'] !== 'Yes') &&
          key !== 'skillSet' &&
          !obj[key]
        ) {
          flag = true;
          break;
        }
      }
    });

    return flag;
  };

  // Every time a field is changed, this function updates the local state value i.e  details.
  const handleDetailsChange = (index, event) => {
    let data = [...details];
    data[index][event.target.name] = event.target.value;
    setDetails(data);
  };

  // The respective education details card will be deleted when close button is clicked.
  const handleUpdateDetails = (index) => {
    setError(false);
    let Data = [...details].filter((item, idx) => idx !== index);
    setDetails(Data);
  };

  // This function is used to save the current form data to session storage and redirect to the next page i.e. Certificate Details
  const handleNext = () => {
    if (validateFormValues()) {
      setError(true);
    } else {
      let Data = getDataFromLocalStorage('formData');
      Data.employment_details = details;
      setDataToLocalStorage('formData', Data);
      setDataToLocalStorage('formStatus', 'Certificate Details');
      setStatus('Certificate Details');
    }
  };

  // This function is used to save data to session storage once it has been modified in the review page.
  const handleSave = () => {
    let Data = getDataFromLocalStorage('formData');
    Data.employment_details = details;
    setDataToLocalStorage('formData', Data);
    setEditable({ ...editable, [value]: true });
  };

  // function for adding the employment cards dynamically.
  const employmentCard = (item, index) => (
    <div>
      <div className='h-[2px] w-11/12 bg-gray-200  my-6 lg:mt-12'></div>
      {details.length !== 1 ? (
        <div className='w-11/12 lg:w-[70%]'>
          <button
            className='float-right'
            onClick={() => {
              handleUpdateDetails(index);
            }}
            title='Close'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth={3}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>
      ) : null}
      <div className='w-11/12 m-auto  lg:w-[70%] lg:m-0 lg:mt-12'>
        <div className='flex flex-col justify-between mt-4 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%]'>
            <label className='text-lg font-bold lg:text-2xl'>
              Is this your Current Employer ?
              <span className='text-red-500 font-normal ml-1'>*</span>
            </label>
            <div className='mt-6'>
              <Dropdown
                name='flag'
                value={details[index].flag}
                setDetails={setDetails}
                error={error}
                details={details}
                setValue='flag'
                options={flagOptions}
                placeholder='Select'
                editableFlag={editable?.employment_details}
                currentForm='Employment_Info'
                index={index}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-between mt-12 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>
              Company Name <span className='text-red-500 font-normal '>*</span>
            </label>
            <input
              type='text'
              value={item.companyName}
              name='companyName'
              className={
                'text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                (error && !item.companyName
                  ? ' border-[1px] border-red-500'
                  : ' border-2 ')
              }
              onInput={(e) => {
                handleDetailsChange(index, e);
              }}
              disabled={editable?.employment_details}
            />
          </div>
          <div className='flex flex-col w-full lg:w-[48%]  '>
            <label className='text-lg font-bold lg:text-2xl'>
              Designation <span className='text-red-500 font-normal '>*</span>
            </label>
            <input
              type='text'
              value={item.designation}
              name='designation'
              className={
                'text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                (error && !item.designation
                  ? ' border-[1px] border-red-500'
                  : ' border-2 ')
              }
              onInput={(e) => {
                handleDetailsChange(index, e);
              }}
              disabled={editable?.employment_details}
            />
          </div>
        </div>
        <div className='flex flex-col justify-between mt-12 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>Technical Skill Set</label>
            <input
              type='text'
              value={item.skillSet}
              id='technicalSkills'
              name='skillSet'
              className={
                'text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg border-2'
              }
              onInput={(e) => {
                var maxWords = 5;
                if (countWords(e.target.value) <= maxWords) {
                  handleDetailsChange(index, e);
                }
              }}
              disabled={editable?.employment_details}
            />
          </div>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>
              Department <span className='text-red-500 font-normal '>*</span>
            </label>
            <input
              type='text'
              value={item.department}
              name='department'
              className={
                'text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                (error && !item.department
                  ? ' border-[1px] border-red-500'
                  : ' border-2 ')
              }
              onInput={(e) => {
                handleDetailsChange(index, e);
              }}
              disabled={editable?.employment_details}
            />
          </div>
        </div>
        <div className='flex flex-col justify-between mt-12 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>
              Start Date <span className='text-red-500 font-normal '>*</span>
            </label>
            <div className='w-full  h-14 mt-6 rounded-full flex items-center  '>
              <input
                id='startDateId'
                type='date'
                value={item.startDate}
                name='startDate'
                className={
                  'session-date text-small w-full border-gray h-full px-4 rounded-full  outline-none lg:text-lg' +
                  (error && !item.startDate
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                onChange={(e) => {
                  handleDetailsChange(index, e);
                }}
                disabled={editable?.employment_details}
              />
            </div>
          </div>
          {/* End date field will be displayed if current employer flag is set to no or empty, else it won't. */}
          {item.flag?.value !== 'Yes' ? (
            <div className='flex flex-col w-full lg:w-[48%] '>
              <label className='text-lg font-bold lg:text-2xl'>
                End Date <span className='text-red-500 font-normal '>*</span>
              </label>
              <div className='w-full  h-14 mt-6 rounded-full flex items-center  '>
                <input
                  id='endDateId'
                  type='date'
                  value={item.endDate}
                  name='endDate'
                  className={
                    'session-date text-small w-full border-gray h-full px-4 rounded-full  outline-none lg:text-lg' +
                    (error && !item.endDate
                      ? ' border-[1px] border-red-500'
                      : ' border-2 ')
                  }
                  onChange={(e) => {
                    handleDetailsChange(index, e);
                  }}
                  disabled={editable?.employment_details}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className='flex flex-col justify-between mt-12 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%]'>
            <label className='text-lg font-bold lg:text-2xl'>
              Notice Period (Days) <span className='text-red-500 font-normal '>*</span>
            </label>
            <input
              type='number'
              value={item.noticePeriod}
              name='noticePeriod'
              className={
                ' text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                (error && !item.noticePeriod
                  ? ' border-[1px] border-red-500'
                  : ' border-2 ')
              }
              placeholder='No. of days'
              onInput={(e) => {
                handleDetailsChange(index, e);
              }}
              disabled={editable?.employment_details}
            />
          </div>
        </div>
      </div>
    </div>
  );

  // Initially we are fetching data from the session storage and we are setting the same to local state ie. details and we are defining max value for the calendar
  useEffect(() => {
    let startDateInput = document.getElementById('startDateId');
    let endDateInput = document.getElementById('endDateId');
    startDateInput.max = endDateInput.max = new Date().toISOString().split('T')[0];

    let Data = getDataFromLocalStorage('formData');
    setDetails(Data.employment_details);
  }, []);

  // Whenever any field is updated we are updating the same in the session storage as well.
  useEffect(() => {
    if (error && !validateFormValues()) {
      setError(false);
    }
    let LocalStorageData = getDataFromLocalStorage('formData');
    LocalStorageData.employment_details = details;
    setDataToLocalStorage('formData', LocalStorageData);
  }, [details]);

  return (
    <div className='pb-12 xl:pl-48  text-black lg:pb-24  w-screen'>
      <button
        onClick={() => {
          setError(false);
          setDetails([
            ...details,
            {
              flag: '',
              companyName: '',
              designation: '',
              skillSet: '',
              department: '',
              startDate: '',
              endDate: '',
              noticePeriod: ''
            }
          ]);
        }}
        className='bg-black flex text-white text-xl  font-bold rounded-full px-8 py-4 lg:text-2xl '
      >
        Add ({details.length})
        <span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-8 w-8 ml-2'
            fill='none'
            viewBox='0 0 24 24'
            stroke='white'
            strokeWidth={1.5}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
        </span>
      </button>
      {details.map((item, idx) => (
        <div key={idx}>{employmentCard(item, idx)}</div>
      ))}
      {error ? (
        <p className='mt-4 text-red-500 text-lg'>
          <span
            style={{
              color: 'red',
              fontWeight: 'normal',
              marginRight: '4px',
              fontSize: '20px'
            }}
          >
            *
          </span>
          Please check the highlighted fields.
        </p>
      ) : (
        ''
      )}

      {setStatus ? (
        <div className='flex w-[330px]  h-full justify-between'>
          <button
            onClick={() => {
              setDataToLocalStorage('formStatus', 'Education Details');
              setStatus('Education Details');
            }}
            className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
          >
            Previous
          </button>
          <button
            onClick={() => {
              // setStatus('Certificate Details');
              handleNext();
            }}
            className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
          >
            Next
          </button>
        </div>
      ) : (
        <button
          onClick={() => {
            handleSave();
          }}
          disabled={editable.employment_details}
          className={
            'text-white text-xl mt-8 font-bold rounded-full px-10 py-3 lg:text-2xl lg:mt-16' +
            (editable.employment_details ? ' bg-gray-400' : ' bg-black')
          }
        >
          Save
        </button>
      )}
    </div>
  );
};

export default EmploymentDetailsForm;
