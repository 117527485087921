import React from 'react';
import { headingTextClass, smallHeader, subHeader } from '../../../helpers/constants';
import Button from '../../Button';

const Genesis = () => {
  return (
    <>
      <div className='pl-6 lg:pl-60 4k:pl-left-space xl:pl-96 mt-32 lg:mt-40 xl:mt-56 '>
        <div className={smallHeader}>the genesis</div>
        <div className={headingTextClass + 'mt-4 xl:mt-7'}>
          <div className='hidden lg:block'>
            <div>Bringing the</div>
            <div>Dawn of Innovation</div>
          </div>
          <div className='block lg:hidden'>
            <div>Bringing</div>
            <div>the Dawn</div>
            <div>of Innovation</div>
          </div>
        </div>
        <div className={subHeader + 'mt-4 text-black'}>
          <div className='hidden lg:block'>
            <div>To reach the summit the right foundation is all you need.</div>
            <div>
              Let our services build the right footing for you to climb your Everest.
            </div>
            {/* <div>Ut wisi enim ad minim veniam. quis nos</div> */}
          </div>
          <div className='block lg:hidden'>
            <div>To reach the summit the right foundation is all you need.</div>
            <div>
              Let our services build the right footing for you to climb your Everest.
            </div>
          </div>
        </div>
        <div className='mt-10 lg:mt-8 xl:mt-12'>
          <Button
            title={'explore'}
            primaryColor='border-black'
            secondaryColor='hover:bg-black hover:text-white'
          />
        </div>
      </div>
      <div className='mt-60 lg:mt-0 grid place-items-center w-full relative lady-animation-bg'>
        <div className='absolute w-full h-full genesis-bg sol-dark-scroll'></div>
        <div className='relative'>
          <img
            className='object-cover bg-no-repeat relative z-10 w-1/2 xl:w-3/5 m-auto lady_img'
            src='/assets/images/landing-page/Women-01.png'
            alt='new ideas emerging from brain'
          />
          {/* <div className='absolute bg-gray-200 bottom-0 w-full h-full z-10'></div> */}
          <div className='head-container'>
            <div className='outer-head'>
              <div className='inner-head'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Genesis;
