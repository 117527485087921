
import CsrLife from '../components/life-solutionec/CsrLife';
import Family from '../components/life-solutionec/Family';
import LifeLandingPage from '../components/life-solutionec/LifeLandingPage';
import PhotoLife from '../components/life-solutionec/PhotoLife';

const Life = () => {
  return (
    <>
      <div className='sol-dark-scroll'>
        <LifeLandingPage />
        <Family />
        <PhotoLife />
        <CsrLife />
       
      </div>
    </>
  );
};

export default Life;