import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ContactUs from '../pages/ContactUs';
import MainLandingPage from '../pages/MainLandingPage';
import AboutUs from '../pages/AboutUs';
import Products from '../pages/Products';
import Services from '../pages/Services';
import { routes } from '../helpers/constants';
import CareerPage from '../pages/CareerPage';
import Life from '../pages/Life';
import InsightPage from '../pages/InsightPage';
import JobList from '../pages/JobList';
import JobDescription from '../pages/JobDescription';
import SuccessStories from '../pages/SuccessStories';
import SuccessStoriesInner from '../pages/SuccessStoriesInner';
import Blogs from '../pages/Blogs';
import NotFound from '../pages/NotFound';
import JobApplicationForm from '../pages/JobApplicationForm';

const Routes = ({ setShow }) => {
  return (
    <Router>
      <Switch>
        <Route exact path={routes.home} component={MainLandingPage} />
        <Route path={routes.blog_inner_page} component={Blogs} />
        <Route exact path={routes.contactUs} component={ContactUs} />
        <Route exact path={routes.insights} component={InsightPage} />
        <Route exact path={routes.aboutUs} component={AboutUs} />
        <Route exact path={routes.products} component={Products} />
        <Route exact path={routes.services} component={Services} />
        <Route exact path={routes.joinUs} component={CareerPage} />
        <Route exact path={routes.life} component={Life} />
        <Route path={routes.joblist} component={JobList} />
        <Route path={routes.job_description} component={JobDescription} />
        <Route path={routes.job_application} component={JobApplicationForm} />
        <Route exact path={routes.success_stories} component={SuccessStories} />
        <Route path={routes.success_stories_inner} component={SuccessStoriesInner} />
        <Route path='/404' render={() => <NotFound setShow={setShow} />} />
        <Route render={() => <NotFound setShow={setShow} />} />
      </Switch>
    </Router>
  );
};

export default Routes;
