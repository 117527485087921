import React, { useEffect, useState } from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';
import axios from 'axios';

const Expressions = () => {
  const [data, setData] = useState({
    list: [],
    iteration: 3
  });

  useEffect(() => {
    axios.get('/expressions.json').then((res) => {
      setData({ ...data, list: res.data });
    });
  }, [data]);
  return (
    <div className='   text-black  pt-40 border-2 h-[800px] customer-success-expressions'>
      <div className=' ml-[26rem] w-1/2 '>
        <div className={smallHeader + 'text-red-600 font-bold'}>Expressions</div>
        <div className='w-full mt-4'>
          <div className={headingTextClass + 'mb-2 mt-7 '}>
            <div>Heading First Line</div>
            <div>Max ends in Second line.</div>
          </div>
        </div>
      </div>
      <div className='customer-slider h-[280px] mt-20'>
        {Array(data.iteration)
          .fill(0)
          .map((item, index) => (
            <div className='move' key={index}>
              {data.list.map((item, idx) => (
                <div
                  className='customer-slider-inner w-[250px] h-full bg-black'
                  key={`${index}${idx}`}
                ></div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Expressions;
