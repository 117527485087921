import React, { useEffect, useState } from 'react';
import CookieConsentWrapper from './components/CookieConsentWrapper';
import Cookies from './components/Cookies';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { getCurrentPageTheme, isInViewport } from './helpers/util';
import Routes from './routes/Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appConfig } from './appConfig';
import CookiesPopup from './components/CookiesPopup';
import { useCookies } from 'react-cookie';

const App = () => {
  const [topDisplayProp, setTopDisplayProp] = useState('hidden');
  const [show, setShow] = useState(true);
  const [cookies, setCookie] = useCookies(['name']); // name is dependency and it is optional and used to control the component rendering.

  const theme = getCurrentPageTheme();
  const config = {
    dark: 'text-white border-white hover:bg-white hover:text-black',
    light: 'text-black border-black hover:bg-black hover:text-white'
  };

  const handleScrollTop = () => {
    const footerElement = document.querySelector('.sol-footer');

    if (isInViewport(footerElement, 5)) {
      setTopDisplayProp('fixed');
    } else {
      setTopDisplayProp('hidden');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollTop);
    return () => {
      window.removeEventListener('scroll', handleScrollTop);
    };
  }, []);

  return (
    <div className='flex min-h-screen'>
      {/* for small screens sidebar is given in Navbar compenent
      but for tablet and above the below Sidebar component is used */}
      <Sidebar />

      <div className='flex-1'>
        <Navbar show={show} />
        <Routes setShow={setShow} />
        {show ? <Footer /> : null}
      </div>

      <button
        className={`right-8 bottom-8 4k:right-16 4k:bottom-16 outline-none uppercase p-3 border-[1px] rounded-full bg-transparent ${config[theme]} ${topDisplayProp} z-10`}
        onClick={() => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }}
        title='Scroll to top'
      >
        <span className={'grid place-items-center'}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-4 4k:h-12 w-4 4k:w-12'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M5 10l7-7m0 0l7 7m-7-7v18'
            />
          </svg>
        </span>
      </button>
      {/* <CookieConsentWrapper /> */}
      {/* <Cookies /> */}
      <CookiesPopup setCookie={setCookie} cookies={cookies} />
      <ToastContainer />
    </div>
  );
};

export default App;
