import React, { useEffect, useState } from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';
import axios from 'axios';

const Featured = () => {
  const [data, setData] = useState({
    list: [],
    iteration: 3
  });
  const [id, setId] = useState(0);

  useEffect(() => {
    axios.get('/featured.json').then((res) => {
      setData({ ...data, list: res.data });
    });
  }, [data]);
  return (
    <div className=' bg-[#eee] text-black pb-10'>
      <div className=' xl:pl-48 pb-12 pt-32 w-full lg:mt-32'>
        <div className={smallHeader + 'text-dark-3'}>Featured</div>
        <div className='grid xl:grid-cols-2 mt-4'>
          <div className={headingTextClass + 'mb-2 mt-7'}>
            <div>Heading ends here.</div>
          </div>
        </div>
      </div>
      <div className='customer-slider h-[350px]'>
        {Array(data.iteration)
          .fill(0)
          .map((item, index) => (
            <div className='move' key={index}>
              {data.list.map((item, idx) => (
                <div
                  className='customer-slider-inner w-[250px] h-full'
                  key={`${index}${idx}`}
                  onMouseEnter={() => {
                    setId(`${index}${idx}`);
                  }}
                  onMouseLeave={() => {
                    setId(0);
                  }}
                  onClick={() => {
                    setId(`${index}${idx}`);
                  }}
                >
                  <div className='h-2/3 border-b-2'></div>
                  <div
                    className={
                      'font-normal w-full my-6 text-xl 4k:text-2xl  mt-8 analy ' +
                      (`${index}${idx}` === id ? '-translate-y-2  ' : null)
                    }
                  >
                    <div className='ml-4 text-xs text-gray-500 font-semibold '>
                      {item.date}
                    </div>
                    <div className='ml-4 text-base mt-2 w-3/4 '>{item.title}</div>
                    <div
                      className={
                        'w-[20px] h-[2px] bg-black ml-4  mt-2  ' +
                        (`${index}${idx}` === id
                          ? 'visible w-[65px] transition-all'
                          : 'transition-all invisible')
                      }
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Featured;
