import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';

const Heading = ({ mainTitle, title1, title2, imgSrc }) => {
  return (
    <div className=' w-full bg-cover h-screen relative  text-white sol-dark-scroll lg:h-screen bg-center bg-black'>
      <div className='pl-6 xl:pl-48 pb-12 absolute bottom-0 w-full'>
        <div className={smallHeader + 'text-xl'} style={{ color: 'white' }}>
          {mainTitle}
        </div>
        <div className='grid xl:grid-cols-2 mt-4'>
          <div
            className={headingTextClass + 'mb-2 mt-1 lg:mt-7'}
            style={{ color: 'white' }}
          >
            <div>{title1}</div>
            <div>{title2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heading;
