import React from 'react';

const UpRipple = () => {
  const DELAY = 2;

  return (
    <>
      <div
        className='ripple-container flex relative justify-center w-3/5 h-44 transform rotate-180'
        style={{ left: '20%' }}
      >
        <div className='small-ripple' style={{ '--i': DELAY + 2 }}></div>
        <div className='small-ripple' style={{ '--i': DELAY + 4 }}></div>
        <div className='small-ripple' style={{ '--i': DELAY + 6 }}></div>
        <div className='small-ripple' style={{ '--i': DELAY + 8 }}></div>
        <div className='small-ripple' style={{ '--i': DELAY + 10 }}></div>
        <div className='small-ripple' style={{ '--i': DELAY + 12 }}></div>
        <div className='small-ripple' style={{ '--i': DELAY + 14 }}></div>
        <div className='small-ripple' style={{ '--i': DELAY + 16 }}></div>
      </div>
      <div className='vision-card-line w-4/5 m-auto 4k:absolute 4k:left-12'></div>
    </>
  );
};

export default UpRipple;
