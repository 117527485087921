import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { appConfig } from '../../../appConfig';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../helpers/util';
import { v4 as uuidv4 } from 'uuid';

/**
 * @desc  This is the step-5 of job application form which contains document details of the job applicant.
 * @param {function} setStatus It is a useState function used to update the current form name.
 * @param {object} editable   It is used to enable/disable the fields and edit button based on the respective section in the review page.
 * @param {function} setEditable  It is a useState function used to update respective section status in the review page.
 * @param {string} value Name of the current form
 */
const DocumentsRequired = ({ setStatus, editable, setEditable, value }) => {
  let fileReader;
  const [details, setDetails] = useState({
    resume_name: 'Attach Resume',
    resume: {},
    cover_letter_name: 'Attach Cover Letter',
    cover_letter: {},
    identification_proof_name: 'Attach Identification Proof',
    identification_proof: {},
    photograph_name: 'Attach Photograph',
    photograph: {}
  });

  const [error, setError] = useState(false);

  // Function to validate the form values
  const validateFormValues = () => {
    let { resume_name, cover_letter_name, identification_proof_name, photograph_name } =
      details;

    // Validating resume only
    if (resume_name !== 'Attach Resume') {
      return false;
    } else {
      return true;
    }
  };
  //  This function is used to save the current form data to session storage and redirect to the next page i.e. Profile Info page.
  const handleNext = () => {
    if (validateFormValues()) {
      setError(true);
    } else {
      let formData = getDataFromLocalStorage('formData');
      let sessionId = formData.sessionId;
      let firstName = formData['profile_info']['firstName'];
      let lastName = formData['profile_info']['lastName'];

      const data = new FormData();

      // Always append key values pairs first to formData before appending files otherwise they will give undefined in the backend.

      data.append('name', `${firstName}_${lastName}`);
      data.append('sessionId', sessionId);
      data.append('email', 'engineer873@gmail.com');

      for (let key in details) {
        if (!key.includes('name') && details[key]?.['name']) {
          let name;

          if (key.includes('_')) {
            name = key.split('_').join('');
          } else {
            name = key;
          }

          data.append(
            'file',
            details[key],
            `${name}.${details[key].name.split('.').pop()}`
          );
        }
      }

      // calling API to save files in the backend folder.(i.e upload/temp-files)
      axios
        .post(`${appConfig.api}/file-upload`, data, {
          'Content-Type': 'multipart/form-data'
        })
        .then((res) => {
          console.log(res, 'res');
        })
        .catch((err) => {
          console.log(err);
        });

      let Data = getDataFromLocalStorage('formData');
      Data.documents = details;
      setDataToLocalStorage('formData', Data);

      setDataToLocalStorage('formStatus', 'Profile Info');
      setStatus('Profile Info');
    }
  };

  // This function is used to save data to session storage once it has been modified in the review page.
  const handleSave = () => {
    console.log(details, 'details');
    let data = getDataFromLocalStorage('formData');
    let sessionId = data.sessionId;

    let formData = new FormData();

    // Always append key values pairs first to formData before appending files otherwise they will give undefined in the backend.
    formData.append('sessionId', sessionId);

    for (let key in details) {
      if (!key.includes('name') && details[key]?.['name']) {
        let name;

        if (key.includes('_')) {
          name = key.split('_').join('');
        } else {
          name = key;
        }

        formData.append(
          'file',
          details[key],
          `${name}.${details[key].name.split('.').pop()}`
        );
      }
    }

    // calling API to modilfy files in the backend folder.(i.e upload/temp-files)
    axios
      .post(`${appConfig.api}/modify-files`, formData, {
        'Content-Type': 'multipart/form-data'
      })
      .then((res) => {
        console.log(res, 'res');
      })
      .catch((err) => {
        console.log(err);
      });

    let Data = getDataFromLocalStorage('formData');
    Data[value] = details;
    setDataToLocalStorage('formData', Data);

    // Editable flag is used to handle the edit button visibility of particular section in the review page
    setEditable({ ...editable, [value]: true });
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    console.log(content);
    // … do something with the 'content' …
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsBinaryString(file);
  };

  // Initially we are fetching data from the session storage and we are setting the same to local state i.e. details
  useEffect(() => {
    // We are inserting data to local stoarge if user opens the form for the first time or else we will retrieve the data from the session storage.
    if (getDataFromLocalStorage('formData') === null) {
      let sessionId = uuidv4();
      let formData = {
        profile_info: {
          jobType: '',
          post_id: '',
          post_name: '',
          firstName: '',
          middleName: '',
          lastName: '',
          dob: '',
          gender: '',
          nationality: '',
          maritalStatus: '',
          speciallyAbled: '',
          primaryContactNumber: '',
          primaryEmailId: '',
          pan: '',
          currentAddress: '',
          permanentAddress: '',
          linkedin: '',
          github: '',
          other: ''
        },
        education_details: [
          {
            flag: '',
            courseName: '',
            specialization: '',
            educationType: '',
            collegeName: '',
            startDate: '',
            endDate: '',
            qualificationYear: '',
            percentage: ''
          }
        ],
        employment_details: [
          {
            flag: '',
            companyName: '',
            designation: '',
            skillSet: '',
            department: '',
            startDate: '',
            endDate: '',
            noticePeriod: ''
          }
        ],
        certificate_details: [
          {
            certificateNumber: '',
            instituteName: '',
            effectiveDate: '',
            expirationDate: ''
          }
        ],
        documents: {
          resume_name: 'Attach Resume',
          resume: {},
          cover_letter_name: 'Attach Cover Letter',
          cover_letter: {},
          identification_proof_name: 'Attach Identification Proof',
          identification_proof: {},
          photograph_name: 'Attach Photograph',
          photograph: {}
        },
        acknowledge: {
          previouslyWorked: '',
          previousSolutionecId: '',
          anyoneKnowInSolutionec: '',
          refererSolutionecId: '',
          howDoYouKnowAboutSolutionec: '',
          Other: '',
          checkbox: false
        },
        sessionId
      };
      console.log(formData, 'formData');
      setDataToLocalStorage('formData', formData);
    } else {
      let Data = getDataFromLocalStorage('formData');
      setDetails(Data.documents);
    }
  }, []);

  return (
    <div className='pb-12 xl:pl-48  text-black lg:pb-24 lg:mt-6  w-screen'>
      <div className='h-28 w-11/12 bg-gray-100 my-6 rounded-[35px] py-6'>
        <div className='ml-12 '>
          <p className='text-2xl font-medium'>Accepted File Types</p>
          <p className='text-base mt-1 font-medium'>
            DOCX, PDF, Image and Text (MSG, PPT and XLSX file types are not accepted for
            resume or cover letters)
          </p>
        </div>
      </div>
      <div className='w-11/12 m-auto  lg:w-[70%] lg:m-0'>
        <div className='flex flex-col justify-between mt-10 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>
              Resume <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
            </label>
            <div
              className={
                'w-full  h-14 mt-6 rounded-full flex items-center  ' +
                (editable?.[value]
                  ? ' bg-[#fafafa] cursor-not-allowed'
                  : ' bg-white cursor-pointer') +
                (error && details.resume_name === 'Attach Resume'
                  ? ' border-[1px] border-red-500'
                  : ' border-2 ')
              }
            >
              <input
                id='upload_resume'
                type='file'
                className='hidden text-small w-[88%] border-gray h-full px-4 rounded-tl-full rounded-bl-full  outline-none lg:text-lg'
                onChange={(e) => {
                  // console.log(e.target.files[0], e.target.files[0].blobFile, 'file');
                  handleFileChosen(e.target.files[0]);
                  setDetails({
                    ...details,
                    resume: e.target.files[0],
                    resume_name: e.target.files[0].name
                  });
                }}
                disabled={editable?.[value]}
                accept='image/*,.pdf,.docx,.txt'
                // value='c:/passwords.txt'
              />
              <label
                htmlFor='upload_resume'
                className='w-full flex justify-between   rounded-tl-full rounded-bl-full text-small lg:text-lg px-4  cursor-pointer '
              >
                <p
                  className={
                    details.resume_name === 'Attach Resume'
                      ? 'text-gray-400'
                      : 'text-black '
                  }
                >
                  {details.resume_name}
                </p>
                <span>
                  <div className='rounded-full w-8 h-8 border-2 flex items-center'>
                    <img
                      src='/assets/images/career/add_file.png'
                      alt='add_file_icon'
                      className='-rotate-45 w-3/4 m-auto '
                    />
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-between mt-10 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>Cover Letter</label>
            <div
              className={
                'w-full  h-14 mt-6 rounded-full flex items-center border-2 ' +
                (editable?.[value]
                  ? ' bg-[#fafafa] cursor-not-allowed'
                  : ' bg-white cursor-pointer')
              }
            >
              <input
                id='upload_cover_letter'
                type='file'
                className='hidden text-small w-[88%] border-gray h-full px-4 rounded-tl-full rounded-bl-full  outline-none lg:text-lg'
                onChange={(e) => {
                  setDetails({
                    ...details,
                    cover_letter: e.target.files[0],
                    cover_letter_name: e.target.files[0].name
                  });
                }}
                disabled={editable?.[value]}
                accept='image/*,.pdf,.docx,.txt'
              />
              <label
                htmlFor='upload_cover_letter'
                className='w-full flex justify-between   rounded-tl-full rounded-bl-full text-small lg:text-lg px-4  cursor-pointer '
              >
                <p
                  className={
                    details.cover_letter_name === 'Attach Cover Letter'
                      ? 'text-gray-400'
                      : 'text-black '
                  }
                >
                  {details.cover_letter_name}
                </p>
                <span>
                  <div className='rounded-full w-8 h-8 border-2 flex items-center'>
                    <img
                      src='/assets/images/career/add_file.png'
                      alt='add_file_icon'
                      className='-rotate-45 w-3/4 m-auto '
                    />
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-between mt-10 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%] '>
            <label className='text-lg font-bold lg:text-2xl'>Identification Proof</label>
            <div
              className={
                'w-full  h-14 mt-6 rounded-full flex items-center  border-2' +
                (editable?.[value]
                  ? ' bg-[#fafafa] cursor-not-allowed'
                  : ' bg-white cursor-pointer')
              }
            >
              <input
                id='upload_proof'
                type='file'
                className='hidden text-small w-[88%] border-gray h-full px-4 rounded-tl-full rounded-bl-full  outline-none lg:text-lg'
                onChange={(e) => {
                  setDetails({
                    ...details,
                    identification_proof: e.target.files[0],
                    identification_proof_name: e.target.files[0].name
                  });
                }}
                disabled={editable?.[value]}
                accept='image/*,.pdf,.docx,.txt'
              />
              <label
                htmlFor='upload_proof'
                className='w-full flex justify-between   rounded-tl-full rounded-bl-full text-small lg:text-lg px-4  cursor-pointer '
              >
                <p
                  className={
                    details.identification_proof_name === 'Attach Identification Proof'
                      ? 'text-gray-400'
                      : 'text-black '
                  }
                >
                  {details.identification_proof_name}
                </p>
                <span>
                  <div className='rounded-full w-8 h-8 border-2 flex items-center'>
                    <img
                      src='/assets/images/career/add_file.png'
                      alt='add_file_icon'
                      className='-rotate-45 w-3/4 m-auto '
                    />
                  </div>
                </span>
              </label>
            </div>
            <span className='lg:text-lg mt-4'>
              Note: aadhaar card, pan card or driving licence etc.
            </span>
          </div>
          <div className='mt-6 flex flex-col w-full lg:w-[48%] xl:mt-0 lg:mt-0'>
            <label className='text-lg font-bold lg:text-2xl'>Passport Photograph</label>
            <div
              className={
                'w-full  h-14 mt-6 rounded-full flex items-center  border-2 ' +
                (editable?.[value]
                  ? ' bg-[#fafafa] cursor-not-allowed'
                  : ' bg-white cursor-pointer')
              }
            >
              <input
                id='upload_photograph'
                type='file'
                className='hidden text-small w-[88%] border-gray h-full px-4 rounded-tl-full rounded-bl-full  outline-none lg:text-lg'
                onChange={(e) => {
                  setDetails({
                    ...details,
                    photograph: e.target.files[0],
                    photograph_name: e.target.files[0].name
                  });
                }}
                disabled={editable?.[value]}
                accept='image/*,.pdf,.docx,.txt'
              />
              <label
                htmlFor='upload_photograph'
                className='w-full flex justify-between   rounded-tl-full rounded-bl-full text-small lg:text-lg px-4  cursor-pointer '
              >
                <p
                  className={
                    details.photograph_name === 'Attach Photograph'
                      ? 'text-gray-400'
                      : 'text-black '
                  }
                >
                  {details.photograph_name}
                </p>
                <span>
                  <div className='rounded-full w-8 h-8 border-2 flex items-center'>
                    <img
                      src='/assets/images/career/add_file.png'
                      alt='add_file_icon'
                      className='-rotate-45 w-3/4 m-auto '
                    />
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>

        {error && details.resume_name === 'Attach Resume' ? (
          <p className='mt-4 text-red-500 text-lg'>
            <span
              style={{
                color: 'red',
                fontWeight: 'normal',
                marginRight: '4px',
                fontSize: '20px'
              }}
            >
              *
            </span>
            Resume is mandatory.
          </p>
        ) : null}

        {setStatus ? (
          <div className='flex w-[330px]  h-full justify-between'>
            {/* <button
              onClick={() => {
                setDataToLocalStorage('formStatus', 'Certificate Details');
                setStatus('Certificate Details');
              }}
              className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
            >
              Previous
            </button> */}
            <button
              onClick={() => {
                handleNext();
              }}
              className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
            >
              Next
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              handleSave();
            }}
            disabled={editable[value]}
            className={
              'text-white text-xl mt-8 font-bold rounded-full px-10 py-3 lg:text-2xl lg:mt-16' +
              (editable[value] ? ' bg-gray-400' : ' bg-black')
            }
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default DocumentsRequired;
