import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
  deleteDataFromLocalStorage
} from '../../../helpers/util';
import { flagOptions, sourceOptions, jobPortalOptions } from '../../../helpers/constants';
import Dropdown from './Dropdown';
import { appConfig } from '../../../appConfig';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

/**
 * @desc  This is the step-6 of job application form which contains acknowledge details of the job applicant.
 * @param {function} setStatus It is a useState function used to update the current form name.
 * @param {object} editable   It is used to enable/disable the fields and edit button based on the respective section in the review page.
 * @param {function} setEditable  It is a useState function used to update respective section status in the review page.
 * @param {string} value Name of the current form
 */
const AcknowledgeForm = ({ setStatus, editable, setEditable, value, jobInfo }) => {
  const [details, setDetails] = useState({
    previouslyWorked: '',
    previousSolutionecId: '',
    anyoneKnowInSolutionec: '',
    refererSolutionecId: '',
    howDoYouKnowAboutSolutionec: '',
    Other: '',
    jobPortalName: '',
    checkbox: false
  });

  const [error, setError] = useState(false);

  const history = useHistory();

  // This function is used to save data to session storage once it has been modified in the review page.
  const handleSave = () => {
    let Data = getDataFromLocalStorage('formData');
    Data[value] = details;
    setDataToLocalStorage('formData', Data);
    setEditable({ ...editable, [value]: true });
  };

  // Function to validate the form values
  const validateFormValues = () => {
    let {
      previouslyWorked,
      previousSolutionecId,
      anyoneKnowInSolutionec,
      refererSolutionecId,
      howDoYouKnowAboutSolutionec,
      Other,
      jobPortalName,
      checkbox
    } = details;

    if (
      previouslyWorked !== '' &&
      anyoneKnowInSolutionec !== '' &&
      (previouslyWorked?.value === 'Yes' ? previousSolutionecId !== '' : true) &&
      (anyoneKnowInSolutionec?.value === 'Yes' ? refererSolutionecId !== '' : true) &&
      howDoYouKnowAboutSolutionec?.value !== '' &&
      (howDoYouKnowAboutSolutionec?.value === 'Other' ? Other !== '' : true) &&
      (howDoYouKnowAboutSolutionec?.value === 'Job Portal' ? jobPortalName !== '' : true)
    ) {
      return false;
    } else {
      return true;
    }
  };

  // This function is to submit the form details to backend.
  const submitBtn = async () => {
    if (validateFormValues()) {
      setError(true);
    } else {
      let currentData = getDataFromLocalStorage('formData');
      currentData.acknowledge = { ...details };

      let Data = { ...currentData };
      setDataToLocalStorage('formData', currentData);

      // Formatting the form data according to the backend data/schema

      let positionApplied = jobInfo?.name;

      //  profile_info data

      Data['profile_info']['positionApplied'] = positionApplied;
      Data['profile_info']['gender'] = Data['profile_info']['gender']['value'];
      Data['profile_info']['maritalStatus'] =
        Data['profile_info']['maritalStatus']['value'];
      Data['profile_info']['speciallyAbled'] =
        Data['profile_info']['speciallyAbled']['value'];

      // employment details
      Data['employment_details'].forEach((item) => {
        item['currentEmployer'] = item['flag'].value;
      });

      // education details
      Data['education_details'].forEach((item) => {
        item['educationType'] = item['educationType'].value;
        item['higherEducation'] = item['flag'].value;
      });

      // acknowledge details
      Data['acknowledge']['anyoneKnowInSolutionec'] =
        Data['acknowledge']['anyoneKnowInSolutionec']['value'];
      Data['acknowledge']['previouslyWorked'] =
        Data['acknowledge']['previouslyWorked']['value'];
      Data['acknowledge']['howDoYouKnowAboutSolutionec'] =
        Data['acknowledge']['howDoYouKnowAboutSolutionec']['value'] === 'Other'
          ? Data['acknowledge']['Other']
          : Data['acknowledge']['howDoYouKnowAboutSolutionec']['value'] === 'Job Portal'
          ? Data['acknowledge']['jobPortalName']['value']
          : Data['acknowledge']['howDoYouKnowAboutSolutionec']['value'];
      Data['acknowledge'] = [Data['acknowledge']];

      console.log(Data, 'Data');

      axios
        .post(`${appConfig.api}/add-user`, Data)
        .then((res) => {
          if (res['data']['error']) {
            toast.error(res['data']['error']);
          } else {
            toast.success(res['data']['message'], { autoClose: 2000 });
            deleteDataFromLocalStorage('formData');
            deleteDataFromLocalStorage('formStatus');
            history.push('/join-us/joblist/experts');
          }
        })
        .catch((err) => {
          toast.error('Something went wrong please try again later.', {
            autoClose: 2000
          });
          console.log(err, 'err');
        });

      // Editable flag is used to handle the edit button visibility of particular section in the review page
      if (setEditable) {
        setEditable({ ...editable, [value]: true });
      }
    }
  };

  // Initially we are fetching data from the session storage and we are setting the same to local state i.e. details
  useEffect(() => {
    let Data = getDataFromLocalStorage('formData');
    setDetails(Data.acknowledge);
  }, []);

  // Whenever any field is updated we are updating the same in the session storage as well.
  useEffect(() => {
    let LocalStorageData = getDataFromLocalStorage('formData');
    LocalStorageData.acknowledge = details;
    setDataToLocalStorage('formData', LocalStorageData);
  }, [details]);

  useEffect(() => {
    console.log(details, 'details');
  }, [details]);

  return (
    <div className='pb-12 xl:pl-48  text-black lg:pb-24 lg:mt-6  w-screen'>
      <div className='w-11/12 m-auto  lg:w-full lg:m-0'>
        <div className='flex flex-col justify-between mt-4 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[52%] '>
            <label className='text-lg font-bold lg:text-2xl'>
              Have you worked previously with Solutionec Pvt Ltd?
              <span style={{ color: 'red', fontWeight: 'normal', marginLeft: '4px' }}>
                *
              </span>
            </label>
            <div className='mt-6 w-full xl:w-[70%]'>
              <Dropdown
                name='previouslyWorked'
                value={details.previouslyWorked}
                setDetails={setDetails}
                details={details}
                setValue='previouslyWorked'
                options={flagOptions}
                placeholder='Select Option'
                error={error}
                editableFlag={editable?.acknowledge}
                currentForm='Acknowledgement'
              />
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-between mt-10 lg:flex-row'>
          <div className='flex flex-col xl:w-[48%]'>
            <label className='text-lg font-bold lg:text-2xl'>
              If Yes, Solutionec Employee ID
              {details.previouslyWorked?.value === 'Yes' ? (
                <span style={{ color: 'red', fontWeight: 'normal', marginLeft: '2px' }}>
                  *
                </span>
              ) : null}
            </label>
            <input
              type='text'
              value={details.previousSolutionecId}
              className={
                ' text-small mt-6 w-full xl:w-3/4 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                (error &&
                details.previouslyWorked.value === 'Yes' &&
                !details.previousSolutionecId
                  ? ' border-[1px] border-red-500'
                  : ' border-2 ')
              }
              onInput={(e) => {
                setDetails({
                  ...details,
                  previousSolutionecId: e.target.value.toUpperCase()
                });
              }}
              disabled={editable?.[value]}
              placeholder='Mention Employee ID'
            />
          </div>
        </div>
        <div className='flex flex-col justify-between mt-10 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[48%]'>
            <label className='text-lg font-bold lg:text-2xl'>
              Do you know anyone working in Solutionec?
              <span style={{ color: 'red', fontWeight: 'normal', marginLeft: '4px' }}>
                *
              </span>
            </label>
            <div className='mt-6 w-full xl:w-3/4'>
              <Dropdown
                name='anyoneKnowInSolutionec'
                value={details.anyoneKnowInSolutionec}
                setDetails={setDetails}
                details={details}
                setValue='anyoneKnowInSolutionec'
                options={flagOptions}
                placeholder='Select Option'
                error={error}
                editableFlag={editable?.acknowledge}
                currentForm='Acknowledgement'
              />
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-between mt-10 lg:flex-row'>
          <div className='flex flex-col xl:w-[48%]'>
            <label className='text-lg font-bold lg:text-2xl'>
              If Yes, Solutionec Employee ID
              {details.anyoneKnowInSolutionec?.value === 'Yes' ? (
                <span style={{ color: 'red', fontWeight: 'normal', marginLeft: '2px' }}>
                  *
                </span>
              ) : null}
            </label>
            <input
              type='text'
              value={details.refererSolutionecId}
              className={
                'text-small mt-6 w-full xl:w-3/4 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                (error &&
                details.anyoneKnowInSolutionec.value === 'Yes' &&
                !details.refererSolutionecId
                  ? ' border-[1px] border-red-500'
                  : ' border-2 ')
              }
              onInput={(e) => {
                setDetails({
                  ...details,
                  refererSolutionecId: e.target.value.toUpperCase()
                });
              }}
              disabled={editable?.[value]}
              placeholder='Mention Employee ID'
            />
          </div>
        </div>
        <div className=' justify-between mt-10 lg:flex-row'>
          <div className='flex flex-col w-full lg:w-[50%]  '>
            <label className='text-lg font-bold lg:text-2xl'>
              How do you came to know about Solutionec?
              <span style={{ color: 'red', fontWeight: 'normal', marginLeft: '4px' }}>
                *
              </span>
            </label>
            {/* <div className='mt-6 w-full xl:w-3/4'>
              <Dropdown
                name='howDoYouKnowAboutSolutionec'
                value={details.howDoYouKnowAboutSolutionec}
                setDetails={setDetails}
                details={details}
                setValue='howDoYouKnowAboutSolutionec'
                options={sourceOptions}
                placeholder='Select Option'
                error={error}
                editableFlag={editable?.acknowledge}
                currentForm='Acknowledgement'
              />
            </div> */}
          </div>
          <div className='flex  mt-6 flex-col xl:flex-row'>
            <div className=' w-full xl:w-[36%]'>
              <Dropdown
                name='howDoYouKnowAboutSolutionec'
                value={details.howDoYouKnowAboutSolutionec}
                setDetails={setDetails}
                details={details}
                setValue='howDoYouKnowAboutSolutionec'
                options={sourceOptions}
                placeholder='Select Option'
                error={error}
                editableFlag={editable?.acknowledge}
                currentForm='Acknowledgement'
              />
            </div>
            {details.howDoYouKnowAboutSolutionec?.value === 'Other' ? (
              <input
                type='text'
                value={details.Other}
                className={
                  ' text-small mt-6 xl:mt-0  xl:ml-4 w-full xl:w-[36%] border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                  (error &&
                  details.howDoYouKnowAboutSolutionec.value === 'Other' &&
                  !details.Other
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                onInput={(e) => {
                  setDetails({
                    ...details,
                    Other: e.target.value
                  });
                }}
                disabled={editable?.[value]}
                placeholder='Mention the reason here.'
              />
            ) : null}
            {details.howDoYouKnowAboutSolutionec?.value === 'Job Portal' ? (
              <div className=' w-full xl:w-[36%] xl:mt-0  xl:ml-4'>
                <Dropdown
                  name='jobPortalName'
                  value={details.jobPortalName}
                  setDetails={setDetails}
                  details={details}
                  setValue='jobPortalName'
                  options={jobPortalOptions}
                  placeholder='Select Option'
                  error={error}
                  editableFlag={editable?.acknowledge}
                  currentForm='Acknowledgement'
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className='mt-16 flex items-center xl:w-3/4'>
          <input
            type='checkbox'
            className='mr-6 h-10 w-10 '
            checked={details.checkbox}
            onChange={(e) => {
              setDetails({ ...details, checkbox: e.target.checked });
            }}
            disabled={editable?.[value]}
          />
          <p className='text-lg font-bold lg:text-2xl mt-6'>
            I agree and declare that the information provided is true and correct. I
            hearby consent I can be reached using the information provided
          </p>
        </div>
        {error &&
        (!details.previouslyWorked ||
          !details.anyoneKnowInSolutionec ||
          (details.previouslyWorked?.value === 'Yes' && !details.previousSolutionecId) ||
          (details.anyoneKnowInSolutionec?.value === 'Yes' &&
            !details.refererSolutionecId &&
            !details.howDoYouKnowAboutSolutionec?.value &&
            (details.howDoYouKnowAboutSolutionec?.value === 'Other'
              ? !details.Other
              : true))) ? (
          <p className='mt-4 text-red-500 text-lg'>
            <span
              style={{
                color: 'red',
                fontWeight: 'normal',
                marginRight: '4px',
                fontSize: '20px'
              }}
            >
              *
            </span>
            Please check the highlighted fields.
          </p>
        ) : (
          ''
        )}

        {!value ? (
          <div className='flex w-[330px]  h-full justify-between '>
            <button
              onClick={() => {
                let LocalStorageData = getDataFromLocalStorage('formData');
                LocalStorageData.acknowledge = details;
                setDataToLocalStorage('formData', LocalStorageData);
                setDataToLocalStorage('formStatus', 'Review');
                setStatus('Review');
              }}
              className={
                'bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
              }
            >
              Review
            </button>
            <button
              onClick={() => {
                submitBtn();
              }}
              disabled={!details.checkbox}
              className={
                'text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16' +
                (!details.checkbox ? ' bg-gray-400' : ' bg-black')
              }
            >
              Submit
            </button>
          </div>
        ) : (
          <div className='flex w-[280px]  h-full justify-between '>
            <button
              onClick={() => {
                handleSave();
              }}
              disabled={editable[value]}
              className={
                'text-white text-xl mt-8 font-bold rounded-full px-8 py-3 lg:text-2xl lg:mt-16' +
                (editable[value] ? ' bg-gray-400' : ' bg-black')
              }
            >
              Save
            </button>
            <button
              onClick={() => {
                submitBtn();
              }}
              disabled={!details.checkbox}
              className={
                ' text-white text-xl mt-8 font-bold rounded-full px-8 py-3 lg:text-2xl lg:mt-16' +
                (!details.checkbox ? ' bg-gray-400' : ' bg-black')
              }
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AcknowledgeForm;
