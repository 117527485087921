import { useEffect } from 'react';
import Select, { components } from 'react-select';
import { customStylesSelect } from '../../../helpers/util';

const Dropdown = ({
  name,
  value,
  setDetails,
  details,
  error,
  setValue,
  options,
  placeholder,
  editableFlag,
  currentForm,
  index
}) => {
  const DropdownIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        strokeWidth={4}
      >
        <path strokeLinecap='round' strokeLinejoin='round' d='M19 9l-7 7-7-7' />
      </svg>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <Select
        name={name}
        defaultValue={value}
        value={value}
        onChange={(value) => {
          if (currentForm === 'Profile_Info' || currentForm === 'Acknowledgement') {
            setDetails({ ...details, [setValue]: value });
          } else {
            let Data = details;
            Data[index][setValue] = value;
            setDetails([...Data]);
          }
        }}
        options={options}
        placeholder={placeholder}
        styles={
          currentForm === 'Profile_Info' || currentForm === 'Acknowledgement'
            ? customStylesSelect(error && !details[name], editableFlag)
            : customStylesSelect(error && !details[index][name], editableFlag)
        }
        components={{ DropdownIndicator }}
        isDisabled={editableFlag}
      />
    </>
  );
};

export default Dropdown;
