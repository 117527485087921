import { darkThemePages } from './constants';

export const getViewport = () => {
  var viewPortWidth;
  var viewPortHeight;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth !== 'undefined') {
    viewPortWidth = window.innerWidth;
    viewPortHeight = window.innerHeight;
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (
    typeof document.documentElement !== 'undefined' &&
    typeof document.documentElement.clientWidth !== 'undefined' &&
    document.documentElement.clientWidth !== 0
  ) {
    viewPortWidth = document.documentElement.clientWidth;
    viewPortHeight = document.documentElement.clientHeight;
  }

  // older versions of IE
  else {
    viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
    viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
  }
  return [viewPortWidth, viewPortHeight];
};

export const isInViewportExact = (element, source) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * returns true or false based on the percent passed.
 * Eg: percent = 10, Checks if the passed element is 10% or more visible in the viewports
 * @param {HTMLElement} element
 * @param {Number} percentVisible
 * @returns {Boolean}
 */
export const isInViewport = (element, percentVisible) => {
  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  return !(
    Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) <
      percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
  );
};

/**
 * @param {NodeList} list
 * @returns {HTMLElement}
 */
export const isInViewportAmongMany = (list) => {
  if (!list) {
    return;
  }

  const result = Array.from(list)
    .map((element) => {
      return isInViewport(element, 5) ? element : false;
    })
    .filter((result) => {
      return result !== false;
    });

  if (result.length) {
    return result[0];
  }
};

export const getCurrentPageTheme = () => {
  const path = window.location.pathname;
  return darkThemePages.includes(path) ? 'dark' : 'light';
};

export const getAbsoluteHrefPath = (path) => {
  if (!path || path.charAt(0) !== '/') {
    return '';
  }
  const absPath = path.split('/').reverse()[0];

  return absPath === '' ? 'home' : absPath;
};

// Local Storage Data Manipulations

export const setDataToLocalStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getDataFromLocalStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const deleteDataFromLocalStorage = (key) => {
  sessionStorage.removeItem(key);
};

// React Select Dropdown Custom Styles

export const customStylesSelect = (flag, editable) => {
  return {
    // selected options
    multiValue: (styles) => ({
      ...styles,
      fontSize: '25px',
      fontWeight: '500'
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none'
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      width: '55px'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: 'black'
      // border: '1px solid red'
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: '19px',
      fontWeight: '500'
    }),
    option: (styles) => ({
      ...styles,
      fontSize: '19px',
      cursor: 'pointer'
    }),
    // select tag
    control: (styles, state) => ({
      ...styles,
      border: flag ? '1px solid red' : '2px solid #e5e7eb',
      minHeight: '56px',
      borderRadius: '35px',
      cursor: editable ? 'not-allowed' : 'pointer',
      paddingLeft: '5px',
      '&:hover': {
        borderColor: '#e6eaf5'
      },
      boxShadow: state.isFocused ? 0 : 0 // This line disable the blue border
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'rgb(156 163 175)',
      fontSize: '18px'
    })
  };
};
