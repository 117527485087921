import BlogDescription from '../components/landing-page/blogs/BlogDescription';
import Expertise from '../components/landing-page/blogs/Expertise';
import { Redirect } from 'react-router-dom';

const Blogs = () => {
  // const [id, setId] = useState(window.location.pathname.split('/').pop() - 1);
  const id = window.location.pathname.split('/').pop() - 1;

  const blogs = [
    {
      id: 1,
      imgSrc: '/assets/images/landing-page/blogs/Pandemic_Leaders.jpg',
      date: '21, Feb 2022',
      title1: 'The Post',
      title2: 'Pandemic Leaders',
      articleLink:
        'https://www.linkedin.com/pulse/post-pandemic-leaders-solutionec/?trackingId=EZOA8rfoTUm76cGQCE1erw%3D%3D',
      description: (
        <>
          <div className='mt-6 lg:ml-48 lg:w-4/5 w-11/12 m-auto'>
            <div className='lg:text-xl font-bold  '>
              In the post-pandemic world, although your leaders might be the same, their
              style of leadership has unquestionably taken a 360-degree turn. The onset of
              Covid-19 changed the way the leadership community envisioned team operations
              at the workplace. The past two years beyond the shadow of a doubt have
              pushed teams and their leaders over the limit, both physically and mentally.
              Some of us have lost our near and dear ones while some suffered huge
              financial losses, which has kickstarted economic turmoil at a larger level.
            </div>
            <div className='mt-6 lg:text-xl'>
              <div>
                Businesses, both private and government sectors have also taken a beating
                with people losing jobs or pay cuts leading to a state of imbalance in our
                day-to-day lives. Amidst this, the biggest challenge faced by corporates,
                especially by the leadership team has been – Management.
              </div>
            </div>
            <div className='mt-6 lg:text-xl'>
              With the “work from home” culture taking a front seat, leaders and managers
              have found themselves at a tough crossroad where they have to manage people
              and projects, while also striking the right balance of maintaining
              productivity and being empathetic to their resources.
            </div>
            <div className='mt-6 lg:text-xl'>
              As managers and leaders, it is crucial to understand the mental and physical
              state of their teams, especially during times like these, and engage with
              every individual accordingly. Today, this becomes pivotal in ensuring a
              smooth and effective workflow. It is also important to remind resources of
              their goals and help them work towards achieving them. Having regular
              touchpoints, involving in discussions on daily tasks and issues, guiding the
              team, and providing continuous feedback has helped maintain the momentum.
            </div>
            <div className='mt-6 lg:text-xl'>
              Though there is a sharp decline in F2F connect between colleagues and
              leaders, teams have developed a better bonding (with online meetings) by
              initiating various concepts such as ice-breaking events, fun activities,
              role-plays, etc., into their work culture. This is seldom seen in office
              culture.
              <div></div>
            </div>
            <div className='mt-6 lg:text-xl'>
              This has led to a better understanding, collaboration, teamwork, and overall
              effective management. Though this pandemic has been devastating on a larger
              scale, it has somehow brought people closer, mitigating the usual
              human-human disputes and a sense of peer values.
            </div>
            <div className='mt-6 lg:text-xl'>
              According to the experts from WHO and Anthony Fauci - Chief Medical Advisor
              to the President of the United States, we should not expect an end to this
              pandemic anytime soon since we are only in the first phase of the Covid
              pandemic. There are four more stages to come - deceleration, control,
              elimination, and finally eradication.
            </div>
            <div className='mt-6 lg:text-xl'>
              We still have a long way with this pandemic and till then let us all unite
              to fight this collectively, be responsible and make sure we work towards
              achieving our goals for the life we envisioned.
            </div>
          </div>
        </>
      ),
      author: 'Vivek Gottipati',
      role: 'Manager'
    },
    {
      id: 2,
      imgSrc: '/assets/images/landing-page/blogs/Value_Driven_Workspace.jpg',
      date: '27, April 2022',
      title1: 'Value-Driven Workplace:',
      title2: 'The New Shade of Corporate',
      articleLink:
        'https://www.linkedin.com/pulse/value-driven-workplace-new-shade-corporate-solutionec/?trackingId=SER5jeQ8nMAgkLum4v6htw%3D%3D',
      description: (
        <>
          <div className='mt-6 lg:ml-48 lg:w-4/5 w-11/12 m-auto'>
            <div className='lg:text-xl font-bold  '>
              Gone are the days when interviews were a one-sided desk show where the
              interviewer would inspect a candidate’s CV to decide their future in the
              company. Today, with the rising competition in the talent market, candidates
              too stands at par with the companies as they speculate on each and every
              aspect of the workplace and what it has to offer. Among company really
              stands for. Aspects like healthy work culture, supportive mentors, and most
              of all organizational values have taken a front seat.
            </div>
            <div className='mt-6 lg:text-xl'>
              <div>All in all, do they care enough?</div>
            </div>
            <div className='mt-6 lg:text-xl'>
              On the flip side of the coin, in organizations, the term ‘values’ has always
              been soft. However, the post-covid scenario has brought in a more widespread
              and varied mindset among enterprises. Now, organizational values play a
              vital role for any organization to grow, retain talent, and even in making
              its business strategies. With work culture changing at a rapid pace from
              ‘work from office’ to ‘work from home,’ values of trust and dedication to
              work are even more crucial today for the growth of business and people.
              Right people in right place can bring in results but for that now
              organization must focus on aligning people’s values to organizational
              values.
            </div>
            <div className='mt-6 lg:text-xl'>
              This is not the era to mindlessly follow your boss or leadership at the
              expense of your own values and convictions. Perhaps there were fewer
              opportunities in the 1990s, and employees were forced to perform whatever
              management required; there were no young entrepreneurs and startups
              sprouting up to compete with the big boys. It's no more competition between
              the sexes; Gen X and Gen Z are more aware and aggressive; they can speak
              their minds and are more honest, and they expect respect from the companies
              they work for.
            </div>
            <div className='mt-6 lg:text-xl'>
              It’s time for organizations to revisit their values and beliefs, in fact,
              values should be made an integral part of the performance management, and
              employees should be evaluated and rewarded based on the good values they
              have displayed. Not only with this sends out a strong message to employees
              but will also encourage them to follow and impart the same.The respect for
              the organizationwill increase among employees and the work culture will be
              more fulfilling and respectful.
              <div></div>
            </div>
            <div className='mt-6 lg:text-xl'>
              Values evolve from a culture so it will include organizational modes of
              conduct, communication styles, decision-making styles, and company
              strategies which eventually will result in a better company image and brand.
              After all,what people think about the company can make and break its
              business.
            </div>
            <div className='mt-6 lg:text-xl'>
              As an employee, if my values and beliefs are aligned with the company I work
              for, I will be a happy employee.I will not have to compromise on my beliefs
              or upbringing, and I would rather have more respect for the company I work
              for.Organization Values will help in employee retention, which is one of the
              biggest perils faced by most IT enterprises. The average attrition has
              crossed where 30% of Gen X & Gen Z is a generation that will only accept and
              work for companies where they get the freedom to work not compromising on
              their values and beliefs. They look for companies where they can speak their
              minds and have open communication without being anonymous.
            </div>
            <div className='mt-6 lg:text-xl'>
              In the long run, a belief-driven culture is what people look forward to now
              instead of bare minimums like high salaries and big fat packages If data is
              to be seen, we can see this shift occurring post-pandemic where the priority
              is -- “Better fit with my values, believes, and lifestyle.” If we see
              Edelman’s Trust Barometer report it clearly says the majority of employees
              have chosen jobs on trust and belief.
            </div>
            <div className='mt-6 lg:text-xl'>
              In its real essence, ‘values’ mean “principles or standards of behavior or
              one's judgment of what is important in life” and we are taught certain
              values since we are born, we see, observe, and live the values of our family
              and society teach us and it is easier to connect with workplaces with values
              that align with our values and respect our beliefs. It blooms a culture of
              trust and loyalty among employees.
            </div>
            <div className='mt-6 lg:text-xl'>
              For organizations to retain and have loyal employees, they need to focus on
              revisiting the organizational values and start living them to be a
              respectful example and a place to work where there are no Monday blues and
              employees look forward to starting their day. Now it’s time organizations
              bring the employees closer to them as family and bridge the gap between
              ‘living to work’ and ‘working to live.’ Shared Values are the future of a
              healthy organization. Understanding employees' values, beliefs, and
              lifestyle is the new key.
            </div>
          </div>
        </>
      ),
      author: 'Megha Soni',
      role: 'Senior Manager'
    },
    {
      id: 3,
      imgSrc: '/assets/images/landing-page/blogs/Care.jpg',
      date: '24, Mar 2022',
      title1: 'The New Age ',
      title2: 'of Palliative Care',
      articleLink:
        'https://www.linkedin.com/pulse/new-age-palliative-care-solutionec/?trackingId=EGRy5QHOPfdR1h3DHrdC1g%3D%3D',
      description: (
        <>
          <div className='mt-6 lg:ml-48 lg:w-4/5 w-11/12 m-auto'>
            <div className='lg:text-xl font-bold  '>
              The post-retirement period often comes with a bag of mixed feelings. For
              some, the anxiety of a completely new life takes over, whereas some are
              excited to explore every aspect of it. However different each of these
              approaches might be, one thing that remains constant among retired folks is
              the need to have a comfortable and healthy life after their many years of
              work. Whether it is their health or common lifestyle choices, families are
              highly focused on providing care for the elderly. Whether sick or not, this
              generation requires special care routines including nutrition monitoring and
              cholesterol checks, among many other things. And those who find themselves
              with a terminal illness, the need for care gets more and more crucial for
              them. This is where palliative care takes a front seat.
            </div>
            <div className='mt-6 lg:text-xl'>
              Although palliative care is often mistaken as end-of-life care, it is much
              more than that. In its very essence, the palliative care model was a
              patient-care model introduced for people surviving with chronic illnesses
              such as cancer or heart failure. The aim was to enhance the quality of life
              for the patient and their families as well.
            </div>
            <div className='mt-6 lg:text-xl'>
              Today, we are more than a decade into it and still lack clarity about the
              industry and its role to play in the healthcare sector. Over the year, the
              domain of palliative care has also witnessed several stigmas around it. To
              begin, among the Indian communities, palliative care sees a constant clash
              with the strong family values rooted in providing family care to the patient
              by the family members themselves. This represents a strong feeling of ‘care’
              and ‘devotion’ among each other, and any external healthcare service such as
              care centers or providers are frowned upon.
            </div>
            <div className='mt-6 lg:text-xl'>
              For a long time, Palliative care and hospice meant the same thing – ‘a final
              frontier for the destitute and a euphemism for dying; an inaccurate
              translation that effectively mislead communities for a large portion of the
              20th century. It was not until a few decades ago the awareness for
              Palliative care has hit the ground with a considerable rise in both access
              and acceptance towards this model of healthcare.
            </div>
            <div className='mt-6 lg:text-xl'>
              This reluctance to accept palliative care has several threads of hesitation,
              one of the most common one being ‘its association with death’ or the last
              stages of chronic illness. Another common idea that patients incorporate to
              avoid palliative care is ‘magical thinking, which in psychological terms is
              a form of denial of impending outcome or the seriousness of the illness. A
              large number of patients are also reported to have separation anxiety from
              their family members where the patients develop a fear of their last days
              being wasted on unyielding treatment results as they could use this tenure
              to spend quality time with their loved ones or even say goodbye.
              <div></div>
            </div>
            <div className='mt-6 lg:text-xl'>
              These stigmas as scattered and are born out of real people with valid fears
              and inhibitions. However, the first step in the direction of bridging the
              gap between this disinclination and the due acceptance of necessary care
              needs to be around the kind of narrative used to describe palliative care.
              There is still a lot of work required in reframing and refining the language
              associated with this area. The last two decades have seen palpable changes
              in the mindset of not just patients and their family members but also health
              care providers and policymakers with respect to the need for palliative care
              in India.
            </div>
            <div className='mt-6 lg:text-xl'>
              Further, a systematic and targeted education for medical staff is imperative
              for a major breakthrough within this sector as its need is not only battling
              the adequacy of resources but also a regressive and clouded mindset about
              the subject. The palliative care movement is one example of how health
              services can traverse beyond the biomedical model of health and can
              represent an affirmative act of living with dignity even whilst accepting
              that death is an inevitable part of life.
            </div>
            <div className='mt-6 lg:text-xl'>
              Here's to a new age that would look at Palliative care in a new light. Let’s
              work towards that.
            </div>
          </div>
        </>
      ),
      author: 'Praveen Ambrose',
      role: 'Associate Director'
    },
    {
      id: 4,
      imgSrc: '/assets/images/landing-page/blogs/Business_Intelligence.jpg',
      date: '02, June 2022',
      title1: 'The BI Know -',
      title2: `How's of the Modern World`,
      articleLink:
        'https://www.linkedin.com/pulse/business-intelligence-know-hows-modern-world-solutionec?trk=public_post-content_share-article',
      description: (
        <>
          <div className='mt-6 lg:ml-48 lg:w-4/5 w-11/12 m-auto'>
            <div className='lg:text-xl font-bold  '>
              For longer than I can remember, the term “spreadsheets” has always brought a
              frenzy among many working in the field of data. Although the purpose of the
              excel sheet was invented to simplify data cleaning and overall management,
              the widening horizon of big data had left several people daunted. There had
              to be a way found that could help data experts analyze and decode every
              intricacy of the data, and eventually the market. That is when Business
              Intelligence came in as a boon.
            </div>
            <div className='mt-6 lg:text-xl'>
              Broadly, the term combines business analytics, data mining, process
              analysis, and
              <a
                href='https://www.tableau.com/learn/articles/data-visualization'
                target='_blank'
                className='ml-1 mr-1'
                rel='noreferrer'
              >
                data visualization
              </a>
              to gain more actionable insights, helping organizations to make more
              data-driven decisions across various industries. The term business
              intelligence often refers to a range of tools that provide quick,
              easy-to-interpret access to insights.
            </div>
            <div className='mt-6 lg:text-xl'>
              Now it would be wrong to limit the entire definition of business
              intelligence to spreadsheets, however, it was one of the biggest challenges
              BI solved. To that extent, let’s understand the contribution of BI in
              detail:
            </div>
            <div className='mt-6 lg:text-xl'>How it is used:</div>
            <div className='mt-6 lg:text-xl'>
              In 1958, Hans Peter Luhn published the article “
              <a
                href='http://altaplana.com/ibmrd0204H.pdf'
                target='_blank'
                className='mx-1'
                rel='noreferrer'
              >
                A Business Intelligence System
              </a>
              ” in the IBM Journal of Research and Development, which became the
              foundation for the current understanding of BI.
              <div></div>
            </div>
            <div className='mt-6 lg:text-xl'>
              Over the past few years, organizations are fast adapting to ever-changing BI
              tools. From being accessible to only fortune 500 companies once upon a time,
              BI has become an indispensable part of many organizations. BI and data
              analytics technology are constantly evolving and there is no sign of slowing
              down.
            </div>
            <div className='mt-6 lg:text-xl'>
              Business intelligence serves to help executives, managers, and other
              operational heads make better business decisions based on data. Furthermore,
              it helps identify better business opportunities, cut costs, and detect
              inefficient business processes.
            </div>
            <div className='mt-6 lg:text-xl'>
              <div className='underline'>Enhancing Productivity:</div>
            </div>
            <div className='mt-6 lg:text-xl'>
              Businesses that successfully implement business intelligence can provide
              better customer service, allowing salespeople to use their selling time more
              productively. Owners can eliminate bottlenecks, refine their business
              processes, automate their daily tasks, etc.
            </div>
            <div className='mt-6 lg:text-xl'>
              <div className='underline'>An Intelligent Decision-Making Process:</div>
            </div>
            <div className='mt-6 lg:text-xl'>
              It is important that you have a firm grasp of your organizational data
              whether you are a business owner or a manager. BI helps you structure and
              analyze the information in your organization. BI aids businesses in making
              strategic decisions.
            </div>
            <div className='mt-6 lg:text-xl'>The past:</div>
            <div className='mt-6 lg:text-xl'>
              The first generation of BI software was largely managed by the IT
              department. The Extract, Transform, Load (ETL) paradigm combined data from
              multiple systems into a single database, for storage or analytics. The final
              report would be a static report. The entire process takes days to weeks
              depending on skilled staff. The face of BI changed after the introduction of
              big data in the 80s.
            </div>
            <div className='mt-6 lg:text-xl'>What lies ahead:</div>
            <div className='mt-6 lg:text-xl'>
              Future BI trends are all part of a quickly evolving model that is essential
              to the progression of modern businesses. The many capabilities of BI tools
              are responsible for driving the growth of the BI market, which is expected
              to be worth $33.3 billion by 2025. BI software will become more
              user-friendly as the industry grows. This growth will also drive a more
              knowledgeable user base. In a few years, this technology will evolve into
              AI-powered chatbots. Native language processing will provide direct answers
              to users' BI inquiries. This concept of machine learning is another major
              trend expected to take off in the business intelligence software industry in
              the future. The future of business is in the cloud, and this is also the way
              big data is now moving. Nearly all BI elements, including data sources and
              models, analytics, data storage, and computing power, have already moved to
              the cloud. Businesses need a connected cloud strategy to ensure reduced risk
              and flexibility.
            </div>
            <div className='mt-6 lg:text-xl'>
              Some of the current tools used by organizations are
            </div>
            <div className='mt-6 lg:text-xl'>
              <span className='font-bold underline mr-2'> Power BI:</span> With this tool,
              you should realize that after some data input it will show the key factor of
              the data. It is easy to use and very effective to present data in a
              graphical and analytic way.
            </div>
            <div className='mt-6 lg:text-xl'>
              <span className='font-bold underline mr-2'> Tableau:</span>Corporate
              analysis tool that is effective. Can visualize complex data to enable
              customers to make informed decisions. Simple to use, easy to set up.
            </div>
            <div className='mt-6 lg:text-xl'>
              <span className='font-bold underline mr-2'> Qlik Sense:</span> Qlik sense is
              a very powerful and easy-to-use tool, it requires some training but once you
              have gained the required skills it becomes an amazing app that can ingest
              data, manipulate, and present information from beginning to end.
            </div>
            <div className='mt-6 lg:text-xl'>
              <span className='font-bold underline mr-2'>Sisense Fusion Analytics:</span>{' '}
              Sisense is easier to learn even for newbies. It is easy to write SQL queries
              and create charts using this tool. The key features include easy-to-use
              functions, a simpler method to create dashboards, and quick access to key
              metrics. It also has monitoring and alerting in place.
            </div>
            <div className='mt-6 lg:text-xl'>
              <span className='font-bold underline mr-2'>Looker – (by Google):</span>{' '}
              Looker is a one-stop solution for analyzing and exploring real-time
              analytics with ease.
            </div>
            <div className='mt-6 lg:text-xl'>
              <span className='font-bold underline mr-2'>What’s in store for us!</span>
            </div>
            <div className='mt-6 lg:text-xl'>
              The existing business intelligence tools are very versatile and cater to
              organizations across various fields. With easy to access information on
              business performance and enabling organizations to make informed business
              decisions, it will be interesting to see what the future holds for us! There
              is still room for innovations in terms of data collected directly from the
              customers. With ever-evolving technologies and the prevalence of artificial
              intelligence, the future of business intelligence looks brighter than ever.
            </div>
          </div>
        </>
      ),
      author: 'Kishore Kumar Kotha',
      role: 'Senior Manager'
    }
    // {
    //   id: 5,
    //   imgSrc: '/assets/images/landing-page/blog-img2.jpg',
    //   date: '01, October 2021',
    //   title1: 'The Post',
    //   title2: 'Pandemic Leaders',
    //   description: (
    //     <>
    //       <h1>Blog-5</h1>
    //     </>
    //   ),
    //   author: 'Name',
    //   role: 'Role'
    // },
    // {
    //   id: 6,
    //   imgSrc: '/assets/images/landing-page/blog-img3.jpg',
    //   date: '01, October 2021',
    //   title1: 'Heading Starts Here',
    //   title2: 'Ends Here',
    //   description: (
    //     <>
    //       <h1>Blog-6</h1>
    //     </>
    //   ),
    //   author: 'Name',
    //   role: 'Role'
    // },
    // {
    //   id: 7,
    //   imgSrc: '/assets/images/landing-page/blog-img1.jpg',
    //   date: '01, October 2021',
    //   title1: 'Heading Starts Here',
    //   title2: 'Ends Here',
    //   description: (
    //     <>
    //       <h1>Blog-7</h1>
    //     </>
    //   ),
    //   author: 'Name',
    //   role: 'Role'
    // },
    // {
    //   id: 8,
    //   imgSrc: '/assets/images/landing-page/blog-img2.jpg',
    //   date: '01, October 2021',
    //   title1: 'Heading Starts Here',
    //   title2: 'Ends Here',
    //   description: (
    //     <>
    //       <h1>Blog-1</h1>
    //     </>
    //   ),
    //   author: 'Name',
    //   role: 'Role'
    // },
    // {
    //   id: 9,
    //   imgSrc: '/assets/images/landing-page/blog-img3.jpg',
    //   date: '01, October 2021',
    //   title1: 'Heading Starts Here',
    //   title2: 'Ends Here',
    //   description: (
    //     <>
    //       <h1>Blog-1</h1>
    //     </>
    //   ),
    //   author: 'Name',
    //   role: 'Role'
    // }
  ];
  const flag = !blogs[window.location.pathname.split('/').pop() - 1] ? false : true;

  return (
    <>
      {flag ? (
        <>
          <Expertise
            title1={blogs[id].title1}
            title2={blogs[id].title2}
            author={blogs[id].author}
            date={blogs[id].date}
            imgSrc={blogs[id].imgSrc}
          />
          <BlogDescription
            author={blogs[id].author}
            role={blogs[id].role}
            description={blogs[id].description}
          />
        </>
      ) : (
        <Redirect to='/404' />
      )}
    </>
  );
};

export default Blogs;
