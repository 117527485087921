import moment from 'moment';
import { useState, useEffect, useRef } from 'react';
import { jobsShowLimit } from '../../helpers/constants';

export const JobCard = ({ data, type, input }) => {
  const [filteredData, setfilteredData] = useState([]);
  const [openings, setOpenings] = useState(jobsShowLimit);
  const seeMoreBtn = useRef();
  const seeLessBtn = useRef();

  const getTimePeriod = (date) => {
    let duraionFromNow = moment(date, 'DDMMYYYY').fromNow();
    return `Posted ${duraionFromNow}`;
  };

  const handleSetOpenings = () => {
    let value = openings + jobsShowLimit;
    setOpenings(value);
  };

  const handleSetLess = () => {
    setOpenings(jobsShowLimit);
    seeMoreBtn.current.style.visibility = 'visible';
    seeLessBtn.current.style.visibility = 'hidden';
  };

  useEffect(() => {
    let filteredResults = data.totalData.filter(
      (item, index) =>
        (item.name.toLowerCase().includes(input.title) ||
          item.jobtype.toLowerCase().includes(input.title) ||
          item.location.toLowerCase().includes(input.title) ||
          input.title === '') &&
        (item.jobtype === input.jobType || input.jobType === '') &&
        (item.location === input.location || input.location === '')
    );
    setfilteredData(filteredResults);
    setOpenings(jobsShowLimit);
  }, [input, data]);

  return (
    <>
      {filteredData.map((item, index) =>
        index < openings ? (
          <a
            href={`/job-description/${type}/${item.s_no}`}
            className='text-dark-3 '
            key={index}
          >
            <div className=' w-11/12 mr-4 lg:w-[97%] flex justify-between border-b-[3px] h-28 float-right mb-6 ml-4 '>
              <div className='flex flex-col w-3/4 '>
                <div className='text-black font-bold text-lg lg:text-xl '>
                  {item.name}
                </div>
                <div className='flex w-auto  mt-2 font-bold text-slate-600'>
                  <div className='w-4 h-4 rounded-full '>
                    <img
                      src={
                        item.jobtype === 'Full Time'
                          ? '/assets/images/career/Full_Time.svg'
                          : item.jobtype === 'Part Time'
                          ? '/assets/images/career/Part_Time.svg'
                          : item.jobtype === 'Contract'
                          ? '/assets/images/career/Contract.svg'
                          : item.jobtype === 'Freelance'
                          ? '/assets/images/career/Freelance.svg'
                          : null
                      }
                      alt='logo'
                    />
                  </div>
                  <p className='text-slate-400 ml-2'>{item.jobtype}</p>
                </div>
                <div className='flex w-auto  mt-2 font-bold text-slate-600 '>
                  <div className='w-4 h-4 rounded-full '>
                    <img src='/assets/images/career/Location.svg' alt='logo' />
                  </div>
                  <p className='text-slate-400 ml-2'>{item.location}</p>
                </div>
              </div>
              <div className='h-full flex flex-col justify-evenly lg:w-1/4 w-40 '>
                <button className='self-end'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-8 w-8'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    strokeWidth={2}
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7' />
                  </svg>
                </button>
                <div className='self-end font-bold text-slate-400 w-full text-right'>
                  {getTimePeriod(item.post_date)}
                </div>
              </div>
            </div>
          </a>
        ) : null
      )}
      {filteredData.length === 0 ? (
        <div className='w-1/5 text-2xl text-center flex justify-between m-auto font-bold'>
          <span>No</span>
          <span>jobs</span>
          <span>available</span>
        </div>
      ) : null}
      {filteredData.length > jobsShowLimit && openings < filteredData.length ? (
        <button
          className='flex w-36 justify-between text-[23px] m-auto mt-6 font-bold'
          onClick={handleSetOpenings}
          ref={seeMoreBtn}
        >
          See More
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-8 w-8'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth={2}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M19 9l-7 7-7-7' />
          </svg>
        </button>
      ) : null}
      {filteredData.length > jobsShowLimit && openings >= filteredData.length ? (
        <button
          className='flex w-36 justify-between text-[23px] m-auto mt-6 font-bold '
          onClick={handleSetLess}
          ref={seeLessBtn}
        >
          See Less
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-8 w-8'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth={2}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M5 15l7-7 7 7' />
          </svg>
        </button>
      ) : null}
    </>
  );
};
