import React from 'react';

const Contacts = () => {
  const smallHeader = ` xl:text-lg 4k:text-xl `;

  const contacts = [
    {
      header: 'business',
      contactNo: '',
      // contactNo: '+11 30 722 45 99',
      email: 'meetus@solutionec.com',
      // email: 'business@solutionec.com | meetus@solutionec.com',
      icon: '/assets/images/contacts-page/Business_MouseOver_Color.png',
      iconClass: 'w-36 h-36 contact-card-icon'
    },
    {
      header: 'queries',
      contactNo: '',
      // contactNo: '+11 60 872 25 89',
      email: 'hello@solutionec.com',
      // email: 'query@solutionec.com | hello@solutionec.com',
      icon: '/assets/images/contacts-page/Queries_MouseOver_Color.png',
      iconClass: 'w-48 h-36 contact-card-icon'
    },
    {
      header: 'careers',
      contactNo: '',
      // contactNo: '+11 20 920 46 90',
      // email: 'hr.india@solutionec.com',
      email: 'jobs@solutionec.com',
      icon: '/assets/images/contacts-page/Support_MouseOver_Color.png',
      iconClass: 'w-44 h-36 contact-card-icon'
    }
  ];

  return contacts.map((contact, index) => {
    return (
      <div
        key={`${contact.header}${index}`}
        className='p-12 flex flex-col justify-end contact-card'
        style={{ height: '26rem', width: '18rem', background: '#d9d9d9b5' }}
      >
        <div>
          <img className={contact.iconClass} src={contact.icon} alt='' />
          <div className={smallHeader + 'uppercase lg:mt-8'}>{contact.header}</div>
          <div className='mt-6'>
            <div className='text-gray-400 font-light xl:text-xl 4k:text-2xl'>
              {contact.contactNo}
            </div>
            <div className='text-gray-400 font-light xl:text-xl 4k:text-2xl'>
              {contact.email.split('|').map((email, emailIndex) => {
                return (
                  <div key={`email#${emailIndex}`}>
                    <a href={`mailto:${email}`} className='myLink'>
                      {email}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='bg-header-primary h-px mt-6 contact-card-line'></div>
        </div>
      </div>
    );
  });
};

export default Contacts;
