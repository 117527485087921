import React from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';

const Section1 = () => {
  return (
    <div
      className='bg-cover bg-right-top h-vision-card xl:h-screen relative'
      style={{ backgroundColor: '#FAFAFA' }}
    >
      <div className='pl-6 xl:pl-48 pb-12 absolute bottom-0 w-full'>
        <div className={smallHeader + 'text-dark-3'}>Latest</div>
        <div className='grid xl:grid-cols-2 mt-2'>
          <div className={headingTextClass + 'mb-2 mt-4 white-space-nowrap-heading'}>
            <div>Data holds the story of humanity's past.</div>
            <div>We are using it to fuel the innovations of the future.</div>
          </div>
        </div>
        <div className='h-px w-11/12 bg-black my-6'></div>
        <div className={'text-dark my-2 text-base 4k:text-2xl'}>
          <div>
            The biggest requisite to success is the wholistic insights into your
            offerings,
          </div>
          <div>
            customers, and competitors. Data is constantly speaking, are you listening?
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
