import React from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';
import CardSlider from './CardSlider';
import SliderButtons from '../landing-page/SliderButtons';

const MoreDiversity = () => {
  const Card = ({ header, desc, fullImg, id }) => {
    return (
      <div className='min-w-[22.5rem] lg:h-[26rem] relative text-white'>
        <img
          className='bg-cover bg-no-repeat w-full h-full'
          src={fullImg}
          width='22.5rem'
          height='26rem'
          alt='block logo'
        />
        <div className='mt-2 font-bold absolute top-[2.3rem] left-[2.4rem] uppercase'>
          {header}
        </div>
        <div className='absolute bottom-[2.5rem] left-[2.4rem] flex items-center justify-between w-[calc(100%-3.7rem)]'>
          <div>
            {desc.map((d, i) => {
              return <div key={`desc#${i}`}>{d}</div>;
            })}
          </div>
        </div>
      </div>
    );
  };

  const slide1 = (
    <Card
      id='1'
      header={'innovation'}
      desc={['To grow is to innovate', 'Second line is required']}
      fullImg={'/assets/images/career/Diversity_Innovative.png'}
    />
  );

  const slide2 = (
    <Card
      id='2'
      header={'velocity'}
      desc={['Data is growing exponentially  ', 'and speed matters.']}
      fullImg={'/assets/images/career/Diversity_Velocity.png'}
    />
  );

  const slide3 = (
    <Card
      id='3'
      header={'empathy'}
      desc={['Content needed.', 'Content needed. ']}
      fullImg={'/assets/images/career/Diversity_Empathy.png'}
    />
  );

  const slide4 = (
    <Card
      id='4'
      header={'quality'}
      desc={['Content needed.', 'Content needed.']}
      fullImg={'/assets/images/career/Diversity_Quality.png'}
    />
  );

  const slides = [slide1, slide2, slide3, slide4];
  return (
    <div className='bg-black pb-24 '>
      <div className='xl:w-[80%] m-auto'>
        <img src='/assets/images/career/All_in_1_x300.jpg' alt='' />
      </div>
      <div className='grid xl:w-[80%] pl-6 lg:pl-32 xl:pl-48 4k:pl-72'>
        <div className='text-white '>
          <div className='flex items-center'>
            <div className={`${smallHeader} pt-50`} style={{ color: 'white' }}>
              Family
            </div>
          </div>
          <div
            className={headingTextClass + 'mt-5 xl:mt-7 4k:mt-12'}
            style={{ color: 'white' }}
          >
            <div>More Diversity, More Perspective</div>
          </div>
          <div className='mt-6 text-white text-base'>
            <div>Solutionec is for everyone</div>
            <div>
              We truly believe that innovation is an omnichannel business, it travels free
            </div>
            <div>Like inspiration, innovation too can come from anywhere</div>
          </div>
          <div className='columns-2 pt-10 pb-10'>
            <div>
              We celebrate randomness and diversity in every shape and form, hence we
              trust that the culture of diversity and inclusion is not only a must for us
              but also our lifeblood. We want our employees to grow and strive in an
              environment that is invisiting to the global talent brewing out there.
              <p className='pt-10'>
                Soultionec likes to faster both a top-down and grassroots approach that
                only gives a sense of freedom but also the space to address the broadcast
                set of initiatives.
              </p>
            </div>

            <div>
              As a rapidly growing organization, we are constantly looking to expand our
              talent base with people from different ethnicities, cultures, and countries,
              who can not only bring in some color to our culture but also create an
              environment that is accepting and loving beyond boundaries, process.
            </div>
          </div>
        </div>
        <div className='hidden lg:block lg:pr-32 4k:pr-52  relative xxl:hidden'>
          <div className='absolute right-0  '>
            <SliderButtons
              mainContainerClass=' flex items-end gap-5 4k:gap-7'
              buttonClass=' outline-none uppercase text-white border-2 border-white bg-transparent rounded-full hover:bg-white hover:text-black p-3 '
              svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
              svgContainerClass=' grid place-items-center '
              targetId='digital-card'
            />
          </div>
        </div>
      </div>
      {/* pl-6 lg:pl-32 xl:pl-48 4k:pl-72 */}
      <div className='mt-20 pl-6 lg:pl-32 xl:pl-48 4k:pl-72 '>
        <CardSlider targetId={'digital-card'}>{slides}</CardSlider>
      </div>
    </div>
  );
};

export default MoreDiversity;
