import React, { useState } from 'react';
import { smallHeader } from '../../../helpers/constants';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const handleSubscribe = () => {
    console.log(email, 'email');
  };
  return (
    <div>
      <div className='ml-12 my-6 lg:w-1/2 lg:ml-[26rem] lg:my-52 '>
        <div className={smallHeader + 'text-dark-3 '}>newsletter </div>
        <div className='mt-4 lg:mt-8 '>
          <div
            className={
              ' text-black w-full  text-2xl lg:text-[2.4rem] lg:leading-[3.2rem] 4k:text-7xl font-light '
            }
          >
            <div>Please subscribe to our </div>
            <div>Newsletter for more updates</div>
          </div>
        </div>
        <div className='mt-4 lg:mt-8'>
          <input
            type='email'
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder='Enter your email address'
            className='border-b-2 border-[#c4c4c4] lg:w-[430px] outline-none text-xl text-black h-10'
          />
        </div>
        <div className='mt-8 font-bold text-black' onClick={handleSubscribe}>
          <button
            className={`uppercase border-[2.8px] rounded-full py-2 px-6 lg:py-4 lg:px-6 4k:px-12 4k:py-5 text-sm lg:text-small 4k:text-2xl lg:leading-4 lg:tracking-[0.050rem] w-max  border-black hover:bg-black hover:text-white `}
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
