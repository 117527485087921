import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';

const Section1 = ({ subTitle, title, message1, message2 }) => {
  return (
    <div className=' h-screen relative '>
      <div className='pl-6 xl:pl-48 pb-12 absolute bottom-0 w-full'>
        <div className={smallHeader}>{subTitle}</div>
        <div className='mt-8'>
          <div className={headingTextClass}>
            <div>{title}</div>
          </div>
        </div>
        <div className='h-px w-11/12 bg-black my-6'></div>
        <div className={' my-2 text-xl 4k:text-2xl text-black '}>
          <div>{message1}</div>
          <div>{message2}</div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
