export const env = process.env.REACT_APP_ENV;
export const port = process.env.REACT_APP_PORT;

const envConfig = {
  default: {},
  development: {
    api: `http://localhost:${port}`,
    home: `http://localhost:${port}`
  },
  testing: {
    api: `http://SPL-SER-AS-001:${port}`,
    home: `http://SPL-SER-AS-001:${port}`
  },
  production: {
    api: '',
    home: ''
  }
};

console.log(envConfig[env]);

export const appConfig = envConfig[env];
