import React from 'react';

const CenterRipple = () => {
  const DELAY = 2;

  return (
    <>
      <div className='ripple-container flex relative justify-center items-center w-3/5 h-44 top-28 4k:top-44 left-1/2'>
        <span className='small-ripple' style={{ '--i': DELAY + 2 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 4 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 6 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 8 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 10 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 12 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 14 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 16 }}></span>
        <div
          className='vision-card-line absolute transform rotate-90 w-48 4k:w-80'
          style={{ top: '5.6rem' }}
        ></div>
        <div
          className='vision-card-line absolute w-48 4k:w-80'
          style={{ top: '5.5rem' }}
        ></div>
      </div>
    </>
  );
};

export default CenterRipple;
