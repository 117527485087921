import React from 'react';
import ArtnScience from '../components/landing-page/sections/ArtnScience';
// import AtSolutionec from '../components/landing-page/sections/AtSolutionec';
import BlogSection from '../components/landing-page/sections/BlogSection';
import Genesis from '../components/landing-page/sections/Genesis';
import ListenTo from '../components/landing-page/sections/ListenTo';
import MoveForward from '../components/landing-page/sections/MoveForward';
import Unbox from '../components/landing-page/sections/Unbox';
import Testimonial from '../components/Testimonial';

const MainLandingPage = () => {
  return (
    <>
      <MoveForward />
      <ArtnScience />
      {/* <AtSolutionec /> */}
      <Unbox />
      <Genesis />
      <ListenTo />
      <Testimonial />
      <BlogSection />
    </>
  );
};

export default MainLandingPage;
