import React, { useState } from 'react';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';

const BlogDescription = ({ author, role, description }) => {
  const [copied, setCopied] = useState(false);
  const copy = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };
  return (
    <div className='text-black mb-10 lg:mb-36 '>
      <div className='w-11/12 m-auto mt-6 flex lg:w-3/4 items-center lg:ml-48 lg:mt-8'>
        <div className='w-16 h-16 lg:w-20 lg:h-20 rounded-full bg-[#eee] '></div>
        <div className='flex flex-col ml-8 '>
          <p className='font-bold text-[18px] lg:text-2xl'>{author}</p>
          <p className='m-0 mt-[-4px] lg:text-base text-slate-400'>{role}</p>
        </div>
      </div>
      {description}
      <div className='mt-10 w-[90%]  m-auto flex lg:w-2/5 h-10 justify-between lg:ml-48  '>
        <div className='lg:w-1/4 '>
          <FacebookShareButton
            url={`https://solutionec-web.netlify.app${window.location.pathname}`}
          >
            <div className=' w-full  h-full flex content-center items-center font-bold '>
              <img
                src='/assets/images/social-icons/Facebook_Icon.svg'
                className='w-6 h-6 lg:w-8 lg:h-8'
                alt='facebook'
              />
              <p className='pl-2 lg:pl-2 text-small lg:text-base'>Share</p>
            </div>
          </FacebookShareButton>
        </div>
        <div className='lg:w-1/4 '>
          <LinkedinShareButton
            title='Solutionec Carrers'
            url={`https://solutionec-web.netlify.app${window.location.pathname}`}
            hashtag={'#solutionec'}
          >
            <div className='flex content-center h-full items-center justify-between font-bold'>
              <img
                src='/assets/images/social-icons/Linkedln_Icon.svg'
                className='w-6 h-6 lg:w-8 lg:h-8'
                alt='linkedin'
              />
              <p className='pl-2 lg:pl-4 text-small lg:text-base'>LinkedIn</p>
            </div>
          </LinkedinShareButton>
        </div>
        <div className='lg:w-1/4 ml-2'>
          <TwitterShareButton
            url={`https://solutionec-web.netlify.app${window.location.pathname}`}
          >
            <div className='flex content-center h-full items-center justify-between font-bold'>
              <img
                src='/assets/images/social-icons/Twitter_Icon.svg'
                className='w-6 h-6 lg:w-8 lg:h-8'
                alt='twitter'
              />
              <p className='pl-2 lg:pl-4 text-small lg:text-base'>Twitter</p>
            </div>
          </TwitterShareButton>
        </div>

        <div className='lg:w-1/4 '>
          <button onClick={copy} className='text-black font-bold'>
            <div className='flex content-center h-full items-center justify-between'>
              <img
                src='/assets/images/social-icons/Copy.svg'
                className='w-6 h-6 lg:w-8 lg:h-8'
                alt='copy'
              />
              <p className='pl-2 lg:pl-4 text-small lg:text-base'>
                {!copied ? 'Copy' : 'Copied!'}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogDescription;
