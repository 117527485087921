import React from 'react';

const LeftRipple = () => {
  const DELAY = 2;

  return (
    <>
      <div className='ripple-container flex relative justify-center w-3/5 h-44 top-4 4k:top-24 transform rotate-90 -left-4 4k:left-4 m-auto'>
        <span className='small-ripple' style={{ '--i': DELAY + 2 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 4 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 6 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 8 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 10 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 12 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 14 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 16 }}></span>
        <div className='vision-card-line absolute w-48 4k:top-0 4k:w-72'></div>
      </div>
    </>
  );
};

export default LeftRipple;
