import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';

const Section1 = () => {
  return (
    <div
      className='bg-cover h-screen relative sol-dark-scroll bg-center'
      style={{ backgroundImage: 'url("/assets/images/contacts-page/galaxy-blue.jpg")' }}
    >
      <div className='pl-6 xl:pl-48 pb-12 absolute bottom-0 w-full'>
        {/* <div className='h-screen mt-4 bg-cover relative' style={{ backgroundImage: 'url("/assets/images/contacts-page/galaxy-blue.jpg")' }}>
        <div className='absolute bottom-0 left-0 py-16 px-20'> */}
        <div className={smallHeader + 'text-light'}>contact us</div>
        <div className='mt-8'>
          <div className={headingTextClass} style={{ color: 'white' }}>
            <div>We Connect</div>
            <div>To our Clients Globally</div>
            <div>To Initiate Round-The-Clock Services</div>
          </div>
        </div>
        <div className='h-px w-11/12 bg-gray-100 my-6'></div>
        <div className={'text-light my-2 text-base 4k:text-2xl'}>
          <div>
            Want to know how Solutionec delivers on the promise of quality and innovation?
          </div>
          <div>Meet us and let us walk you through the journey.</div>
          {/* <div>Ut wisi enim ad minim veniam, quis nos</div> */}
        </div>
      </div>
    </div>
  );
};

export default Section1;
