import React from 'react';
import { smallHeader } from '../../../../helpers/constants';
import ImageSlider from '../../../landing-page/ImageSlider';

const Feedback = ({ cardColor = 'text-[rgba(0,27,226,1)]' }) => {
  const transformedHeaderClass =
    cardColor !== 'text-[rgba(0,27,226,1)]'
      ? `${smallHeader}`
      : `${smallHeader} text-[#fff]`;

  const hex = cardColor.split('-').reverse()[0];
  const cardColorInHex = hex.substring(1, hex.length - 1);

  const desc = {
    1: [
      'The Global Data Management team',
      'is a valuable business patner, enabling',
      'us to leverage third party data to provide',
      'performance visibility to the GBU',
      'through tools such as Flashcard.'
    ],
    2: [
      'Flashcard is a large scale project',
      'with a great job behind it that helps us',
      'in the subsidaries to have information',
      'about other markets and countries',
      'in a fast and agile way.'
    ]
  };
  const location = {
    1: ['Head of GenMed Business Insights', '& Customer Field Transformation'],
    2: ['Market Research &', 'Customer Insights | Iberia']
  };

  const Card = ({ header, desc, client, location }) => {
    return (
      <div
        className='p-6 w-[20rem] h-[26rem] lg:max-w-[20rem] lg:h-[28rem] flex flex-col justify-between'
        style={{
          background: `linear-gradient(0deg, ${cardColorInHex} 0%, rgba(0,0,0,1) 64%, rgba(0,0,0,1) 100%)`
        }}
      >
        <div className='mt-2 font-bold'>{header}</div>
        <div>
          <div className='font-extralight'>
            {desc.map((d, i) => {
              return <div key={`desc#${i}`}>{d}</div>;
            })}
          </div>
          <div className='text-base font-bold mt-4'>{client}</div>
          <div className='text-xs'>
            {location.map((l, i) => {
              return <div key={`location#${i}`}>{l}</div>;
            })}
          </div>
        </div>
      </div>
    );
  };

  const Slide1 = (
    <div className='grid place-items-center'>
      <Card
        header={'Flashcard'}
        desc={desc['1']}
        client={'Carlos Soria'}
        location={location['1']}
      />
    </div>
  );
  const Slide2 = (
    <div className='grid place-items-center'>
      <Card
        header={'Flashcard'}
        desc={desc['2']}
        client={'M Jose Duran'}
        location={location['2']}
      />
    </div>
  );
  const slides = [Slide1, Slide2];

  return (
    <div className='bg-black text-white pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-20 sol-dark-scroll'>
      <div className={`pt-24 ${cardColor} ${transformedHeaderClass}`}>
        Customer Testimonials
      </div>
      {/* <div className={headingTextClass + 'mt-8'}>
        <div>Customer Testimonials: </div>
      </div>
      <div className='mt-8 text-sm'>
        <div>Hear about our work from those who came before you.</div>
      </div> */}
      <div className='flex items-center gap-x-8 mt-8'>
        <div className='lg:hidden w-[90vw]'>
          <ImageSlider slides={slides} autoPlay={false} />
        </div>
        <div className='hidden lg:flex gap-10'>
          {slides.map((slide, index) => (
            <div key={`slide#${index}`}>{slide}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
