import React from 'react';
import { headingTextClass, routes, smallHeader } from '../../helpers/constants';

const JobOpening = () => {
  return (
    <>
      <div
        style={{
          background: 'black',
          color: 'white'
        }}
      >
        <div className='pl-6 lg:pl-32 xl:pl-96 pb-10  '>
          <div className={smallHeader + 'uppercase pt-40'} style={{ color: 'white' }}>
            Explore
          </div>
          <div className={headingTextClass + 'mt-7 '} style={{ color: 'white' }}>
            <div className='lg:block pt-5'>
              <div>Job Openings For </div>
              <div>Freshers & Industry Expert</div>
            </div>
          </div>
          <div className='mt-8 text-base lg:text-xl'>
            <div className='lg:block'>
              <div>We truly believe that innovation is a channel</div>
              <div>Like inspiration, innovation too can come from anywhere.</div>
            </div>
            <div className='flex flex-wrap py-2 mb-8'>
              <div className='w-full px-0'>
                <nav className='relative flex flex-wrap items-center justify-between py-3 rounded'>
                  <div className='container  flex flex-wrap items-center justify-between'>
                    <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
                      <a
                        className='text-3xl leading-relaxed inline-block mr-4 py-2 whitespace-nowrap text-white hover:no-underline'
                        href={`${routes.joblist}/freshers`}
                      >
                        <div className='flex items-center gap-1  link-underline    '>
                          Freshers
                          <img
                            src='/assets/images/external_link_icon.svg'
                            className='w-4 h-4'
                            alt=''
                          />
                        </div>
                      </a>
                      <a
                        className='text-3xl leading-relaxed inline-block mr-4 py-2 whitespace-nowrap text-white hover:no-underline'
                        href={`${routes.joblist}/experts`}
                      >
                        <div className='flex items-center gap-1    link-underline'>
                          Experts
                          <img
                            src='/assets/images/external_link_icon.svg'
                            className='w-4 h-4'
                            alt=''
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobOpening;
