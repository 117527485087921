import React from 'react';
import { headingTextClass, smallHeader, subHeader } from '../../../helpers/constants';
import CardSlider from '../CardSlider';
import SliderButtons from '../SliderButtons';

const BlogSection = () => {
  return (
    <div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 mt-32 4k:mt-48 pb-32'>
      <div className='xxl:pl-40 4k:pl-0'>
        <div className={smallHeader}>blog section</div>
        <div className={headingTextClass + 'mt-7'}>
          <div className='hidden lg:block'>
            <div>Pinch of Expertise :</div>
            <div>Hear our Visionaries Talk</div>
          </div>
          <div className='block lg:hidden'>
            <div>Pinch of Expertise :</div>
            <div>Hear our Visionaries Talk</div>
          </div>
        </div>
        {/* gap-y-4 xl:gap-48 */}
        <div className='grid lg:flex lg:justify-between lg:items-end'>
          <div className={subHeader + 'mt-8 xl:mt-4 xxl:text-xl'}>
            <div className='hidden lg:block'>
              <div>Engage with the thought-provoking and industry-relevant pieces</div>
              <div>by our leaders shaping the minds of many.</div>
            </div>
            <div className='block lg:hidden'>
              <div>Engage with the thought-provoking and industry-relevant pieces</div>
              <div>by our leaders shaping the minds of many.</div>
            </div>
          </div>
          <div className='hidden lg:block lg:pr-32 xl:pr-[15.313rem] 4k:pr-52'>
            <SliderButtons
              mainContainerClass=' flex items-end gap-5 4k:gap-7 pb-1'
              buttonClass=' outline-none uppercase text-black border-2 border-black bg-light rounded-full hover:bg-black hover:text-light p-3 '
              svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
              svgContainerClass=' grid place-items-center '
              targetId='blogs-card'
            />
          </div>
        </div>
      </div>
      <CardSlider source='blogs' />
    </div>
  );
};

export default BlogSection;
