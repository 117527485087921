import React, { useEffect, useState } from 'react';
import '../styles/sidebar-menu.css';
import { isInViewportAmongMany, getAbsoluteHrefPath } from '../helpers/util';
import SocialIcons from './SocialIcons';
import NewsIcon from './NewsIcon';
import { routes } from '../helpers/constants';

const MenuBar = ({ sidebarTheme, setSidebarTheme, setHideTracker }) => {
  const setTheme = (theme) => {
    if (typeof setSidebarTheme === 'function') {
      setSidebarTheme(
        theme || {
          menuIcon: 'light',
          newsIcon: 'light'
        }
      );
    }
  };

  /**
   * sets hideTracker value to the passed value
   * hideTracker is a useState variable in Sidebar.jsx component
   * which determines whether to hide the page tracker when the menu is open
   * @param {boolean} value
   */
  const hideTracker = (value) => {
    if (typeof setHideTracker === 'function' && typeof value === 'boolean') {
      setHideTracker(value);
    }
  };

  const solutionecDropDiplayOptions = { hide: 'h-0 hidden', unhide: 'h-unset block' };

  const [dropClass, setDropClass] = useState('h-0 hidden');
  const [careerDropClass, setCareerDropClass] = useState('h-0 hidden');

  const [isMenuOpen, setIsMenuOpen] = useState(null);

  const [darkElements, setDarkElements] = useState(
    document.querySelectorAll('.sol-dark-scroll')
  );
  const darkElement = isInViewportAmongMany(darkElements || []);

  const [darkElementCoordinates, setDarkElementCoordinates] = useState(
    darkElement ? darkElement.getBoundingClientRect() : {}
  );

  // 53 is the height from the edge of the screen till the end of the icon
  const menuIconTheme = () =>
    darkElementCoordinates.y <= 53 * 2 &&
    darkElementCoordinates.y >= Number(`-${darkElementCoordinates.height}`) - -53
      ? 'dark'
      : 'light';

  const newsIconTheme = () =>{

    return (
      (darkElementCoordinates.height > window.screen.height - 90
        ? true
        : darkElementCoordinates.y >= 53*2 || darkElementCoordinates.y == 0 ) &&   // For insights page and contact us page
      darkElementCoordinates.y <= darkElementCoordinates.height + (53*2)
        ? 'dark'
        : 'light'
    )
  }
   

  const artworkMapping = {
    [getAbsoluteHrefPath(routes.home)]: '/assets/images/sidebar/Menu_Artwork_Home.png',
    [getAbsoluteHrefPath(routes.aboutUs)]:
      '/assets/images/sidebar/Menu_Artwork_About Us.png',
    [getAbsoluteHrefPath(routes.joinUs)]:
      '/assets/images/sidebar/Menu_Artwork_Career.png',
    [getAbsoluteHrefPath(routes.contactUs)]:
      '/assets/images/sidebar/Menu_Artwork_Contact Us.png',
    [getAbsoluteHrefPath(routes.insights)]:
      '/assets/images/sidebar/Menu_Artwork_Insights.png',
    solutionec: '/assets/images/sidebar/Menu_Artwork_Solution Excellence Center.png',
    [getAbsoluteHrefPath(routes.products)]:
      '/assets/images/sidebar/Menu_Artwork_Solution Excellence Center.png',
    [getAbsoluteHrefPath(routes.services)]:
      '/assets/images/sidebar/Menu_Artwork_Solution Excellence Center.png'
  };
  const artworkMetadataMapping = {
    [getAbsoluteHrefPath(routes.home)]:
      'w-[25rem] h-[31rem] right-[13rem] bottom-[4.4rem]',
    [getAbsoluteHrefPath(routes.aboutUs)]:
      'w-[25rem] h-[32rem] right-[12rem] bottom-[4.4rem]',
    [getAbsoluteHrefPath(routes.joinUs)]:
      'w-[16rem] h-[42rem] right-[18rem] bottom-[3.4rem]',
    [getAbsoluteHrefPath(routes.contactUs)]:
      'w-[23rem] h-[23rem] right-[11rem] bottom-[4.4rem]',
    [getAbsoluteHrefPath(routes.insights)]:
      'w-[34rem] h-[17rem] right-[9rem] bottom-[4.4rem]',
    solutionec: 'w-[23rem] h-[23rem] right-[10rem] bottom-[4.2rem]',
    [getAbsoluteHrefPath(routes.products)]:
      'w-[23rem] h-[23rem] right-[10rem] bottom-[4.2rem]',
    [getAbsoluteHrefPath(routes.services)]:
      'w-[23rem] h-[23rem] right-[10rem] bottom-[4.2rem]'
  };
  const [artworkSrc, setArtworkSrc] = useState(
    artworkMapping[getAbsoluteHrefPath(routes.home)]
  );
  const [artworkMetadata, setArtworkMetadata] = useState(
    artworkMetadataMapping[getAbsoluteHrefPath(routes.home)]
  );

  const resetArtwork = () => {
    const path = getAbsoluteHrefPath(window.location.pathname);
    setArtworkSrc(artworkMapping[path]);
    setArtworkMetadata(artworkMetadataMapping[path]);
  };

  const SecDropdown = () => {
    return (
      <div>
        {/* eslint-disable-next-line */}
        <a
          href='#'
          onMouseOver={() => {
            setArtworkSrc(artworkMapping['solutionec']);
            setArtworkMetadata(artworkMetadataMapping['solutionec']);
          }}
          onClick={() => {
            setDropClass(
              dropClass === solutionecDropDiplayOptions.hide
                ? solutionecDropDiplayOptions.unhide
                : null
            );
            setCareerDropClass(solutionecDropDiplayOptions.hide);
          }}
          // It is taking time while navigating to the page.
          // onClick={() => {
          //   setDropClass(
          //     dropClass === solutionecDropDiplayOptions.hide
          //       ? solutionecDropDiplayOptions.unhide
          //       : solutionecDropDiplayOptions.hide
          //   );
          // }}
        >
          <li
            className={`${
              ['/products', '/services'].includes(window.location.pathname)
                ? 'border-l-2'
                : ''
            } menubar-sol border-0 hover:border-l-2`}
          >
            <div className={`transition-all ${dropClass}`}>
              <a href='/products'>
                <li>Products</li>
              </a>
              <a href='/services'>
                <li>Services</li>
              </a>
            </div>
          </li>
        </a>
      </div>
    );
  };

  const CareerDropdown = () => {
    return (
      <div>
        {/* eslint-disable-next-line */}
        <a
          href='#'
          onMouseOver={() => {
            setArtworkSrc(artworkMapping['solutionec']);
            setArtworkMetadata(artworkMetadataMapping['solutionec']);
          }}
          onClick={() => {
            setDropClass(solutionecDropDiplayOptions.hide);
            setCareerDropClass(
              careerDropClass === solutionecDropDiplayOptions.hide
                ? solutionecDropDiplayOptions.unhide
                : null
            );
          }}
          // It is taking time while navigating to the page.
          // onClick={() => {
          //   setDropClass(
          //     dropClass === solutionecDropDiplayOptions.hide
          //       ? solutionecDropDiplayOptions.unhide
          //       : solutionecDropDiplayOptions.hide
          //   );
          // }}
        >
          <li
            className={`${
              ['/join-us', '/life'].includes(window.location.pathname) ? 'border-l-2' : ''
            } menubar-careers border-0 hover:border-l-2`}
          >
            <div className={`transition-all ${careerDropClass}`}>
              <a href='/join-us'>
                <li>Careers</li>
              </a>
              <a href='/life'>
                <li>Life@Solutionec</li>
              </a>
            </div>
          </li>
        </a>
      </div>
    );
  };

  useEffect(() => {
    setDarkElements(document.querySelectorAll('.sol-dark-scroll'));

    const handleDarkMenuTheme = () => {
      const darkElement = isInViewportAmongMany(
        document.querySelectorAll('.sol-dark-scroll')
      );
      setDarkElementCoordinates(darkElement ? darkElement.getBoundingClientRect() : {});
    };
    handleDarkMenuTheme();
    document.addEventListener('scroll', handleDarkMenuTheme);

    return () => {
      document.removeEventListener('scroll', handleDarkMenuTheme);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTheme(
      Object.assign({}, sidebarTheme || {}, {
        menuIcon: menuIconTheme(),
        newsIcon: newsIconTheme()
      })
    );
    // eslint-disable-next-line
  }, [darkElementCoordinates]);

  useEffect(() => {
    resetArtwork();

    // open solutionec dropdown if the children are selected
    if (['/products', '/services'].includes(window.location.pathname)) {
      setDropClass(
        dropClass === solutionecDropDiplayOptions.hide
          ? solutionecDropDiplayOptions.unhide
          : solutionecDropDiplayOptions.hide
      );
    }

    // open career dropdown if the children are selected
    if (['/join-us', '/life'].includes(window.location.pathname)) {
      setCareerDropClass(
        careerDropClass === solutionecDropDiplayOptions.hide
          ? solutionecDropDiplayOptions.unhide
          : solutionecDropDiplayOptions.hide
      );
    }

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setIsMenuOpen(false);
        hideTracker(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div id='menuToggle'>
      <input
        type='checkbox'
        className='transform rotate-90 relative'
        checked={isMenuOpen}
        onChange={(e) => {
          setIsMenuOpen(e.target.checked);
          hideTracker(true);
        }}
      />
      <div className='transform rotate-90 relative top-3 4k:left-0'>
        <span
          className={`menu-line ${
            (sidebarTheme ? sidebarTheme.menuIcon : 'light') === 'dark'
              ? 'bg-white'
              : 'bg-black'
          }`}
        ></span>
        <span
          className={`menu-line ${
            (sidebarTheme ? sidebarTheme.menuIcon : 'light') === 'dark'
              ? 'bg-white'
              : 'bg-black'
          }`}
        ></span>
      </div>
      <ul
        id='menu'
        className='grid lg:flex'
        style={{ height: `${window.innerHeight + 34}px` }}
        onMouseOut={() => {
          resetArtwork();
        }}
      >
        <div className='hidden lg:flex w-28 xl:w-32 lg:border-r lg:border-gray-500 flex-col justify-between items-center pb-24 xl:pb-16'>
          <div></div>
          <div className='lg:relative lg:left-4 xl:left-0'>
            <NewsIcon theme='dark' />
          </div>
        </div>
        <div className='mt-32 pl-2 lg:pl-0 lg:mt-12 font-extralight '>
          <a
            href={routes.home}
            onMouseOver={() => {
              setArtworkSrc(artworkMapping[getAbsoluteHrefPath(routes.home)]);
              setArtworkMetadata(
                artworkMetadataMapping[getAbsoluteHrefPath(routes.home)]
              );
            }}
          >
            <li
              className={`${
                window.location.pathname === routes.home ? 'border-l-2' : ''
              } border-0 hover:border-l-2`}
            >
              Home
            </li>
          </a>
          <a
            href={routes.aboutUs}
            onMouseOver={() => {
              setArtworkSrc(artworkMapping[getAbsoluteHrefPath(routes.aboutUs)]);
              setArtworkMetadata(
                artworkMetadataMapping[getAbsoluteHrefPath(routes.aboutUs)]
              );
            }}
          >
            <li
              className={`${
                window.location.pathname === routes.aboutUs ? 'border-l-2' : ''
              } border-0 hover:border-l-2`}
            >
              About Us
            </li>
          </a>
          <SecDropdown />
          <CareerDropdown />
          <a
            href={routes.insights}
            onMouseOver={() => {
              setArtworkSrc(artworkMapping[getAbsoluteHrefPath(routes.insights)]);
              setArtworkMetadata(
                artworkMetadataMapping[getAbsoluteHrefPath(routes.insights)]
              );
            }}
          >
            <li
              className={`${
                window.location.pathname === routes.insights ? 'border-l-2' : ''
              } border-0 hover:border-l-2`}
            >
              Insights
            </li>
          </a>
          {/* <a
            href={routes.joinUs}
            onMouseOver={() => {
              setArtworkSrc(artworkMapping[getAbsoluteHrefPath(routes.joinUs)]);
              setArtworkMetadata(
                artworkMetadataMapping[getAbsoluteHrefPath(routes.joinUs)]
              );
            }}
          >
            <li
              className={`${
                window.location.pathname === routes.joinUs ? 'border-l-2' : ''
              } border-0 hover:border-l-2`}
            >
              Careers
            </li>
          </a> */}
          <a
            href={routes.contactUs}
            onMouseOver={() => {
              setArtworkSrc(artworkMapping[[getAbsoluteHrefPath(routes.contactUs)]]);
              setArtworkMetadata(
                artworkMetadataMapping[[getAbsoluteHrefPath(routes.contactUs)]]
              );
            }}
          >
            <li
              className={`${
                window.location.pathname === routes.contactUs ? 'border-l-2' : ''
              } border-0 hover:border-l-2`}
            >
              Contact Us
            </li>
          </a>
          <div className='hidden lg:block ml-8 mt-12'>
            <SocialIcons size='md' theme='dark' />
          </div>
        </div>
        <div className='hidden xl:block relative w-[23%]'>
          <div
            className={`bg-cover bg-no-repeat absolute ${artworkMetadata}`}
            style={{ backgroundImage: `url("${artworkSrc}")` }}
          ></div>
        </div>
        <div className='block lg:hidden pl-10 text-2xl'>
          <span className='mx-2 font-semibold text-white'>EN</span>
          <span className='mx-2 text-gray-100'>FR</span>
        </div>
        <div className='block lg:hidden pl-8'>
          <NewsIcon theme='dark' />
        </div>
      </ul>
    </div>
  );
};

export default MenuBar;
