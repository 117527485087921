import React from 'react';
import { defaultButtonClasses, smallHeader } from '../../helpers/constants';

const Solutionecexplore = () => {
  return (
    <>
      <div className='grid grid-cols-2 gap-0 pl-6 lg:pl-32 xl:pl-38 4k:pl-72  height-image-section'>
        <div className='first-card'>
          <div className='first-card-inner'>
            <div className='first-card-position'>
              <div className={smallHeader + ' text-left  text-dark-3'}>
                The Monthly Dose
              </div>
              <div className={'text-base lg:text-xl mt-8'}>
                <div>Our Monthly Employee Newsletter at a Glance.</div>
                <div className='mt-10 lg:mt-8 xl:mt-12'>
                  <button className={'text-black border-dark-3' + defaultButtonClasses}>
                    Explore
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='second-card'>
          <div className='second-card-inner'>
            <div className='second-card-front'>
              <img
                src='/assets/images/insight-page/solutionec-img-seconn2.png'
                className='img-solution'
                alt='solutionec img'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solutionecexplore;
