import React from 'react';
import { smallHeader } from '../../../helpers/constants';

const OurImpact = () => {
  return (
    <div
      className='bg-cover relative lg:px-32 py-20 text-white'
      style={{ backgroundImage: 'url("/assets/images/about-us/Our_Impact_OPT.jpg")' }}
    >
      <div className='w-full pl-6 lg:pl-32 xl:pl-32 py-4 pb-[0.6rem] sol-impact-bg'>
        <div className={smallHeader + 'text-light mt-2 lg:mt-[11rem]'}>our impact</div>
        <div className={'mt-10 text-[2.5rem] leading-[2.813rem] font-light'}>
          <div className='lg:hidden'>
            <div>Solutionec was</div>
            <div>founded on the shared</div>
            <div>principle of growing</div>
            <div>our business to better</div>
            <div>the lives of people &</div>
            <div>benefit society in a</div>
            <div>responsible and</div>
            <div>Sustainable way.</div>
          </div>
          <div className='hidden lg:block'>
            <div>Solutionec was founded on the </div>
            <div>shared principle of growing our business</div>
            <div>to better the lives of people & benefit society</div>
            <div>in a responsible and sustainable way.</div>
          </div>
        </div>
        <div className='grid lg:flex gap-8 xl:gap-16 xxl:gap-24 mt-6 lg:mt-[1.875rem] lg:text-[0.938rem] tracking-[0.015rem] font-light xxl:text-base xxl:pr-28'>
          <div>
            <div className='lg:hidden'>
              <div>As a group of data enthusiasts, we deploy a</div>
              <div>unique blend of data savy, industry expertise</div>
              <div>and technological excellence to allow global</div>
              <div>healthcare companies achieve their digital</div>
              <div>transformation goals by improving their core</div>
              <div>operations and launching better business</div>
              <div>models that are more agile, resilient, and</div>
              <div>transformative for the common good.</div>
            </div>
            <div className='hidden lg:block'>
              <div>As a group of data enthusiasts, we deploy a unique blend</div>
              <div>of data savvy, industry expertise and technological</div>
              <div>excellence to allow global healthcare companies achieve</div>
              <div>their digital transformations goals by improving their core</div>
              <div>operations and launching better business models that</div>
              <div>are more agile, resilient, and transformative for the</div>
              <div>common good.</div>
            </div>
          </div>
          <div>
            <div className='lg:hidden'>
              <div>Our team of data experts have in-depth</div>
              <div>experience helping organisations dive</div>
              <div>fearlessly into the vastness of data and</div>
              <div>come up with ground-breaking insights</div>
            </div>
            <div className='hidden lg:block'>
              <div>Our team of data experts have in-depth experience</div>
              <div>helping origanizations dive fearlessly into the vastness</div>
              <div>of data and come up with ground-breaking insights</div>
            </div>
          </div>
        </div>
        <div className='w-11/12 mt-[1.875rem] border-t-[1px] border-white h-px'></div>
        <div className='text-xl lg:text-2xl mt-[1.25rem] relative bottom-4 font-light'>
          solution excellence center
        </div>
      </div>
    </div>
  );
};

export default OurImpact;
