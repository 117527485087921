import React, { useState, useEffect } from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';
import axios from 'axios';

const PickStories = () => {
  const [data, setData] = useState({
    list: [],
    iteration: 3
  });

  useEffect(() => {
    axios.get('/pick_stories.json').then((res) => {
      setData({ ...data, list: res.data });
    });
  }, [data]);
  return (
    <div className='  bg-[#eee] text-black  pt-48 pb-48'>
      <div className=' ml-[26rem] w-1/2 '>
        <div className={smallHeader + 'text-dark-3'}>Pick Stories</div>
        <div className='w-full mt-4'>
          <div className={headingTextClass + 'mb-2 mt-7 '}>
            <div>CHC - LATAM </div>
            <div> PERFORMANCE DASHBOARD</div>
          </div>
        </div>

        <div className={'font-normal w-11/12 my-6 text-xl 4k:text-2xl'}>
          <div>
            Building an interactive PowerBI dashboard as a single point of truth and
            derive executive insights with a single user click. An automated dashboard
            solution at a click away to assess the current capabilities relative to the
            future aspirations
          </div>
        </div>
      </div>
      <div className='customer-slider mt-10  h-[480px]'>
        {Array(data.iteration)
          .fill(0)
          .map((item, index) => (
            <div className='move' key={index}>
              {data.list.map((item, idx) => (
                <div
                  className='customer-slider-inner h-full w-[380px]'
                  key={`${index}${idx}`}
                >
                  <div className='h-3/4 relative'>
                    <p className='absolute bottom-4 left-6 font-bold'>{item.date}</p>
                  </div>
                  <div className='h-1/4 bg-black text-white pl-6 pt-4'>
                    <p className='mb-2'>{item.author}</p>
                    <div className=' mt-2 w-4/5 text-xl'>{item.title}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PickStories;
