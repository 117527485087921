import React from 'react';
import { headingTextClass, smallHeader } from '../../../../helpers/constants';
import SolutionsSlider from './SolutionsSlider';

const Solutions = () => {
  return (
    <div className='bg-black text-white pl-6 pb-4 lg:pl-32 xl:pl-48 4k:pl-72 '>
      <div className={smallHeader + 'text-light'}>solutions</div>
      <div className={headingTextClass + 'mt-7'} style={{ color: 'white' }}>
        <div className='lg:hidden'>
          <div>Learn what strong</div>
          <div>insights can do to</div>
          <div>the growth lines of your company</div>
        </div>
        <div className='hidden lg:block'>
          <div>Learn what strong insights can</div>
          <div>do to the growth lines of your company</div>
        </div>
      </div>

      <SolutionsSlider />
    </div>
  );
};

export default Solutions;
