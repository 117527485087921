import React from 'react';

const Maps = () => {
  return (
    <>
      {/* <iframe
        title='headquaters'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20994.317452632695!2d2.1631036420684184!3d48.87175400205243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6635a53a7872b%3A0x40b82c3688b3d20!2s92500%20Rueil-Malmaison%2C%20France!5e0!3m2!1sen!2sin!4v1651479011728!5m2!1sen!2sin'
        style={{
          height: '31rem',
          minWidth: '26rem'
        }}
        allowFullScreen=''
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
      <iframe
        title='india-office'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.599596818716!2d77.68538721482227!3d12.997442290838986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1178a0a2078b%3A0x267fb0c1736d96fe!2sSolutionec%20Private%20Ltd!5e0!3m2!1sen!2sin!4v1651479357685!5m2!1sen!2sin'
        style={{
          height: '31rem',
          minWidth: '26rem'
        }}
        allowFullScreen=''
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe> */}
      <div
        className=' min-w-[25rem] h-[31rem] relative text-white font-light   bg-cover  bg-center'
        style={{
          backgroundImage: 'url("/assets/images/contacts-page/Bengaluru_Maps.jpg")'
        }}
      >
        <div className='absolute bottom-6 left-6'>
          <p className='text-lg '>BENGALURU</p>
          <div className='mt-4'>
            <p className='text-2xl'>Summit A, 6th Floor, Brigade</p>
            <p className='text-2xl m-0'>Meteropolis, Bengaluru 16, KA</p>
          </div>
        </div>
      </div>
      <div
        className='min-w-[25rem] h-[31rem] relative text-white font-light   bg-cover  bg-center'
        style={{
          backgroundImage: 'url("/assets/images/contacts-page/Paris_Map.png")'
        }}
      >
        <div className='absolute bottom-6 left-6'>
          <p className='text-lg'>HEADQUARTERS</p>
          <div className='mt-4'>
            <p className='text-2xl'>Rueil Malmaison - 92500,</p>
            <p className='text-2xl m-0'>France, Paris</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maps;
