import React from 'react';
import { getCurrentPageTheme } from '../helpers/util';
import SocialIcons from './SocialIcons';

const Footer = () => {
  const smallHeader = ` xl:text-lg xl:text-xl uppercase `;
  const darkTheme = getCurrentPageTheme() === 'dark';
  const theme = darkTheme ? 'bg-black sol-dark-scroll' : '';

  return (
    <footer
      className={`border-t pl-6 lg:pl-32 xl:pl-48 4k:pl-72 py-6 4k:pt-20 ${theme} sol-footer`}
      style={{ color: darkTheme ? '#fff' : '#8C9091' }}
    >
      <SocialIcons theme={darkTheme ? 'dark' : 'light'} size='md' />
      <div className='grid lg:flex gap-x-2 xl:gap-x-44 4k:text-2xl mt-12'>
        {/* <div className='grid lg:grid-cols-2 4k:grid-cols-3 gap-x-2 xl:gap-0 4k:text-2xl mt-12'> */}
        <div>
          <div
            className={
              `4k:text-3xl ${darkTheme ? 'text-white' : 'text-dark-3'}` + smallHeader
            }
          >
            contact
          </div>
          <div className='my-10'>
            <div>For general queries:</div>
            <div>hello@solutionec.com</div>
            <div className='mt-4'>For job opportunities:</div>
            <div>jobs@solutionec.com</div>
          </div>
        </div>
        <div>
          <div>
            <div
              className={
                `4k:text-3xl ${darkTheme ? 'text-white' : 'text-dark-3'}` + smallHeader
              }
            >
              {/* execution center */}
              delivery center
            </div>
            <div className='my-10'>
              <div>Summit A</div>
              <div>6th Floor, Brigade Meteropolis</div>
              <div>Garudacharpalya, Bangalore, KA</div>
              <div>560016</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div
              className={
                `4k:text-3xl ${darkTheme ? 'text-white' : 'text-dark-3'}` + smallHeader
              }
            >
              HEADQUARTERS
            </div>
            <div className='my-10'>
              <div>25-27 rue Tronchet, Paris 75008</div>
              <div>France, +33 (0)1 47 14 72 38</div>
            </div>
          </div>
        </div>
      </div>
      <div className='m-32'></div>
      <div
        className={`grid lg:flex gap-x-2 xl:gap-x-44 4k:text-2xl  ${
          darkTheme ? 'text-white' : 'text-dark-3'
        }`}
      >
        <div className='mr-4'>
          Solutionec &copy; {new Date().getFullYear()}. All Rights Reserved
        </div>
        <div>
          <a href='/'>Privacy Policy</a>
        </div>
        <div></div>
      </div>
    </footer>
  );
};

export default Footer;
