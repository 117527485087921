import React, { useState } from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';
import { routes } from '../../helpers/constants';

const Latest = () => {
  const [copied, setCopied] = useState(false);
  const copy = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  return (
    <div className='lg:mt-[268px] '>
      <div className='pl-6 xl:pl-48 pb-12  w-full'>
        <div className={smallHeader + 'text-dark-3'}>Latest</div>
        <div className='grid xl:grid-cols-2 mt-4'>
          <div className={headingTextClass + 'mb-2 mt-7 '}>
            <a
              href={`${routes.success_stories_inner}/1`}
              className='text-black hover:no-underline'
            >
              <div>Creating Digital Impact with </div>
              <div>Nimble Technologies.</div>
            </a>
          </div>
        </div>

        <div className={'text-dark my-2 text-base 4k:text-2xl'}>
          <div>Your Digitals Data Agents for Your Digital Journey</div>
        </div>
        <div className='mt-8 flex w-2/6 h-10 justify-between'>
          <div className='w-1/4 '>
            <FacebookShareButton
              url={`https://solutionec-web.netlify.app${window.location.pathname}`}
            >
              <div className=' w-full  h-full flex content-center items-center'>
                <img
                  src='/assets/images/social-icons/Facebook_Icon.svg'
                  className='w-6 h-6'
                  alt='facebook'
                />
                <p className='pl-2'>Share</p>
              </div>
            </FacebookShareButton>
          </div>
          <div className='w-1/4 '>
            <LinkedinShareButton
              title='Solutionec Carrers'
              url={`https://solutionec-web.netlify.app${window.location.pathname}`}
              hashtag={'#solutionec'}
            >
              <div className='flex content-center h-full items-center justify-between'>
                <img
                  src='/assets/images/social-icons/Linkedln_Icon.svg'
                  className='w-6 h-6'
                  alt='linkedin'
                />
                <p className='pl-4'>LinkedIn</p>
              </div>
            </LinkedinShareButton>
          </div>
          <div className='w-1/4 ml-2'>
            <TwitterShareButton
              url={`https://solutionec-web.netlify.app${window.location.pathname}`}
            >
              <div className='flex content-center h-full items-center justify-between'>
                <img
                  src='/assets/images/social-icons/Twitter_Icon.svg'
                  className='w-6 h-6'
                  alt='twitter'
                />
                <p className='pl-4'>Twitter</p>
              </div>
            </TwitterShareButton>
          </div>

          <div className='w-1/4 '>
            <button onClick={copy} className='text-black'>
              <div className='flex content-center h-full items-center justify-between'>
                <img
                  src='/assets/images/social-icons/Copy.svg'
                  className='w-6 h-6'
                  alt='copy'
                />
                <p className='pl-4'>{!copied ? 'Copy' : 'Copied!'}</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Latest;
