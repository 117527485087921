import React from 'react';
import { smallHeader, headingTextClass } from '../../../helpers/constants';
import ImageSlider from '../ImageSlider';

const Slide1 = (
  <>
    <div
      className='w-full h-screen items-center relative bg-cover bg-no-repeat'
      style={{
        backgroundImage: 'url("/assets/images/landing-page/Art_Science_of_Data_OPT.jpg")'
      }}
    >
      {/* <img
        className='bg-cover bg-no-repeat w-4/5 lg:w-2/5 absolute top-[21%] left-[10%] lg:top-[13%] lg:left-[30%]'
        src='/assets/images/landing-page/Artwork_Butterfly.png'
        alt='slider 3 artrwork'
      /> */}
      <div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-20 lg:pb-16 absolute bottom-0 left-0 text-light'>
        <span className={smallHeader + 'text-light'}>analytics</span>
        <div className={headingTextClass + 'mt-7 text-light'}>
          <div>
            <div>Art & Science of Data:</div>
            <div>Weaving Data Stories</div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const Slide2 = (
  <>
    <div className='w-full h-screen relative sol-dark-scroll'>
      <img
        src='/assets/images/landing-page/BI_Slider_OPT.jpg'
        alt='DI'
        className='w-full h-screen object-cover object-center'
      />
      <div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-20 lg:pb-16 absolute bottom-0 left-0'>
        <span className={smallHeader + 'text-light'}>experience</span>
        <div className={headingTextClass + 'mt-7 text-light '}>
          <div className='hidden lg:block'>
            <div>The Power of</div>
            <div>Business Intelligence with us</div>
          </div>
          <div className='block lg:hidden'>
            <div>The Power of </div>
            <div>Business Intelligence</div>
            <div>with us</div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const Slide3 = (
  <>
    <div className='w-full h-full relative sol-dark-scroll'>
      <img
        src='/assets/images/landing-page/Consulting_Managment_OPT_2.jpg'
        alt='everything begins with a dot'
        className='w-full h-screen object-cover object-center'
      />
      <div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-20 lg:pb-16 absolute bottom-0 left-0 text-light'>
        <span className={smallHeader + 'text-light'}>Data Analytics</span>
        <div className={'mt-7 capitalize' + headingTextClass}>
          <div>
            <div>Consulting Management:</div>
            <div>Taking you to your ‘Aha!’ Moment</div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const Slide4 = (
  <>
    <div className='w-full h-full relative sol-dark-scroll'>
      <img
        src='/assets/images/landing-page/Dot_OPT.jpg'
        alt='everything begins with a dot'
        className='w-full h-screen object-cover object-center'
      />
      <div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-20 lg:pb-16 absolute bottom-0 left-0'>
        <span className={smallHeader + 'text-light'}>data</span>
        <div className={headingTextClass + 'mt-7 text-light'}>
          <div>Everything</div>
          <div>Begins with a dot</div>
        </div>
      </div>
    </div>
  </>
);

const Slide5 = (
  <>
    <div
      className='w-full h-screen items-center relative bg-cover bg-no-repeat'
      style={{
        backgroundImage:
          'url("/assets/images/landing-page/Insights_Slider_Artwork_OPT.jpg")'
      }}
    >
      {/* <img
        className='bg-cover bg-no-repeat w-4/5 lg:w-2/5 absolute top-[21%] left-[10%] lg:top-[13%] lg:left-[30%]'
        src='/assets/images/landing-page/Artwork_Butterfly.png'
        alt='slider 3 artrwork'
      /> */}
      <div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-20 lg:pb-16 absolute bottom-0 left-0 text-light'>
        <span className={smallHeader + 'text-light'}>insights</span>
        <div className={'mt-7' + headingTextClass}>
          <div>
            {/* <div>Turning</div>
            <div>Insights into action</div> */}
            <div>Turning insights into</div>
            <div>actionable and strategic decisions</div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide1];

const MoveForward = () => {
  return (
    <>
      {/* <div className='w-full h-screen items-center relative'>{Slide1}</div> */}
      <ImageSlider
        slides={slides}
        indicatorType='dashed'
        alignment='text-right pr-20'
        dotPosition='absolute bottom-[4rem]'
        autoPlay={true}
      />
    </>
  );
};

export default MoveForward;
