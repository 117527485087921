import React from 'react';
import {
  headingTextClass,
  smallHeader,
  subHeader,
  routes
} from '../../../helpers/constants';
import Button from '../../Button';
import { Link } from 'react-router-dom';

const ListenTo = () => {
  return (
    <div className='mt-0 lg:mt-40 xl:mt-0 4k:mt-64  '>
      <div
        className='relative bg-cover bg-no-repeat success-stories-bg'
        style={{
          backgroundImage:
            'url("/assets/images/landing-page/Sucess_Stories_x72_Optimised.jpg")'
        }}
      >
        <div className='grid absolute bottom-0 left-0 pl-6 lg:pl-32 xl:pl-48 4k:pl-72 4k:pb-52'>
          <div className='text-white'>
            <div className='flex items-center'>
              <div className={`${smallHeader}`} style={{ color: 'white' }}>
                listen to
              </div>
              <img
                className='w-[3.8rem] h-[1.8rem] ml-2'
                src='/assets/images/landing-page/listen_to_icon.svg'
                alt='sound waves logo'
              />
            </div>
            <div
              className={headingTextClass + 'mt-5 xl:mt-7 4k:mt-12 '}
              style={{ color: 'white' }}
            >
              <div>What our</div>
              <div>Clients have to Say?</div>
            </div>
            <div className={subHeader + 'mt-6'}>
              <div className='text-white'>We let our success make the noise</div>
            </div>
            <div className='mt-8 xl:mt-12 mb-20 xl:mb-36'>
              <a href={routes.success_stories} className='text-white' rel='noreferrer'>
                <Button
                  title={'read more'}
                  primaryColor='border-white'
                  secondaryColor='hover:bg-white hover:text-black'
                />
              </a>
              {/* <Link to={routes.success_stories} className='text-white'>
                <Button
                  title={'read more'}
                  primaryColor='border-white'
                  secondaryColor='hover:bg-white hover:text-black'
                />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListenTo;
