import React, { useEffect, useState } from 'react';
import MenuBar from './MenuBar';
import NewsIcon from './NewsIcon';

const Sidebar = () => {
  const [sidebarTheme, setSidebarTheme] = useState({
    menuIcon: 'light',
    newsIcon: 'light'
  });
  const [hideTracker, setHideTracker] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTracker = document.getElementById('scrollbar');
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;

      if (scrollTracker) {
        scrollTracker.style.top =
          scrolled < 0 ? `${scrolled}%` : `calc(${scrolled}% - 6rem)`;
      }
    };

    document.addEventListener('scroll', () => {
      handleScroll();
    });
    // eslint-disable-next-line
  }, []);

  return (
    // only for tablet and above
    <div className='hidden lg:block'>
      <div className='w-20 xl:w-32 4k:w-44 border-r border-gray-200 h-full fixed top-0 py-16 px-5 4k:pt-32 4k:pb-20 flex flex-col justify-between items-center z-20'>
        <MenuBar
          sidebarTheme={sidebarTheme}
          setSidebarTheme={setSidebarTheme}
          setHideTracker={setHideTracker}
        />
        <NewsIcon theme={sidebarTheme ? sidebarTheme.newsIcon : 'light'} />
      </div>
      {/* page tracker */}
      <div
        className='w-px h-full fixed top-0 ml-20 xl:ml-32 4k:ml-44 z-20 pt-24'
        style={hideTracker ? { display: 'none' } : { display: 'block' }}
      >
        {/* <div className='hidden xl:block w-px h-full fixed top-0 ml-32 z-20 pt-24 bg-gray-200'> */}
        <div
          className='w-full h-24 bg-gray-800 relative'
          id='scrollbar'
          style={{ top: 'calc(0% - 6rem)', right: 1 }}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
