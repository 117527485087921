import React, { useState } from 'react';
import '../../styles/slider.css';
import CenterRipple from './vision-animations/CenterRipple';
import DiagonalRipple from './vision-animations/DiagonalRipple';
import LeftRipple from './vision-animations/LeftRipple';
import UpRipple from './vision-animations/UpRipple';
import { routes } from '../../helpers/constants';

const Blogs = ({ cards }) => {
  const [id, setId] = useState(null);

  return (
    <div className='slider-container ml-auto lg:ml-0'>
      <div id='blogs-card' className='cards-container mt-10 4k:mt-28   '>
        {cards.map((card, index) => (
          <div
            key={`blog#${index}`}
            className={`${index === 0 ? '' : 'ml-6 xl:ml-10 cursor-pointer'}`}
            onMouseEnter={() => {
              setId(index);
            }}
            onMouseLeave={() => {
              setId(null);
            }}
          >
            {/* <Link
              className='hover:no-underline'
              to={`${routes.blog_inner_page}/${card.id}`}
            >
              <div className='text-dark-3 hover:text-header-primary select-none'>
                <div
                  className='w-72 h-64 lg:w-80 lg:h-64 xxl:w-96 xxl:h-80 4k:w-blog-img 4k:h-blog-img bg-cover bg-center bg-no-repeat'
                  style={{ backgroundImage: `url("${card.imgSrc}")` }}
                ></div>
                <div>
                  <div className='text-gray-400 mt-4 text-[0.875rem] tracking-[0.025rem] 4k:text-lg'>
                    {card.date}
                  </div>
                  <div className='text-lg leading-[1.5rem] tracking-[0.025rem] 4k:text-3xl mt-4'>
                    {card.title.map((title, index) => (
                      <div key={`title#${index}`}>{title}</div>
                    ))}
                  </div>
                  <div className='mt-6 uppercase '>read more</div>
                  <div
                    className={
                      'w-[20px] h-[2px] bg-blue-600 mt-[2.5px] ' +
                      (id === index
                        ? 'visible w-[50px] transition-all'
                        : 'transition-all invisible')
                    }
                  ></div>
                </div>
              </div>
            </Link> */}
            <a
              href={`${routes.blog_inner_page}/${card.id}`}
              className='hover:no-underline'
            >
              <div className='text-dark-3 hover:text-header-primary select-none'>
                <div
                  className='w-72 h-64 lg:w-80 lg:h-64 xxl:w-96 xxl:h-80 4k:w-blog-img 4k:h-blog-img bg-cover bg-center bg-no-repeat'
                  style={{ backgroundImage: `url("${card.imgSrc}")` }}
                ></div>
                <div>
                  <div className='text-gray-400 mt-4 text-[0.875rem] tracking-[0.025rem] 4k:text-lg'>
                    {card.date}
                  </div>
                  <div className='text-lg leading-[1.5rem] tracking-[0.025rem] 4k:text-3xl mt-4'>
                    {card.title.map((title, index) => (
                      <div key={`title#${index}`}>{title}</div>
                    ))}
                  </div>
                  <div className='mt-6 uppercase '>read more</div>
                  <div
                    className={
                      'w-[20px] h-[2px] bg-blue-600 mt-[2.5px] ' +
                      (id === index
                        ? 'visible w-[50px] transition-all'
                        : 'transition-all invisible')
                    }
                  ></div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const Visions = ({ cards }) => (
  <div className='slider-container ml-auto xl:ml-0'>
    <div id='visions-card' className='cards-container mt-8 lg:mt-2 xl:mt-12'>
      {cards.map((card, index) => (
        <div
          key={`vision#${index}`}
          // vision-card-bg class has gradient styling
          className={`text-white p-6 4k:p-10 4k:pb-16 blue-white  4k:grid  bg-cover bg-no-repeat bg-center   ${index === 0 ? '' : 'ml-6 4k:ml-10'
            } ${window.innerWidth < 1200 ? 'vision-card-bg' : ''}`}
          style={{
            backgroundImage:
              'url("/assets/images/landing-page/Values_Background_x72.jpg")'
          }}
        >
          <div className='relative'>{card.animation}</div>
          <div className='4k:flex 4k:flex-col 4k:justify-end'>
            <div className='mt-14 font-semibold text-xl 4k:text-4xl tracking-[0.025rem]'>
              {card.header}
            </div>
            {/* change text-xs to text-base later */}
            <div className='mt-3 text-[0.813rem] tracking-[0.025rem] leading-[1.063rem] 4k:text-2xl vision-card-font'>
              {card.description?.map((description, index) => {
                return <div key={`description#${index}`}>{description}</div>;
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

//added for insight page blog section

const Insight = ({ cards }) => (
  <div className='slider-container ml-auto lg:ml-0 insight-slider-container'>
    <div id='insight-card' className='cards-container mt-[-40px] 4k:mt-28 '>
      {cards.map((card, index) => (
        <div key={`blog#${index}`} className={`${index === 0 ? '' : 'ml-6 xl:ml-10'}`}>
          <div
            className='image-width-for-cat-card  4k:w-blog-img 4k:h-blog-img bg-cover bg-center bg-no-repeat'
            style={{ backgroundImage: `url("${card.imgSrc}")` }}
          ></div>
          <div className='cat-card-text'>
            <div className=' mt-4 4k:text-lg'>{card.date}</div>
            <div className='text-lg 4k:text-3xl mt-4'>
              {card.title.map((title, index) => (
                <div key={`title#${index}`}>{title}</div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

//added for photo section in Life@solutionec section

const Photos = ({ cards }) => (
  <>
    <div className='slider-container ml-auto lg:ml-0 insight-slider-container'>
      <div id='photos' className='cards-container mt-[200px] 4k:mt-28 '>
        {cards.map((card, index) => (
          <div key={`${index}`} className={`${index === 0 ? '' : 'ml-6 xl:ml-10'}`}>
            <div className="w-60 h-56 bg-gray-800 mr-8" style={{ border: "1px solid black" }}>
              <div className=" w-20 h-20 rounded-full bg-black mt-12 ml-12">

              </div>
              <div className='mt-8 ml-12 uppercase '>
                {card.header}
              </div>
              <div className='ml-12'>
                {card.desc}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

  </>
)


//Added for csr section in Life@solutionec section

const Csr = ({ cards }) => (
  <>
    <div className='slider-container ml-auto lg:ml-0 insight-slider-container'>
      <div id='csr' className='cards-container mt-[200px] 4k:mt-28 '>
        {cards.map((card, index) => (
          <div key={`${index}`} className={`${index === 0 ? '' : 'ml-6 xl:ml-10'}`}>
            <div className="w-60 h-56 bg-gray-800 mr-8 flex justify-center items-center" style={{ border: "1px solid black" }}>
              <div className=" w-28 h-28 rounded-full bg-black text-light">
                {card.fullImg}
              </div>

            </div>
          </div>
        ))}
      </div>
    </div>

  </>
)

// end here

const Other = ({ cards, targetId }) => (
  <div className='slider-container'>
    <div id={targetId || 'cards'} className='cards-container gap-6'>
      {cards}
    </div>
  </div>
);

const CardSlider = ({ source, children, targetId }) => {
  // TODO update titles for feb and march POE
  const blogs = [
    {
      id: 1,
      imgSrc: '/assets/images/landing-page/Pandemic_Leaders.png',
      date: 'February 21, 2022',
      title: ['The Post', 'Pandemic Leaders'],
      articleLink:
        'https://www.linkedin.com/pulse/post-pandemic-leaders-solutionec/?trackingId=EZOA8rfoTUm76cGQCE1erw%3D%3D'
    },
    {
      id: 2,
      imgSrc: '/assets/images/landing-page/Value_Driven_Workplace.png',
      date: 'April 27, 2022',
      title: ['Value-Driven Workplace:', 'The New Shade of Corporate'],
      articleLink:
        'https://www.linkedin.com/pulse/value-driven-workplace-new-shade-corporate-solutionec/?trackingId=SER5jeQ8nMAgkLum4v6htw%3D%3D'
    },
    {
      id: 3,
      imgSrc: '/assets/images/landing-page/Care.png',
      date: 'March 24, 2022',
      title: ['The New Age', 'of Palliative Care'],
      articleLink:
        'https://www.linkedin.com/pulse/new-age-palliative-care-solutionec/?trackingId=EGRy5QHOPfdR1h3DHrdC1g%3D%3D'
    },
    {
      id: 4,
      imgSrc: '/assets/images/landing-page/BI.png',
      date: 'June 02, 2022',
      title: ['The Business Intelligence', 'Know-Hows of the Modern World'],
      articleLink:
        'https://www.linkedin.com/pulse/business-intelligence-know-hows-modern-world-solutionec?trk=public_post-content_share-article'
    }
    // {
    //   id: 5,
    //   imgSrc: '/assets/images/landing-page/blog-img2.jpg',
    //   date: 'October 01, 2021',
    //   title: ['Lorem ipsum dolor sit amet,', ' adipiscing elit, sed diam nonum']
    // },
    // {
    //   id: 6,
    //   imgSrc: '/assets/images/landing-page/blog-img3.jpg',
    //   date: 'October 01, 2021',
    //   title: ['Lorem ipsum dolor sit amet,', ' adipiscing elit, sed diam nonum']
    // },
    // {
    //   id: 7,
    //   imgSrc: '/assets/images/landing-page/blog-img1.jpg',
    //   date: 'October 01, 2021',
    //   title: ['Lorem ipsum dolor sit amet,', ' adipiscing elit, sed diam nonum']
    // },
    // {
    //   id: 8,
    //   imgSrc: '/assets/images/landing-page/blog-img2.jpg',
    //   date: 'October 01, 2021',
    //   title: ['Lorem ipsum dolor sit amet,', ' adipiscing elit, sed diam nonum']
    // },
    // {
    //   id: 9,
    //   imgSrc: '/assets/images/landing-page/blog-img3.jpg',
    //   date: 'October 01, 2021',
    //   title: ['Lorem ipsum dolor sit amet,', ' adipiscing elit, sed diam nonum']
    // }
  ];

  const visions = [
    {
      id: 1,
      animation: <UpRipple />,
      header: 'Innovation',
      description: [
        'Lorem ipsum dolor sit amet, ametus',
        'consectetuer adipiscing elit, ssediam',
        'nonummy nibho'
        // 'Partnering with healthcare,',
        // 'pharmaceutical and life science',
        // 'companies, Solutionec helps tap',
        // 'into data for better decision-making.'
        // old
        // 'Partnering with healthcare,',
        // 'Solutionec helps tap into',
        // 'data for better decision-making.'
        // old
        // 'As a trusted partner in the',
        // 'healthcare domain, Solutionec',
        // 'helps tap into the power of data',
        // 'analytics for better decision-making'
      ]
    },
    {
      id: 2,
      animation: <DiagonalRipple />,
      header: 'Velocity',
      description: [
        'Lorem ipsum dolor sit amet, ametus',
        'consectetuer adipiscing elit, ssediam',
        'nonummy nibho'
        // 'Data is growing exponentially,',
        // 'and speed matters.',
        // 'In today’s competitive market, it’s',
        // 'crucial to stay ahead of the curve –',
        // 'with agile database management and',
        // 'insightful market research'
        // old
        // 'Drawing on cutting-edge technology',
        // 'and management brings together expert',
        // 'profiles with more than 15 years of experience',
        // 'in the consulting field. Our analytics experts',
        // 'transform your data into insights you can leverage.'
      ]
    },
    {
      id: 3,
      animation: <LeftRipple />,
      header: 'Empathy',
      description: [
        'Lorem ipsum dolor sit amet, ametus',
        'consectetuer adipiscing elit, ssediam',
        'nonummy nibho'
        // 'We use data analytics and data science',
        // 'to empower clients who are championing',
        // 'life-changing and life-saving',
        // 'innovations for people worldwide'
      ]
    },
    {
      id: 4,
      animation: <CenterRipple />,
      header: 'Quality',
      description: [
        'Lorem ipsum dolor sit amet, ametus',
        'consectetuer adipiscing elit, ssediam',
        'nonummy nibho'
        // 'Draw on cutting-edge technology and',
        // 'management brings together expert profiles',
        // 'with more than 15 years of experience',
        // 'in the consulting field, our analytics experts',
        // 'transform your data into insights you can leverage.'
      ]
    }
  ];

  const insights = [
    {
      id: 1,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 2,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 3,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 4,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 5,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 6,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 7,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 8,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    },
    {
      id: 9,
      imgSrc: '/assets/images/insight-page/category-blogs.png',
      date: 'October 01, 2021',
      title: [
        'Why Data Analytics,',
        'should be a part of your ',
        ' core business operation'
      ]
    }
  ];
  const photos = [
    {
      id: '1',
      header: 'Birthday',
      desc: 'Celebrations 22',
      fullImg: ''
    },
    {
      id: '2',
      header: 'Event',
      desc: 'Stay Connect',
      fullImg: ''
    },
    {
      id: '3',
      header: 'Event',
      desc: 'Stay Connect',
      fullImg: ''
    },
    {
      id: '4',
      header: 'Event',
      desc: 'Stay Connect',
      fullImg: ''
    },
    {
      id: '5',
      header: 'Event',
      desc: 'Stay Connect',
      fullImg: ''
    },
    {
      id: '6',
      header: 'Eventss',
      desc: 'Stay Connect',
      fullImg: ''
    },
  ]
  const csr = [
    {
      id: 1,
      fullimg: ''
    },
    {
      id: 2,
      fullimg: ''
    },
    {
      id: 3,
      fullimg: ''
    },
    {
      id: 4,
      fullimg: ''
    },
    {
      id: 5,
      fullimg: ''
    },
    {
      id: 6,
      fullimg: ''
    },
    {
      id: 7,
      fullimg: ''
    }
  ]

  const renderComponent = {
    blogs: <Blogs cards={blogs} />,
    visions: <Visions cards={visions} />,
    insights: <Insight cards={insights} />,
    photos: <Photos cards={photos} />,
    csr: <Csr cards={csr} />
  };

  return renderComponent[source] || <Other cards={children} targetId={targetId} />;
};

export default CardSlider;
