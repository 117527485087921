import React from 'react';
import { Slider } from 'rsuite';
import { smallHeader } from '../../../../helpers/constants';
import ImageSlider from '../../../Join-Us/ImageSlider';

const SolutionsSlider = () => {
  const Slide1 = (
    <div className='relative'>
      <div className='h-[420px] lg:w-[70%] m-auto  '>
        <img
          className='bg-cover bg-no-repeat  h-[70%]'
          src='/assets/images/flashcard/Artwork_BP.png'
          alt=''
        />
      </div>
      <div className='text-white text-lg lg:text-3xl absolute bottom-12'>
        Brand performance analysis
      </div>
    </div>
  );

  const Slide2 = (
    <div className='relative'>
      <div className='h-[420px] lg:w-[70%] m-auto   '>
        <img
          className='bg-cover bg-no-repeat   h-[70%]'
          src='/assets/images/flashcard/Artwork_CA.png'
          alt=''
        />
      </div>
      <div className='text-white text-lg lg:text-3xl absolute bottom-12'>
        Competitive intelligence
      </div>
    </div>
  );

  const Slide3 = (
    <div className='relative'>
      <div className='h-[420px] lg:w-[70%]  m-auto  '>
        <img
          className='bg-cover bg-no-repeat   h-[70%]'
          src='/assets/images/flashcard/Artwork_MA.png'
          alt=''
        />
      </div>
      <div className='text-white text-lg lg:text-3xl absolute bottom-12'>
        Market analysis
      </div>
    </div>
  );

  const Slide4 = (
    <div className='relative'>
      <div className='h-[420px]  lg:w-[85%] m-auto  '>
        <img
          className='bg-cover bg-no-repeat  h-[70%]'
          src='/assets/images/flashcard/Artwork_MEAFT.png'
          alt=''
        />
      </div>
      <div className='text-white text-lg lg:text-3xl absolute bottom-12'>
        Market evaluation against financial targets
      </div>
    </div>
  );

  return (
    <div className='mt-20 '>
      <div className='w-full lg:w-[60%] m-auto   h-aut0 '>
        <ImageSlider slides={[Slide1, Slide2, Slide3, Slide4]} dotPosition={'relative'} />
      </div>
    </div>
  );
};

export default SolutionsSlider;
