import React from 'react';

const SliderButtons = ({
  buttonClass,
  svgContainerClass,
  svgClass,
  targetId,
  mainContainerClass
}) => {
  return (
    <div className={mainContainerClass}>
      <button
        className={buttonClass}
        onClick={() => {
          const target = document.querySelector(`#${targetId}`);
          target.scrollLeft = target.scrollLeft -=
            window.innerWidth / 2 > 600 ? window.innerWidth / 2 : window.innerWidth - 100;
        }}
      >
        <span className={svgContainerClass}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={svgClass}
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      </button>
      <button
        className={buttonClass}
        onClick={() => {
          const target = document.querySelector(`#${targetId}`);
          target.scrollLeft = target.scrollLeft +=
            window.innerWidth / 2 > 600 ? window.innerWidth / 2 : window.innerWidth - 100;
        }}
      >
        <span className={svgContainerClass}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={svgClass}
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default SliderButtons;
