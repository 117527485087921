import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';

const WhoWeAre = () => {
  const data = {
    numbers: ['100+', '', 'Offices', '2018'],
    info: [
      'Employees',
      'Users Across the Globe',
      'Paris and Bangalore',
      'Incepted at Rueil Mailmason'
    ]
  };

  const MapDetails = ({ number, info }) => {
    return (
      <>
        <div className='text-3xl'>{number}</div>
        <div className='text-xs'>
          <div>{info}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          background:
            'linear-gradient(0deg, rgba(111,155,242,1) 0%, rgba(202,233,255,1) 100%)'
        }}
      >
        <div className='pl-6 lg:pl-32 xl:pl-48 pb-10'>
          <div className={smallHeader + 'pt-8 text-dark-3'}>who we are</div>
          <div className='w-1/2'>
            {/* (Note to Ajith and Rafeek: An era where information is the main strategic
            resource upon which individuals, organizations, and societies rely for their
            growth and development. Also called information millennium.) */}
          </div>
          <div className={headingTextClass + 'mt-7'}>
            <div className='lg:hidden'>
              <div>In the era</div>
              <div>of Information,</div>
              <div>we are here to unlock</div>
              <div>the true potential</div>
              <div>of your data!</div>
            </div>
            <div className='hidden lg:block'>
              <div>In the era of information,</div>
              <div>we are here to unlock the</div>
              <div>true potential of your data!</div>
            </div>
          </div>
          <div className='mt-8 text-base lg:text-xl'>
            <div className='lg:hidden'>
              <div>Robust data analytics brings discipline in a</div>
              <div>business and discipline brings excellence.</div>
              <div>In the modern, fast-paced business</div>
              <div>ecosystem, you can no longer work</div>
              <div>with traditional data silos and take</div>
              <div>shots in the dark.</div>
            </div>
            <div className='hidden lg:block'>
              <div>
                Robust data analytics brings discipline in a business and discipline
                brings excellence.
              </div>
              <div>
                In the modern, fast-paced business ecosystem, you can no longer work with
                traditional
              </div>
              <div>data silos and take shots in the dark.</div>
            </div>
          </div>
          <div className='mt-6 text-base lg:text-xl'>
            <div className='lg:hidden'>
              <div>If you want to emerge truly as the top</div>
              <div>horse of this race, you need to bring</div>
              <div>together data systematically and derive</div>
              <div>the impactful business insights, for now,</div>
              <div>and the future. And we can be your data</div>
              <div>analytics partner who can carry you</div>
              <div>from the clutter to the moment of clarity,</div>
              <div>The “Aha!” moment.</div>
            </div>
            <div className='hidden lg:block'>
              <div>
                If you want to emerge truly as the top horse of this race, you need to
                bring together
              </div>
              <div>
                data systematically and derive the impactful business insights, for now,
                and the future.
              </div>
              <div>
                And we can be your data analytics partner who can carry you from the
                clutter
              </div>
              <div>to the moment of clarity, The “Aha!” moment.</div>
            </div>
          </div>
        </div>
      </div>
      {/* Section 2 */}
      <div
        className='pl-6 lg:pl-32 xl:pl-48 lg:py-20'
        style={{
          background:
            'linear-gradient(0deg, rgba(19,53,128,1) 0%, rgba(111,155,242,1) 100%)'
        }}
      >
        <div className='grid place-items-start'>
          <img
            className='w-11/12 lg:w-3/5'
            src='/assets/images/about-us/Excellence.svg'
            alt=''
          />
        </div>
        <div className='mt-10 lg:mt-20 text-xl lg:text-3xl text-white font-light'>
          <div className='lg:hidden'>
            <div>Founded in 2018, We are a new generation</div>
            <div>product-based management services</div>
            <div>and consulting partner with critical</div>
            <div>problem solving capabilities and expertise</div>
            <div>in business intelligence & analytics.</div>
            <div>An excellence centre that serves as a</div>
            <div>gateway to comprehensive and intelligible</div>
            <div>end-to-end solutions for the</div>
            <div>pharmaceutical & healthcare industry.</div>
          </div>
          <div className='hidden lg:block'>
            <div>Founded in 2018, We are a new-generation product-based</div>
            <div>management services and consulting partner with critical problem</div>
            <div>
              solving capabilities and expertise in business intelligence & analytics.
            </div>
            <div>An excellence centre that serves as a gateway to comprehensive</div>
            <div>and intelligible end-to-end solutions for the pharmaceutical</div>
            <div>and healthcare industry.</div>
          </div>
        </div>
        {/* <div className='text-light'>
          (Suggested taglines for this section)
          <div>
            Suggestions
            <ul>
              <li>A Motivated Team, Inspired Minds, and the Power of Business</li>
              <li>
                Intelligence -Life- Changing, Life-Improving and Life-Saving Innovations
                (old)
              </li>
              <li>From complex Data to Simple Insights</li>
            </ul>
          </div>
        </div> */}
      </div>
      {/* Section 3 Map */}
      <div
        className='pl-6 lg:pl-32 xl:pl-48 pb-20 lg:pb-0 lg:py-20 pt-10 lg:pt-0 text-white sol-dark-scroll'
        style={{
          background: 'linear-gradient(0deg, rgba(0,0,0,1) 65%, rgba(19,53,128,1) 100%)'
        }}
      >
        <div className='text-xs lg:text-base'>
          <div>
            A Motivated Team, Inspired Minds, and the Power of Business Intelligence
          </div>
          {/* <div>adipimet, consectetuer adipi</div> */}
        </div>
        <div className='block lg:hidden'>
          <img
            className={`m-auto mt-20 lg:m-0`}
            src={'/assets/images/about-us/World_Map.png'}
            alt='Map Map'
          />
          <div className='grid grid-cols-2 mt-20'>
            <div className='flex flex-col'>
              <MapDetails number={data.numbers[0]} info={data.info[0]} />
              <div className='mt-10'></div>
              <MapDetails number={data.numbers[1]} info={data.info[1]} />
            </div>
            <div className='flex flex-col'>
              <MapDetails number={data.numbers[3]} info={data.info[3]} />
              <div className='mt-10'></div>
              <MapDetails number={data.numbers[2]} info={data.info[2]} />
            </div>
          </div>
        </div>
        <div className='hidden lg:block relative h-[70vh]'>
          <div className='w-full absolute h-full'>
            <div className='flex justify-between mt-6 h-full'>
              <div className='flex my-[7%]'>
                <div>
                  <MapDetails number={data.numbers[0]} info={data.info[0]} />
                </div>
                <div className='flex flex-col-reverse'>
                  <div>
                    <MapDetails number={data.numbers[1]} info={data.info[1]} />
                  </div>
                </div>
              </div>
              <div className='flex my-[7%]'>
                <div>
                  <MapDetails number={data.numbers[2]} info={data.info[2]} />
                </div>
                <div className='flex flex-col-reverse'>
                  <div>
                    <MapDetails number={data.numbers[3]} info={data.info[3]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full grid place-items-center h-full'>
            <img
              className={`w-[31.25rem] xxl:w-[42rem] xxl:h-[18rem] 4k:w-[71rem] 4k:h-[28rem] h-[12.5rem] m-auto mt-20 lg:m-0`}
              src={'/assets/images/about-us/World_Map.png'}
              alt='World Map'
            />
          </div>
        </div>
        {/* <div className='mt-12'>
          Note to Ajith and Rafeek Please suggest the data to be added here
        </div> */}
      </div>
    </>
  );
};

export default WhoWeAre;
