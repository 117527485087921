import React from 'react';

import Latest from '../components/success_stories/Latest';
import PickStories from '../components/success_stories/PickStories';
import FollowUs from '../components/success_stories/FollowUs';
import Quote from '../components/success_stories/Quote';
import Featured from '../components/success_stories/Featured';
import Expressions from '../components/success_stories/Expressions';

const SuccessStories = () => {
  return (
    <>
      <div className='w-screen'>
        <Latest />
        <PickStories />
        <Quote />
        <Featured />
        <Expressions />
        <FollowUs />
      </div>
    </>
  );
};

export default SuccessStories;
