import React from "react";

const FamilyPhoto = ({index}) => {
	return (
		<>
			<div className="w-1/2 h-[85vh] mt-4 grid grid-cols-3 gap-2">

				{/* <div class="grid grid-cols-3 gap-4"> */}
				{/* <!-- 1st Column --> */}
				<div className="grid grid-rows-3 gap-2">
					<div class="col-span-1 row-span-1 bg-gray-200 p-2">{index+1}</div>
					<div class="col-span-1 row-span-1 bg-gray-300 p-2">{index+2}</div>
					<div class="col-span-1 row-span-1 bg-gray-800 p-2">{index+3}</div>

				</div>
				{/* <!-- 2nd Column --> */}
				<div className="grid grid-rows-2 gap-2">
					<div class="col-span-1 row-span-1 bg-gray-200 p-2">{index+4}</div>
					<div class="col-span-1 row-span-1 bg-gray-500 p-2">{index+5}</div>
				</div>
				{/* <!-- 3rd Column --> */}
				<div className="grid grid-rows-3 gap-2">
					<div class="col-span-1 row-span-1 bg-gray-600 p-2">{index+6}</div>
					<div class="col-span-1 row-span-1 bg-gray-700 p-2">{index+7}</div>
					<div class="col-span-1 row-span-1 bg-gray-900 p-2">{index+8}</div>
				</div>

			</div>
		</>
	)
}
export default FamilyPhoto;