import React from 'react';
import { flashcardVariables, headingTextClass } from '../../../../helpers/constants';
import CardSlider from '../../../landing-page/CardSlider';

const Compatibility = () => {
  const { smallHeader } = flashcardVariables;

  const functions = [
    {
      Function: 'Performance manager',
      'Parent Function': 'BI'
    },
    {
      Function: 'Strategy',
      'Parent Function': 'BU head'
    },
    {
      Function: 'Top management',
      'Parent Function': 'Marketing'
    },
    {
      Function: 'BU Head',
      'Parent Function': 'Sales'
    },
    {
      Function: 'Marketing',
      'Parent Function': 'Others'
    },
    {
      Function: 'Analyst',
      'Parent Function': 'Insight'
    },
    {
      Function: 'Sales',
      'Parent Function': 'SFE'
    },
    {
      Function: 'Training',
      'Parent Function': 'Synergy Administration'
    },
    {
      Function: 'Business development',
      'Parent Function': 'Trainee / VIE'
    },
    {
      Function: 'Market insight',
      'Parent Function': 'MEX'
    },
    {
      Function: 'Others'
    },
    {
      Function: 'SFE'
    },
    {
      Function: 'BOS Head'
    },
    {
      Function: 'Project manager'
    },
    {
      Function: 'New product launch'
    },
    {
      Function: 'Market Access'
    },
    {
      Function: 'Digital marketing'
    },
    {
      Function: 'Finance'
    },
    {
      Function: 'Buyer'
    },
    {
      Function: 'Synergy Administration'
    },
    {
      Function: 'IT'
    },
    {
      Function: 'Trade & commercial'
    },
    {
      Function: 'Pricing'
    },
    {
      Function: 'BI'
    },
    {
      Function: 'CMI'
    },
    {
      Function: 'Medical'
    },
    {
      Function: 'Alliance & Partnership'
    },
    {
      Function: 'VIE'
    },
    {
      Function: 'Marketing excellence'
    },
    {
      Function: 'Marketing strategy analysis'
    },
    {
      Function: 'Media'
    },
    {
      Function: 'Customer Relationship Management'
    },
    {
      Function: 'Shopper & trade marketing'
    },
    {
      Function: 'Switch & White Spaces'
    },
    {
      Function: 'Trainee'
    },
    {
      Function: 'E-Commerce'
    },
    {
      Function: 'Pharmacovigilance'
    },
    {
      Function: 'Supply chain'
    },
    {
      Function: 'Human Resources'
    },
    {
      Function: 'Forecasting'
    },
    {
      Function: 'OTC analyst'
    },
    {
      Function: 'Global Innovation'
    },
    {
      Function: 'Regulatory Affairs'
    },
    {
      Function: 'Lawyer'
    },
    {
      Function: 'Admin'
    }
  ];

  const Icon = ({ team }) => {
    return (
      <div className='flex flex-col items-center gap-y-6'>
        <div className='bg-gray-900 rounded-full w-20 h-20'></div>
        <div>{team}</div>
      </div>
    );
  };

  return (
    <>
      <div className='bg-black text-white pl-6 lg:pl-32 xl:pl-48 4k:pl-80 pb-20'>
        <div className={smallHeader + 'pt-28'}>COMPATIBILITY</div>
        <div className={headingTextClass + 'mt-7'} style={{ color: 'white' }}>
          <div>Flashcard access</div>
          <div>Across all the channels.</div>
        </div>
        <div>(shift + scroll to move around)</div>
      </div>
      <div className='bg-black text-white pl-6 lg:pl-48 flex items-center gap-10 pb-20 sol-dark-scroll '>
        <CardSlider>
          {functions.map((_, index) => {
            return (
              <div key={`icon#${index}`}>
                <Icon team={_.Function} />
              </div>
            );
          })}
        </CardSlider>
      </div>
    </>
  );
};

export default Compatibility;
