import React from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';
import CardSlider from '../landing-page/CardSlider';
import SliderButtons from '../../components/landing-page/SliderButtons';

const CategorySectionInsight = () => {
  return (
    <>
      <div>
        <div className='pl-6 lg:pl-32 xl:pl-48 pb-10 center-content-text'>
          <div
            className={smallHeader + 'uppercase pt-40 pb-30 text-dark-3 heading-left-sec'}
          >
            Category
          </div>
          <div className={headingTextClass + 'mt-6 heading-left-sec '}>
            <div className='lg:block pt-5'>
              <div>Usher into the age fueled </div>
              <div>by fact-based decision making</div>
            </div>
          </div>
        </div>
        <div className='grid lg:flex justify-between mt-4 xl:mt-0 lg:items-center xl:items-end'>
          <label
            className='read-more-trigger hidden xl:inline-block text-header-primary text-lg leading-[1rem] 4k:text-4xl'
            htmlFor='read-more-controller'
          ></label>
          <div className='hidden lg:block lg:pr-16 xl:pr-[15.313rem] 4k:pr-52'>
            <SliderButtons
              mainContainerClass=' flex items-center gap-5 4k:gap-7'
              buttonClass=' outline-none uppercase text-black border-2 border-black bg-light rounded-full hover:bg-black hover:text-light p-3 '
              svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
              svgContainerClass=' grid place-items-center '
              targetId='insight-card'
            />
          </div>
        </div>
        <div
          className='pl-6 lg:pl-32 xl:pl-48 insight-cards h-96 sol-dark-scroll '
          style={{
            backgroundImage: `url(/assets/images/insight-page/cat-slider-bg.png)`
          }}
        >
          <CardSlider source='insights' />
        </div>
        {/* <div className='hidden lg:block lg:pr-16 xl:pr-24 4k:pr-52 slider-button-insight border-2'>
          <SliderButtons
            mainContainerClass=' flex items-center gap-5 4k:gap-7'
            buttonClass=' outline-none uppercase text-header-primary border-2 border-header-primary bg-light rounded-full hover:bg-header-primary hover:text-light p-3 '
            svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
            svgContainerClass=' grid place-items-center '
            targetId='insight-card'
          />
        </div> */}
      </div>
    </>
  );
};

export default CategorySectionInsight;
