import React from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';

const Section1 = () => {
  return (
    <div className='relative h-[100vh] xl:h-[200vh] xxl:h-[170vh] sol-dark-scroll'>
      <img
        className='bg-cover bg-no-repeat absolute w-[100vw] h-full'
        src='/assets/images/career/header_bg.jpg'
        alt=''
      />
      <div className='absolute  left-0 bottom-0 pl-6 xl:pl-48 pb-12 '>
        <div className={smallHeader} style={{ color: 'white' }}>
          Join us
        </div>
        <div className='grid xl:grid-cols-2 mt-7'>
          <div className={headingTextClass + 'mb-2 '} style={{ color: 'white' }}>
            <div>To Build a</div>
            <div>Better Eco System</div>
          </div>
        </div>
        <div className='h-px w-11/12 bg-white my-6'></div>
        <div className={'text-dark my-2 text-base 4k:text-2xl text-white'}>
          <div>
            We believe in a workplace that is transparent, productive and empowers
            employees to do better. Our
          </div>
          <div>
            existing talent pool is working with clients and experts in the pharmaceutical
            sector, globally. The Solutionec
          </div>
          <div>
            policies are centered around nurturing the employees in a way that fits them
            the best.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
