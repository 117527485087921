import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import ApplicationForm from '../components/job-application/Application';
import ProfileInfo from '../components/job-application/ProfileInfo';
import EducationDetails from '../components/job-application/EducationDetails';
import EmploymentDetails from '../components/job-application/EmploymentDetails';
import CertificateDetails from '../components/job-application/CertificateDetails';
import Documents from '../components/job-application/Documents';
import Acknowledge from '../components/job-application/Acknowledge';
import ApplicationReview from '../components/job-application/ApplicationReview';
import { appConfig } from '../appConfig';
import { setDataToLocalStorage, getDataFromLocalStorage } from '../helpers/util';
import { toast } from 'react-toastify';
import axios from 'axios';

const JobApplicationForm = () => {
  const [status, setStatus] = useState('');
  const [jobInfo, setJobInfo] = useState({});

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [status]);

  useEffect(() => {
    const handleChange = (value) => {
      if (getDataFromLocalStorage('formStatus') === null) {
        setDataToLocalStorage('formStatus', 'Documents');
        setStatus('Documents');
      } else {
        let status = getDataFromLocalStorage('formStatus');
        setStatus(status);
      }
      setJobInfo(value);
    };

    let str = window.location.pathname;
    let type = str.split('/')[2];
    let s_no = str.split('/')[3];

    if (!type || !s_no || str.split('/').length > 4) {
      setStatus('404 Error');
    } else {
      // Fetching openings from backend api.

      if (type === 'freshers') {
        axios
          .get(`${appConfig.api}/openings`)
          .then((res) => {
            let value = res.data.freshers.find((item, index) => item.s_no == s_no);
            value ? handleChange(value) : setStatus('404 Error');
          })
          .catch((err) => {
            toast.error('Server is down please try again later');
            history.push('/');
          });
      } else if (type === 'experts') {
        axios
          .get(`${appConfig.api}/openings`)
          .then((res) => {
            let value = res.data.experts.find((item, index) => item.s_no == s_no);
            value ? handleChange(value) : setStatus('404 Error');
          })
          .catch((err) => {
            toast.error('Server is down please try again later');
            history.push('/');
          });
      } else {
        setStatus('404 Error');
      }
    }
  }, []);
  return (
    <>
      {status === 'Profile Info' ? (
        <ProfileInfo setStatus={setStatus} />
      ) : status === 'Education Details' ? (
        <EducationDetails setStatus={setStatus} />
      ) : status === 'Employment Details' ? (
        <EmploymentDetails setStatus={setStatus} />
      ) : status === 'Certificate Details' ? (
        <CertificateDetails setStatus={setStatus} />
      ) : status === 'Documents' ? (
        <Documents setStatus={setStatus} />
      ) : status === 'Acknowledgement' ? (
        <Acknowledge setStatus={setStatus} jobInfo={jobInfo} />
      ) : status === 'Review' ? (
        <ApplicationReview setStatus={setStatus} jobInfo={jobInfo} />
      ) : status === '404 Error' ? (
        <Redirect to='/404' />
      ) : (
        ''
      )}
      {/* <ApplicationForm /> */}
    </>
  );
};

export default JobApplicationForm;
