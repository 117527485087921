import React from 'react';
import Section1 from '../components/contact-us/sections/Section1';
import WeAre from '../components/contact-us/sections/WeAre';
import OurPresense from '../components/contact-us/sections/OurPresense';
import Messege from '../components/contact-us/sections/Messege';
// import Questions from '../components/contact-us/sections/Questions';

const ContactUs = () => {
  return (
    <>
      <Section1 />
      <WeAre />
      <OurPresense />
      <Messege />
      {/* <Questions /> */}
    </>
  );
};

export default ContactUs;
