import React from 'react';
import SliderButtons from '../../components/landing-page/SliderButtons';
import CardSlider from './CardSlider';
import { smallHeader, headingTextClass, subHeader } from '../../helpers/constants';

const PeopleWorkspace = () => {
  const EventCard = ({ type, name, id }) => {
    return (
      <div
        key={id}
        className=' min-w-[16rem] relative  h-60 w-64 bg-[#151516] rounded-[2rem]  mr-8 text-white'
      >
        <div className='h-20 w-20 bg-black rounded-full absolute top-8 left-8'></div>
        <div className='absolute bottom-8 left-8 '>
          <div className='text-base'>{type.toUpperCase()}</div>
          <div className='text-xl mt-1'>Stay Connect</div>
        </div>
      </div>
    );
  };

  const slide1 = <EventCard id='1' name={'Stay Connect'} type={'event'} />;

  const slide2 = <EventCard id='2' name={'Stay Connect'} type={'event'} />;

  const slide3 = <EventCard id='3' name={'Stay Connect'} type={'event'} />;

  const slides = [slide1, slide2, slide3];

  return (
    <div className='bg-black'>
      <img
        src='/assets/images/career/Rise_Artwork.png'
        alt='people_oriented_img'
        className='xl:w-[95%] w-full'
      />
      <div className='mt-10 pl-6 lg:pl-32 xl:pl-96 4k:pl-72  text-white '>
        <div className={smallHeader + 'w-max'} style={{ color: 'white' }}>
          Solutionec
        </div>
        <div className={headingTextClass + 'mt-7 4k:mt-12'} style={{ color: 'white' }}>
          <div className='block'>
            <div>People-Oriented Workspace</div>
          </div>
          <div className='text-base lg:text-xl lg:block pt-8'>
            <div>Our motto forever remains to become the better version</div>
            <div>of ourselves, every day, in a sustainable manner.</div>
          </div>
          <div className='text-base lg:text-xl xl:block mt-5'>
            We encourage values of innovation, velocity, quality, and empathy
          </div>
          <div className='text-base lg:text-xl'>
            not just at work but also as a skil in the day-to-day life of our employees.
          </div>
          <div className='block lg:hidden  pt-7'>
            <span>
              <div>
                Ranging from onsite opportunities, online learning with the world's best
                coaches to our
              </div>
              <div>
                in-house technical/non-technical mentorship program, at Solutionec you can
                have it all.
              </div>
              <div>
                Apart from work, unwinding session that involve sports activities, offsite
                events, CSR
              </div>
              <div>
                initiatives, and virtual meet ups are a constant part of the office
                culture.
              </div>
              <div className='mt-5'>
                We want our employees to strike the perfect balance of work and fun{' '}
              </div>
              <div>while learning remains a continual</div>
            </span>
          </div>
          <div className='hidden lg:block xl:hidden pt-6'>
            <span>
              <div>
                Ranging from onsite opportunities, online learning with the world's best
                coaches to our
              </div>
              <div>
                in-house technical/non-technical mentorship program, at Solutionec you can
                have it all.
              </div>
              <div>
                Apart from work, unwinding session that involve sports activities, offsite
                events, CSR
              </div>
              <div>
                initiatives, and virtual meet ups are a constant part of the office
                culture.
              </div>
              <div className='mt-5'>
                We want our employees to strike the perfect balance of work and fun{' '}
              </div>
              <div>while learning remains a continual</div>
            </span>
          </div>
        </div>

        <div className='grid lg:flex justify-between mt-4 xl:mt-0 lg:items-center xl:items-end '>
          <div className={subHeader + 'mt-4'} style={{ color: 'white' }}>
            <div className='hidden xl:block 4k:hidden pt-6'>
              <span>
                <div>
                  Ranging from onsite opportunities, online learning with the world's best
                  coaches to our
                </div>
                <div>
                  in-house technical/non-technical mentorship program, at Solutionec you
                  can have it all.
                </div>
                <div>
                  Apart from work, unwinding session that involve sports activities,
                  offsite events, CSR
                </div>
                <div>
                  initiatives, and virtual meet ups are a constant part of the office
                  culture.
                </div>
                <div className='mt-5'>
                  We want our employees to strike the perfect balance of work and fun{' '}
                </div>
                <div>while learning remains a continual</div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-20 pl-6 lg:pl-32 xl:pl-96'>
        <CardSlider targetId={'diversity'}>{slides}</CardSlider>
      </div>
    </div>
  );
};

export default PeopleWorkspace;
