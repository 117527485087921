import React from 'react';
import Section1 from './sections/Section1';
import EmploymentDetailsForm from './sections/EmploymentDetailsForm';

const EmploymentDetails = ({ setStatus }) => {
  return (
    <>
      <Section1
        subTitle='step 4'
        title='Employment Details'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
      />
      <EmploymentDetailsForm setStatus={setStatus} />
    </>
  );
};

export default EmploymentDetails;
