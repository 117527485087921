import React from 'react';
import AhaMoment from '../components/about-us/sections/AhaMoment';
import Leaders from '../components/about-us/sections/Leaders';
import OurImpact from '../components/about-us/sections/OurImpact';
import OurPeople from '../components/about-us/sections/OurPeople';
import OurStory from '../components/about-us/sections/OurStory';
import Section1 from '../components/about-us/sections/Section1';
import WhoWeAre from '../components/about-us/sections/WhoWeAre';

const AboutUs = () => {
  return (
    <>
      <Section1 />
      <WhoWeAre />
      <OurStory />
      <Leaders />
      <AhaMoment />
      <OurImpact />
      <OurPeople />
      {/* <div className='bg-black h-[60vh] mb-20 sol-dark-scroll'></div> */}
    </>
  );
};

export default AboutUs;
