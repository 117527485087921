import React from 'react';
import ImageSlider from './landing-page/ImageSlider';
import { smallHeader } from '../helpers/constants';

const Testimonial = () => {
  const TestimoniaCard = ({
    width,
    height,
    name,
    children,
    designation = 'designation',
    imgSrc
  }) => {
    return (
      <div className='pl-6 lg:pl-72 4k:pl-left-space pt-16 lg:pt-36 pb-16 lg:pb-28'>
        <div className={smallHeader + `text-black`}>testimonial</div>
        <div
          className={width + height + 'mt-12  bg-cover bg-center'}
          style={{ backgroundImage: `url(${imgSrc})` }}
        ></div>
        <div className='mt-8 text-[1.375rem] leading-[1.875rem] text-black'>
          {children}
        </div>
        <div className='mt-8 lg:mt-16'>
          <div className='text-lg font-semibold text-black'>{name}</div>
          <div className='block lg:hidden'>
            <div className='text-sm  text-black'>
              {designation.split('|').map((desg, index) => {
                return <div key={`designation#${index}`}>{desg}</div>;
              })}
            </div>
          </div>
          <div className='hidden lg:block'>
            <div className='text-sm text-black'>{designation}</div>
          </div>
        </div>
      </div>
    );
  };

  const testimonial1 = (
    <>
      <TestimoniaCard
        width={'w-32 '}
        height={'h-32 '}
        name={'Olivier Fouret'}
        designation='MSD | Oncology Business Unit Director Belgium & Luxembourg'
        imgSrc={'/assets/images/landing-page/Eye.png'}
      >
        <div className='lg:hidden h-[360px]'>
          <div>Working with Solutionec was a </div>
          <div>truly great experience,</div>
          <div>the team is extremely dedicated</div>
          <div>and deliver highly qualitative work.</div>
          <div>I strongly recommend Solutionec</div>
          <div>for any company willing to ease</div>
          <div>their decision making by taking fact </div>
          <div>based decision through data </div>
          <div>visualization !</div>
        </div>
        <div className='hidden lg:block'>
          <div>Working with Solutionec was a truly great experience,</div>
          <div>the team is extremely dedicated and deliver highly qualitative work.</div>
          <div>I strongly recommend Solutionec for any company willing to ease</div>
          <div>their decision making by taking fact based decision through</div>
          <div>data visualization !</div>
        </div>
      </TestimoniaCard>
    </>
  );

  const testimonial2 = (
    <>
      <TestimoniaCard
        width={'w-32 '}
        height={'h-32 '}
        name={'Himed Zalegh'}
        designation='Sanofi | Performance and Strategic Project Director Asia'
        imgSrc={'/assets/images/landing-page/Connectivity.png'}
      >
        <div className='lg:hidden'>
          <div>What really sets Solutionec apart </div>
          <div>is that they always strive to</div>
          <div>understand your needs and make</div>
          <div>the digital journey as seamless</div>
          <div>as possible for your teams.</div>
          <div>Solutionec has a keen understanding</div>
          <div>of how to leverage data in the</div>
          <div>pharma & biotech space.</div>
          <div>Their expert consultants provide</div>
          <div>top-notch business intelligence and</div>
          <div>analytics services for both</div>
          <div>short-term and long-term projects.</div>
        </div>
        <div className='hidden lg:block'>
          <div>
            What really sets Solutionec apart is that they always strive to understand
            your
          </div>
          <div>
            needs and make the digital journey as seamless as possible for your teams.
          </div>
          <div>
            Solutionec has a keen understanding of how to leverage data in the pharma &
          </div>
          <div>
            biotech space. Their expert consultants provide top-notch business
            intelligence
          </div>
          <div>and analytics services for both short-term and long-term projects.</div>
        </div>
      </TestimoniaCard>
    </>
  );

  const testimonial3 = (
    <>
      <TestimoniaCard
        width={'w-32 '}
        height={'h-16 '}
        name={'Pedro DaCosta'}
        designation='Sanofi | Commercial Manager, Sarilumab Europe'
        imgSrc={'/assets/images/landing-page/Eye_Option.png'}
      >
        <>
          <div className='lg:hidden h-[360px] flex flex-col'>
            <div>Solutionec team were crucial</div>
            <div>in the execution of a much needed</div>
            <div>market report for our company.</div>
            <div>They are always willing to go</div>
            <div>the extra mile and bring vital</div>
            <div>analytical skills to the projects.</div>
            <div>I highly recommend!</div>
          </div>
          <div className='hidden lg:block h-[150px]'>
            <div>
              Solutionec team were crucial in the execution of a much needed market report
            </div>
            <div>
              for our company. They are always willing to go the extra mile and bring
              vital
            </div>
            <div> analytical skills to the projects. I highly recommend!</div>
          </div>
        </>
      </TestimoniaCard>
    </>
  );

  const slides = [testimonial1, testimonial2, testimonial3, testimonial1];

  return (
    <div>
      <ImageSlider
        slides={slides}
        maxWidth='max-w-[100vw] lg:max-w-[98vw]'
        dotPosition='flex items-center justify-end'
        alignment={
          'text-left relative bottom-16 pl-6 lg:pl-0 lg:text-right lg:pr-[14rem]'
        }
        indicatorType='hollow'
        theme='dark'
        autoPlay={true}
      />
    </div>
  );
};

export default Testimonial;
