import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';

const Section1 = () => {
  return (
    <div
      className='w-full h-screen items-center relative bg-cover bg-no-repeat'
      style={{
        background:
          'linear-gradient(0deg, rgba(202,233,255,1) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%)'
      }}
    >
      <>
        {/* <div className='grid place-items-center'> */}
        <img
          className='bg-cover bg-no-repeat h-2/5 lg:h-1/2 m-auto mt-32 lg:mt-0 lg:right-52 lg:bottom-48 lg:absolute'
          src='/assets/images/about-us/Build.svg'
          alt='Build'
        />
        {/* </div> */}
        <div className='absolute bottom-0 left-0 pb-32 lg:pb-16 pl-6 lg:pl-32 xl:pl-48 4k:pl-72'>
          <div className={smallHeader + 'text-dark-3'}>about us</div>
          <div className={headingTextClass + 'mb-2 mt-7'}>
            <div>We Connect Data</div>
            <div>To Build a Better Future</div>
          </div>
          <div className='w-11/12 my-6 border-t-2 border-gray-400 h-px'></div>
          <div className='my-2'>
            <div className='lg:hidden'>
              <div>We can drive your ROI with a larger view</div>
              <div>into your customer demands and well-connected</div>
              <div>insights.</div>
            </div>
            <div className='hidden lg:block'>
              <div>
                We can drive your ROI with a larger view into your customer demands
              </div>
              <div>and well-connected insights.</div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Section1;
