import React, { useState, useEffect } from "react";
import { smallHeader, headingTextClass, subHeader } from '../../helpers/constants';
import FamilyPhoto from "./FamilyPhoto";

const Family = () => {

	const [photoIndex, setPhotoIndex] = useState(0);
	


	const handleMorePhoto = () => {
		setPhotoIndex(photoIndex + 8);
	}
	

	const photoIndexArray = Array.from({ length: Math.ceil(photoIndex / 8) + 1 }, (_, index) => index * 8); //Creating Array for index to fetch next 8 images [0,8,16,...]
	


	return (
		<>
			<div className="bg-black w-full pb-20 pt-36 sol-dark-scroll">


				<div className='pl-16 lg:pl-52 xl:pl-60 4k:pl-72 text-light'>
					<img src="/assets/images/life-solutionec/family.png" className="mb-20" >

					</img>
					<span className={smallHeader + 'text-light mb-8 '}>Family</span>
					<div className={'mt-7 mb-5' + headingTextClass}>
						<div>
							<div>Everything</div>
							<div>You Need To Know</div>
						</div>
					</div>

					<div className='mt-8 mb-16 text-xl lg:text-lg text-white font-light'>
						<div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Porro quisquam cupiditate, omnis quae hic </div>
						<div> iste vero error velit doloribus molestiae sequi, est eaque neque et fuga.</div>
					</div>

					{photoIndexArray.map(index =>(
							<FamilyPhoto key={index} index={index} />
						))}

					
					<div className="w-1/2 flex mt-8 ">
						<button className="justify-items-center" style={{ margin: 'auto' }} onClick={handleMorePhoto}>See more</button>
					</div>
				</div>

			</div>
		</>
	)
}
export default Family;