import React from 'react';
import { subHeader } from '../../../../helpers/constants';

const FlashStats = () => {
  const Stat = ({ header, number, desc }) => {
    return (
      <div
        className='pl-4 lg:pl-10 pt-2 lg:pt-4 w-32 h-36 lg:w-52 lg:h-60'
        style={{
          background:
            'linear-gradient(0deg, rgba(0,27,226,1) 0%, rgba(0,0,0,1) 64%, rgba(0,0,0,1) 100%)'
        }}
      >
        <div className='w-1/3 h-px lg:h-1.5 bg-white'></div>
        <div className='mt-2 text-xs lg:text-lg'>{header}</div>
        <div className='w-8 lg:w-16 h-8 lg:h-16 rounded-full bg-blue-900 my-2 lg:my-4'></div>
        <div className='text-2xl lg:text-5xl font-bold'>{number}</div>
        <div className='text-[0.9rem]'>{desc}</div>
      </div>
    );
  };

  return (
    <div className='mt-24 text-white'>
      {/* <div>
        Note to Ajith and Rafeek
        <div>Please suggest the details and data that will go here</div>
      </div> */}
      <div className='flex gap-8 lg:gap-20'>
        <Stat header={'Amount of Data'} number={'15,000'} desc={'we are processing'} />
        <Stat header={'No. of Clients'} number={'184'} desc={'we work with'} />
      </div>
      <div className='flex gap-8 lg:gap-20 mt-10 lg:mt-20'>
        <Stat header={'No. of Data Sets'} number={'129'} desc={'we process'} />
        <Stat header={'No. of Users'} number={'100+'} desc={'we have'} />
      </div>
      <div className='mt-10 pb-20 lg:pb-44 text-sm'>
        <div className={subHeader + 'text-xl gap-24 text-light hidden lg:block'}>
          <div>
            The abundance of such technology and resources can not only help you maximize
            the potential
          </div>
          <div>of your data but also initiate a robust strategy formulation.</div>
        </div>
        <div className='lg:hidden'>
          <div>The abundance of such technology & resources</div>
          <div>can not only help you maximise the potential of</div>
          <div>your data but also initiate a robust strategy</div>
          <div>formulation.</div>
        </div>
      </div>
    </div>
  );
};

export default FlashStats;
