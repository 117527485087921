import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';

const Expertise = ({ title1, title2, author, date, imgSrc }) => {
  return (
    <div
      className=' w-full bg-cover h-screen relative  text-white sol-dark-scroll lg:h-screen bg-center'
      style={{ backgroundImage: `url("${imgSrc}")` }}
    >
      <div className='pl-6 xl:pl-48 pb-12 absolute bottom-0 w-full'>
        <div className={smallHeader + 'text-xl'} style={{ color: 'white' }}>
          Expertise
        </div>
        <div className='grid xl:grid-cols-2 mt-4'>
          <div
            className={headingTextClass + 'mb-2 mt-1 lg:mt-7'}
            style={{ color: 'white' }}
          >
            <div>{title1}</div>
            <div>{title2}</div>
          </div>
        </div>

        {/* <div className={' my-4 lg:text-xl 4k:text-2xl'}>
          <div>
            A Pinch of Expertise <strong>by {author}</strong>
          </div>
        </div>

        <div className={' my-4 lg:text-[1.2rem] 4k:text-2xl'}>
          <div className='tracking-[0.025rem] 4k:text-lg font-bold'>
            Published on {date}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Expertise;
