import React from 'react';

const DiagonalRipple = () => {
  const DELAY = 2;

  return (
    <>
      <div
        className='ripple-container flex relative justify-center h-44 top-8 4k:top-1/4 transform rotate-45 left-1/4 4k:left-1/3'
        style={{ width: '45%' }}
      >
        <span className='small-ripple' style={{ '--i': DELAY + 2 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 4 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 6 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 8 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 10 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 12 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 14 }}></span>
        <span className='small-ripple' style={{ '--i': DELAY + 16 }}></span>
        <div className='vision-card-line absolute top-20 w-56 4k:w-96 4k:top-32 transform rotate-90'></div>
      </div>
    </>
  );
};

export default DiagonalRipple;
