import React from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';

const Body = ({ date, author, role }) => {
  return (
    <div className='text-black  lg:pb-24 '>
      <div className='w-11/12 m-auto mt-6 text-2xl font-bold lg:w-3/4  lg:ml-48 lg:mt-24 '>
        Published on {date}
      </div>
      <div>
        <div className='mt-6 lg:ml-48 lg:mt-0 lg:w-4/5  w-11/12 m-auto '>
          <div className='lg:text-3xl lg:mt-10  '>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ea in porro
            illum, sit est magnam praesentium, omnis sunt rerum blanditiis consequatur ut
            laborum voluptate placeat iure quidem inventore recusandae! Lorem ipsum dolor
            sit amet consectetur adipisicing elit. Saepe ea in porro illum, sit est magnam
            praesentium, omnis sunt rerum blanditiis consequatur ut laborum voluptate
            placeat iure quidem inventore recusandae!
          </div>
          <div className='lg:text-3xl lg:mt-10 '>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ea in porro
            illum, sit est magnam praesentium, omnis sunt rerum blanditiis consequatur ut
            laborum voluptate placeat iure quidem inventore recusandae! Lorem ipsum dolor
            sit amet consectetur adipisicing elit. Saepe ea in porro illum, sit est magnam
            praesentium, omnis sunt rerum blanditiis consequatur ut laborum voluptate
            placeat iure quidem inventore recusandae!
          </div>
        </div>
        <div className='lg:w-4/5 m-auto lg:mt-28 lg:ml-48 lg:h-[500px] bg-black '></div>
        <div className='mt-6 lg:ml-48 lg:mt-28 lg:w-4/6   w-11/12 m-auto '>
          <div className={smallHeader + 'text-xl text-dark-3'}>Author Quote's</div>
          <div className={'mt-7 text-3xl font-bold'}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum deserunt
            reprehenderit a voluptatibus eveniet commodi delectus, eos fugit, fugiat
            consequuntur repellendus vero architecto ea? Vero, nemo commodi? Voluptatibus,
            quas ex!
          </div>
        </div>
        <div className='w-11/12 m-auto mt-6 flex flex-col lg:w-3/4 lg:ml-48 lg:mt-24 '>
          <div className='w-16 h-16 lg:w-28 lg:h-28 rounded-full bg-[#eee] '></div>
          <div className='flex flex-col lg:mt-8  '>
            <p className=' text-[18px] lg:text-2xl'>by {author}</p>
            <p className=' text-[18px] lg:text-2xl'>{role}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
