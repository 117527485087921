import React from 'react';
import SliderButtons from '../../../landing-page/SliderButtons';
import { smallHeader, headingTextClass } from '../../../../helpers/constants';
import CardSlider from '../../../landing-page/CardSlider';

const Features = () => {
  const features = [
    {
      header: 'Real Time;Analytics',
      imgSrc: '/assets/images/flashcard/Real_Time_Analytics.png',
      fullImg: '/assets/images/flashcard/Artwork-01.png'
    },
    {
      header: 'Customised;Business Dashboard',
      imgSrc: '/assets/images/flashcard/Customised_Business_Dashboards.png',
      fullImg: '/assets/images/flashcard/Artwork-02.png'
    },
    {
      header: 'Multi Source;Data Visualisation',
      imgSrc: '/assets/images/flashcard/Multi_Source_Data_Visualisation.png',
      fullImg: '/assets/images/flashcard/Artwork-03.png'
    },
    {
      header: 'Online platform;Supported on all devices',
      imgSrc: '/assets/images/flashcard/Online_Platform_Supported_Devices.png',
      fullImg: '/assets/images/flashcard/Artwork-04.png'
    }
  ];

  const Card = ({ header, imgSrc, fullImg }) => {
    return (
      <div className='min-w-[22.5rem] lg:h-[26rem] relative '>
        <img
          className='bg-cover bg-no-repeat '
          src={fullImg}
          width='92%'
          height='100%'
          alt='block logo'
        />
        <div className='feature-header-container absolute bottom-[2.188rem] left-[2.188rem]'>
          <div className='mt-2 font-semibold text-xl tracking-[0.020rem] relative feature-header'>
            {header.split(';').map((header, index) => {
              return <div key={`header${index}`}>{header}</div>;
            })}
          </div>
          <div className='h-px bg-white feature-card-line'></div>
        </div>
      </div>
    );
  };

  return (
    <div className='bg-black text-white pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-10'>
      <div className={smallHeader + 'pt-20 lg:pt-28 text-light'}>features</div>
      <div className={headingTextClass + 'mt-7 '} style={{ color: 'white' }}>
        <div className='lg:hidden'>
          <div>Powerful Data &</div>
          <div>Ground-Breaking</div>
          <div>Insights in Real-Time</div>
        </div>
        <div className='hidden lg:block'>
          <div>Powerful data &</div>
          <div>Ground-breaking insights</div>
          <div>In Real-time</div>
        </div>
      </div>
      <div className='grid lg:flex lg:justify-between lg:items-end'>
        <div className='mt-8 text-sm'>
          <div className='hidden lg:block'>
            <div>
              As your active data partner, we help you navigate through a heap of
              voluminous data
            </div>
            <div>and come out with unique insights that fuel growth and prosperity.</div>
          </div>
          <div className='lg:hidden'>
            <div>As your active data partner, we help you navigate</div>
            <div>through a heap of voluminous data and come out with</div>
            <div>unique insights that fuel growth and prosperity.</div>
          </div>
        </div>
        <div className='hidden lg:block lg:pr-32 4k:pr-52'>
          <SliderButtons
            mainContainerClass=' flex items-end gap-5 4k:gap-7'
            buttonClass=' outline-none uppercase text-white border-2 border-white bg-transparent rounded-full hover:bg-white hover:text-black p-3 '
            svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
            svgContainerClass=' grid place-items-center '
            targetId='features-card'
          />
        </div>
      </div>
      <div className='mt-10'>
        <CardSlider targetId={'features-card'}>
          {features.map((feature, index) => (
            <Card
              key={`feature#${index}`}
              header={feature.header}
              imgSrc={feature.imgSrc}
              fullImg={feature.fullImg}
            />
          ))}
        </CardSlider>
      </div>
    </div>
  );
};

export default Features;
