import CategorySectionInsight from '../components/insights-page/CategorySection1';
import FeaturedSection from '../components/insights-page/FeaturedSection';
import FollowusSection from '../components/insights-page/followusSection';
import Section1 from '../components/insights-page/Section1';
import Solutionecexplore from '../components/insights-page/Solutionec-explore';
import SolutionecInsider from '../components/insights-page/SolutionecInsider';

const Insight = () => {
  return (
    <>
      <Section1 />
      <CategorySectionInsight />
      <FeaturedSection />
      <Solutionecexplore />
      <SolutionecInsider />
      <FollowusSection />
    </>
  );
};

export default Insight;
