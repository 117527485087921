import React from 'react';
import { smallHeader } from '../../../helpers/constants';

const AhaMoment = () => {
  return (
    <div className='relative sol-dark-scroll bg-cover bg-no-repeat h-[120vh]'>
      <img
        src='/assets/images/about-us/AHA_Moment_BG.png'
        className='w-full h-full object-cover object-center lg:object-[unset]'
        alt='progress'
      />
      <div className='text-white'>
        <div className='pl-6 lg:pl-32 xl:pl-48 absolute bottom-80 lg:bottom-64'>
          <div className={smallHeader + 'text-[orange]'}>THE AHA! MOMENT</div>
          <div className='text-[1.125rem]'>
            <div className='mt-6'>
              <div>The Aha! Moment is nothing but the sudden</div>
              <div>moment of clarity and breakthrough, where it</div>
              <div>all falls into place. When the right insights find you,</div>
              <div>everything begins to make more sense - your</div>
              <div>market and customer.</div>
            </div>
            <div className='mt-6'>
              <div>That is the Aha! Moment you should live for,</div>
              <div>and we can take you there.</div>
            </div>
          </div>
        </div>
        <div className='pl-6 lg:pl-32 xl:pl-48 absolute bottom-44 lg:bottom-32 xxl:bottom-[7rem]'>
          <div className='leading-[normal] tracking-[0.030rem]'>Mr. Rafeek Muhammed</div>
          <div className='text-[0.780rem] tracking-[0.030rem]'>
            Managing Partner, Solutionec
          </div>
        </div>
      </div>
    </div>
  );
};

export default AhaMoment;
