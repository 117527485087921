import React from 'react';
import { smallHeader } from '../../helpers/constants';

const Quote = () => {
  return (
    <div>
      <div className='w-1/2  ml-[26rem] my-48'>
        <div className={smallHeader + 'text-dark-3 '}>Quote </div>
        <div className=' mt-8 '>
          <div
            className={
              ' text-black w-full  text-4xl lg:text-[2.4rem] lg:leading-[3.2rem] 4k:text-7xl font-light'
            }
          >
            <div>This section used to inspire or motivate </div>
            <div>pishing the limits beyond something</div>
            <div>Do follow us on social media</div>
            <div>special@solutionec</div>
          </div>
        </div>

        <div className='mt-8 text-black  text-3xl'>Signature</div>
      </div>
    </div>
  );
};

export default Quote;
