import React from 'react';
import { social_links } from '../helpers/constants';

const SocialIcons = ({ size = 'sm', theme = 'light' }) => {
  const sizeChart = {
    sm: 'w-6 h-6',
    md: 'w-[2rem] h-[2rem]',
    lg: 'w-[2.5rem] h-[2.5rem]'
  };

  const links = [
    {
      id: 1,
      src: '/assets/images/social-icons/Linkedln_Icon.svg',
      className: sizeChart[size],
      alt: 'LinkedIn Icon',
      href: social_links.linkedin
    },
    // {
    //   id: 2,
    //   src: '/assets/images/social-icons/Twitter_Icon.svg',
    //   className: sizeChart[size],
    //   alt: 'Twitter Icon',
    //   href: '/'
    // },
    {
      id: 3,
      src: '/assets/images/social-icons/Facebook_Icon.svg',
      className: sizeChart[size],
      alt: 'Facebook Icon',
      href: social_links.facebook
    },
    {
      id: 4,
      src: '/assets/images/social-icons/Insagram_Icon.svg',
      className: sizeChart[size],
      alt: 'Instagram Icon',
      href: social_links.instagram
    }
    // {
    //   id: 5,
    //   src: '/assets/images/social-icons/Youtube_Icon.svg',
    //   className: sizeChart[size],
    //   alt: 'Youtube Icon',
    //   href: '/'
    // }
  ];

  return (
    <div className='flex gap-4 mt-4'>
      {links.map((link, index) => {
        return (
          <div key={`link#${index}`}>
            <a href={link.href} target={'_blank'} rel='noreferrer'>
              <img
                src={link.src}
                className={link.className}
                style={
                  theme === 'dark' ? { filter: 'invert(1)' } : { filter: 'invert(0)' }
                }
                alt={link.alt}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default SocialIcons;
