export const headingTextClass = `text-black text-[28px] lg:text-[2.813rem] lg:leading-[3.2rem] 4k:text-7xl font-light `;
export const smallHeader = ` xl:text-lg xl:tracking-[.063rem] xxl:text-xl 4k:text-3xl uppercase text-header-primary `;
export const subHeader = ` text-base 4k:text-4xl text-dark-1 `;
export const defaultButtonClasses = ` uppercase border-2 border-gray-400 rounded-full hover:bg-gray-200 py-2 px-4 lg:py-2 lg:px-6 4k:px-12 4k:py-5 text-sm lg:text-lg 4k:text-2xl w-max `;
export const darkThemePages = ['/products', '/services', '/join-us','/life'];
export const aboutUsBigText = ' text-lg lg:text-3xl font-light tracking-[0.020rem] ';
export const jobsShowLimit = 6; // default no of job openings to show in the job list page

export const flashcardVariables = {
  headingTextClass: ` text-4xl 4k:text-6xl font-light `,
  smallHeader: ` xl:text-sm xxl:text-xl 4k:text-2xl uppercase `
};

export const routes = {
  home: '/',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  products: '/products',
  services: '/services',
  joinUs: '/join-us',
  life:'/life',
  insights: '/insights',
  job_description: '/job-description',
  job_application: '/job-application',
  joblist: '/join-us/joblist',
  success_stories: '/success-stories',
  success_stories_inner: '/success-stories/blogs',
  blog_inner_page: '/blogs'
};

export const social_links = {
  facebook: 'https://www.facebook.com/info.solutionec/',
  twitter: '/',
  instagram: 'https://www.instagram.com/solutionec_stories/',
  linkedin: 'https://www.linkedin.com/company/solutionec/mycompany/'
};

// Dropdown options used in the job application form
export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'LGBTQ', label: 'LGBTQ' },
  { value: 'Do not wish to disclose', label: 'Do not wish to disclose' }
];

export const flagOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

export const educationTypeOptions = [
  { value: 'Full-Time', label: 'Full-Time' },
  { value: 'Part-Time', label: 'Part-Time' },
  { value: 'Distance', label: 'Distance' }
];

export const maritalOptions = [
  { value: 'Single', label: 'Single' },
  { value: 'Married', label: 'Married' },
  { value: 'Divorced', label: 'Divorced' }
  // { value: 'Widowed', label: 'Widowed' }
];

export const sourceOptions = [
  { value: 'Company Website', label: 'Company Website' },
  { value: 'Linkedin', label: 'Linkedin' },
  { value: 'Google', label: 'Google' },
  { value: 'Job Portal', label: 'Job Portal' },
  { value: 'Other', label: 'Other' }
];

export const jobPortalOptions = [
  { value: 'Glassdoor', label: 'Glassdoor' },
  { value: 'Instahyre', label: 'Instahyre' },
  { value: 'Naukri', label: 'Naukri' },
  { value: 'Indeed', label: 'Indeed' },
  { value: 'Timesjob', label: 'Timesjob' },
  { value: 'Monster', label: 'Monster' },
  { value: 'Zip recruiter', label: 'Zip recruiter' },
  { value: 'Simply Hired', label: 'Simply Hired' },
  { value: 'Career Builder', label: 'Career Builder' },
  { value: 'Other', label: 'Other' }
];

// Dropdown options used in the job list page
export const jobTypeOptions = [
  { value: 'Full Time', label: 'Full Time' },
  { value: 'Part Time', label: 'Part Time' },
  { value: 'Contract', label: 'Contract' },
  { value: 'Freelance', label: 'Freelance' }
];

export const locationOptions = [
  { value: 'Bengaluru, IN', label: 'Bangalore' },
  { value: 'Paris, FR', label: 'Paris' }
];
