import React from 'react';
import FlashCard from '../components/products-page/flashcard/FlashCard';

const Products = () => {
  return (
    <>
      <FlashCard />
    </>
  );
};

export default Products;
