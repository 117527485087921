import React, { useState } from 'react';
import { smallHeader, headingTextClass } from '../../helpers/constants';
import SliderButtons from '../landing-page/SliderButtons';
import CardSlider from '../landing-page/CardSlider';
import Modal from '../Modal';

const Digital = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const Template = ({ header, description = null }) => {
    return (
      <div>
        <div className={headingTextClass + 'uppercase font-semibold'}>{header}</div>
        <div className='text-[#6e6e73] text-[17px] font-[400]'>
          {/* {description.length
            ? description.map((d, i) => {
                return <div key={`description#${i}`}>{d}</div>;
              })
            : null} */}
          {description}
        </div>
      </div>
    );
  };

  const bigData = (
    <Template
      header='big data'
      description={
        <div>
          Our stalwart and innovative Big Data Analytics Services manage storage,
          transaction, and analysis in a planned and controlled manner to enhance it
          further for real-time analysis, visualization, and forecast. Big Data Analytics
          often brings in several business benefits such as more effective marketing,
          reduced risk, new revenue opportunities, improved customer service, and improved
          operational efficiency.
        </div>
      }
    />
  );

  const CLOUD = (
    <Template
      header='cloud'
      description={
        <div>
          <div>
            While thriving in a dynamic world, companies are constantly redefining their
            business models by personalizing their products and services. In such
            scenarios, cloud computing becomes an ideal way to deliver enterprise solution
            for companies by not only extending their infrastructure but also bringing in
            radical innovations.
          </div>
          <div>
            With Solutionec's Cloud services, you can drive application, modernization,
            and increased developer engagement by harnessing the speed and overall
            flexibility. We lead you to gain a competitive edge by seamlessly interweaving
            information, analytics, and technology - all on the cloud!
          </div>
        </div>
      }
    />
  );

  const AI = (
    <Template
      header='artificial intelligence'
      description={
        <div>
          <div>
            Our artificial intelligence algorithms are designed to make decisions, using
            real-time data. By leveraging these technologies, computers can be trained to
            accomplish specific tasks by processing large amounts of data and recognizing
            patterns in the data.
          </div>
          <ul className='list-disc list-inside'>
            <li>
              Exploring patterns to draw insights from data to make better decisions
            </li>
            <li>Data harmonization with the power of NLP</li>
            <li>Information extraction through the power of deep learning techniques</li>
          </ul>
          <div>
            Unleash the power of AI by transforming your entire business—not just in small
            pockets, but at scale
          </div>
        </div>
      }
    />
  );

  const ML = (
    <Template
      header='machine learning'
      description={
        <div>
          <ul className='list-disc list-inside'>
            <li>Understanding customer behaviors through digital platforms</li>
            <li>
              Enable operational efficiencies that provide the flexibility to support
              changing business requirements and priorities, improving your
              responsiveness, agility, and speed to value.
            </li>
            <li>Data-driven analytics to redefine business metrics that really matter</li>
            <li>Segregating 5-10% of the data that drives 90% of the business value.</li>
          </ul>
        </div>
      }
    />
  );

  const RPA = (
    <Template
      header='rpa'
      description={
        <div>
          <ul className='list-disc list-inside'>
            <li>Understanding customer behaviors through digital platforms</li>
            <li>
              Enable operational efficiencies that provide the flexibility to support
              changing business requirements and priorities, improving your
              responsiveness, agility, and speed to value.
            </li>
            <li>Data-driven analytics to redefine business metrics that really matter</li>
            <li>Segregating 5-10% of the data that drives 90% of the business value.</li>
          </ul>
        </div>
      }
    />
  );

  const config = {
    1: bigData,
    2: CLOUD,
    3: AI,
    4: ML,
    5: RPA
  };

  const Card = ({ header, desc, fullImg, id }) => {
    return (
      <div className='min-w-[22.5rem] lg:h-[26rem] relative'>
        <img
          className='bg-cover bg-no-repeat w-full h-full'
          src={fullImg}
          width='22.5rem'
          height='26rem'
          alt='block logo'
        />
        <div className='mt-2 font-bold absolute top-[2.3rem] left-[2.4rem] uppercase'>
          {header}
        </div>
        <div className='absolute bottom-[2.5rem] left-[2.4rem] flex items-center justify-between w-[calc(100%-3.7rem)]'>
          <div>
            {desc.map((d, i) => {
              return <div key={`desc#${i}`}>{d}</div>;
            })}
          </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              setShowModal(true);
              setSelectedCard(id);
            }}
            title='Click to know more'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-14 w-14'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth={1}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  const slide1 = (
    <Card
      id='1'
      header={'big data'}
      desc={['Big Data Analytics often brings', 'in several business benefits.']}
      fullImg={'/assets/images/services-page/Artwork-01.png'}
    />
  );

  const slide2 = (
    <Card
      id='2'
      header={'cloud'}
      desc={[
        'Cloud computing becomes an ideal  ',
        'way to deliver enterprise solutions.'
      ]}
      fullImg={'/assets/images/services-page/Artwork-02.png'}
    />
  );

  const slide3 = (
    <Card
      id='3'
      header={'artifcial intelligence'}
      desc={[
        'Information extraction through the ',
        'power of deep learning techniques. '
      ]}
      fullImg={'/assets/images/services-page/Artwork-03.png'}
    />
  );

  const slide4 = (
    <Card
      id='4'
      header={'machine learning'}
      desc={['Segregating 5-10% of the data that ', ' drives 90% of the business value.']}
      fullImg={'/assets/images/services-page/Artwork-04.png'}
    />
  );

  const slide5 = (
    <Card
      id='5'
      header={'rpa'}
      desc={[
        'Data-driven analytics to redefine ',
        'business metrics that really matter.'
      ]}
      fullImg={'/assets/images/services-page/Artwork-05.png'}
    />
  );

  const slides = [slide1, slide2, slide3, slide4, slide5];

  return (
    <div className='pt-40 bg-black text-white pl-6 lg:pl-32 xl:pl-48 4k:pl-72'>
      <Modal
        title={'news'}
        show={showModal}
        theme={'light'}
        styles={{
          modalMainStyles: {
            // width: '51.04rem',
            // height: '45.875rem'
            width: '45%',
            height: '75%'
          }
        }}
        closeAction={() => {
          setShowModal(false);
        }}
      >
        {config[selectedCard]}
      </Modal>
      <div className={smallHeader + 'text-yellow-500'}>digital</div>
      <div className='grid lg:flex lg:justify-between lg:items-end'>
        <div className={headingTextClass + 'mt-7'} style={{ color: 'white' }}>
          <div>We are a trusted partner</div>
          <div>To many around the globe</div>
        </div>
        <div className='hidden lg:block lg:pr-32 4k:pr-52'>
          <SliderButtons
            mainContainerClass=' flex items-end gap-5 4k:gap-7'
            buttonClass=' outline-none uppercase text-white border-2 border-white bg-transparent rounded-full hover:bg-white hover:text-black p-3 '
            svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
            svgContainerClass=' grid place-items-center '
            targetId='digital-card'
          />
        </div>
      </div>
      <div className='mt-10'>
        <CardSlider targetId={'digital-card'}>{slides}</CardSlider>
      </div>
    </div>
  );
};

export default Digital;
