import React from 'react';
import { smallHeader, headingTextClass, subHeader } from '../../helpers/constants';
const LifeLandingPage = () => {
	return (
		<>
			<div>


				<div
					className='w-full h-[215vh] items-center relative bg-cover bg-no-repeat'
					style={{
						backgroundImage:
							'url("/assets/images/life-solutionec/Life_Header.jpg")'
					}}
				>

					<div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-80 lg:pb-80 absolute bottom-0 left-0 text-light'>
						<span className={smallHeader + 'text-light'}>Insider</span>
						<div className={'mt-7' + headingTextClass}>
							<div>

								<div>Everything</div>
								<div>You Need To Know</div>
							</div>
						</div>
					</div>
					<div className='pl-6 ml-48 lg:pl-32 xl:pl-48 4k:pl-72 pb-64 lg:pb-64 w-4/5 mx- my-4 border-t-2 border-gray-400 h-px absolute bottom-0 left-0'></div>

					<div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72 pb-4 lg:pb-32 text-xl lg:text-lg text-white font-light absolute bottom-0 left-0'>
						<div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Porro quisquam cupiditate, omnis quae hic </div>
						<div> iste vero error velit doloribus molestiae sequi, est eaque neque et fuga,</div>
						<div> voluptatum quaerat! Ad, beatae!</div>
					</div>

				</div>
			</div>
			

		</>
	);
}

export default LifeLandingPage;