import React from 'react';
import { smallHeader, headingTextClass, subHeader } from '../../../helpers/constants';
// import ComingUp from './sections/ComingUp';
import Compatibility from './sections/Compatibility';
import Solutions from './sections/Solutions';
import Feedback from './sections/Feedback';
import FlashStats from './sections/FlashStats';
// import OurClients from './sections/OurClients';
import Features from './sections/Features';
// import ProductGrowth from './sections/ProductGrowth';
// import QnA from './sections/QnA';
// import Video from './sections/Video';

const FlashCard = () => {
  return (
    <>
      <div>
        <div className='relative h-[39vh] xl:h-[160vh] xxl:h-[170vh] sol-dark-scroll'>
          <img
            className='bg-cover bg-no-repeat absolute w-full h-full'
            src='/assets/images/flashcard/Main_Header_Artwork_OPT.jpg'
            alt=''
          />
        </div>
        <div className='sol-dark-scroll'>
          <div className='pt-10 bg-black text-white pl-6 lg:pl-32 xl:pl-48 4k:pl-72 sol-dark-scroll'>
            <div className={smallHeader + 'text-light'}>flashcard</div>
            <div className={headingTextClass + 'mt-7'} style={{ color: 'white' }}>
              <div className='lg:hidden'>
                <div>With great insights,</div>
                <div>comes greater</div>
                <div>decision-making</div>
                <div>and that is the</div>
                <div>beginning of</div>
                <div>every Innovation.</div>
              </div>
              <div className='hidden lg:block'>
                <div>With great insights,</div>
                <div>comes greater decision-making and</div>
                <div>that is the beginning of every innovation.</div>
              </div>
            </div>
            <div className='mt-4'>
              <div className='lg:hidden  text-sm'>
                <div>Our in-house fully integrated data visualisation tool</div>
                <div>Flashcard– is a next generation BI solution that</div>
                <div>leverages state-of-the-art business intelligence</div>
                <div>and performance management solution on a</div>
                <div>scalable and flexible platform.</div>
              </div>
              <div className={subHeader + 'text-xl gap-24 text-light hidden lg:block'}>
                <div>
                  Our in-house fully integrated data visualization tool Flashcard is a
                  next generation BI solution
                </div>
                <div>
                  that leverages state-of-the-art business intelligence and performance
                  management solution
                </div>
                <div>on a scalable and ﬂexible platform.</div>
              </div>
            </div>
            <div className='mt-4 text-sm'>
              <div className='lg:hidden'>
                <div>It comes with eye-catching KPI presentations,</div>
                <div>easy visualisation of key business trends, patterns</div>
                <div>and analysis. With it’s smart visualisation features</div>
                <div>and all-inclusive single performance management</div>
                <div>measurement system, Flashcard seamlessly</div>
                <div>converts big data to smart data and helps you</div>
                <div>attain actionable insights, thereby, make smarter</div>
                <div>decisions in a split-second.</div>
              </div>
              <div className={subHeader + 'text-xl gap-24 text-light hidden lg:block'}>
                <div>
                  It comes with eye-catching KPI presentations, easy visualization of key
                  business trends, patterns
                </div>
                <div>
                  and analysis. With it’s smart visualization features and all-inclusive
                  single performance management
                </div>
                <div>
                  measurement system, Flashcard seamlessly converts big data to smart data
                  and helps you attain
                </div>
                <div>
                  actionable insights, thereby, make smarter decisions in a split-second.
                </div>
              </div>
            </div>
            <FlashStats />
          </div>
          <Solutions />
          {/* <Video /> */}
          <Features />
          {/* <ProductGrowth /> */}
          {/* <ComingUp /> */}
          {/* <OurClients /> */}
          <Feedback />
          <Compatibility />
          {/* <QnA /> */}
        </div>
      </div>
    </>
  );
};

export default FlashCard;
