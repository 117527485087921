import React from 'react';
import { aboutUsBigText, smallHeader } from '../../../helpers/constants';
import ImageSlider from '../../landing-page/ImageSlider';

const OurPeople = () => {
  const groupPhotos = [
    '/assets/images/about-us/Group_Picture_1.jpg',
    '/assets/images/about-us/Our_Impact_OPT.jpg',
    '/assets/images/about-us/Our_Impact_OPT.jpg',
    '/assets/images/about-us/Our_Impact_OPT.jpg'
  ];

  return (
    <div className='py-24 pl-6 lg:pl-32 xl:pl-48'>
      <div className={smallHeader + 'text-dark-3'}>our people</div>
      <div className={smallHeader + 'mt-10 text-dark-3 normal-case'}>
        <div className={`${aboutUsBigText} lg:text-[1.563rem] lg:leading-[1.875rem]`}>
          <div className='lg:hidden'>
            <div>Meet the visionaries spearheading</div>
            <div>our teams and clients globally.</div>
            <div>Empowering people, innovations,</div>
            <div>and ideas with the right mix of</div>
            <div>leadership and mentoring.</div>
          </div>
          <div className='hidden lg:block'>
            <div>Meet the visionaries spearheading our teams and clients globally.</div>
            <div>Empowering people, innovations, and ideas with the right mix</div>
            <div>of leadership and mentoring.</div>
          </div>
        </div>
        <div className='mt-10'>
          <div>
            <img
              className='w-[96%] lg:w-[56.25rem] lg:h-[35rem]'
              src={groupPhotos[0]}
              alt='solutionec'
            />
          </div>
          {/* <ImageSlider
            slides={groupPhotos.map((photo, index) => {
              return (
                <div key={`photo#${index}`}>
                  <img
                    className='bg-no-repeat bg-cover w-[96%] lg:w-[56.25rem] lg:h-[30rem]'
                    src={photo}
                    alt='solutionec'
                  />
                  <div className='flex gap-4 text-xs w-10 mt-2'>
                    <div>Photo credits: person</div>
                    <div>Name of the photo: group photo</div>
                    <div>Description: group photo</div>
                    <div>Place: Solutionec Bangalore</div>
                  </div>
                </div>
              );
            })}
            dotPosition='relative flex items-center justify-center'
            indicatorType='hollow'
            theme='dark'
            autoPlay={false}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default OurPeople;
