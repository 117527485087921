import React, { useState, useEffect } from 'react';
import { smallHeader, headingTextClass, subHeader } from '../../helpers/constants';
import Modal from '../Modal';

const ConsultingManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const Template = ({ header, description = null }) => {
    return (
      <div>
        {/* <div className={headingTextClass + 'uppercase font-semibold'}>{header}</div> */}
        <div className='text-[#6e6e73] text-[15px] m-auto font-[400] mt-2 w-[85%] '>
          {/* {description.length
            ? description.map((d, i) => {
                return <div key={`description#${i}`}>{d}</div>;
              })
            : null} */}
          {description}
        </div>
      </div>
    );
  };

  const marketingAnalytics = (
    <Template
      // header='Marketing Analytics'
      description={
        <div className='mb-8'>
          <img
            src='/assets/images/services-page/pop-up/Insider_Icon_Marketing_Analytics.png'
            alt=''
          />
          <p className='font-bold text-black text-3xl py-4'>Marketing Analytics</p>
          <div>
            Market data analysis can be a very resourceful unit in order to keep a track
            of the product sales performance. It is the ultimate for any industries. With
            our salesforce analysts and their profound market understanding, you can now
            cater to the needs of your customers by providing effective and efficient
            performance analysis. Not only will this help you enhance your sales team's
            capabilities but also improve their performance by leaps and bounds.
          </div>
          <div className='mt-4 text-yellow-500'>
            <span>Sales Analytics</span>
            <br />
            <span>Longitudinal Rx / Patient Journey</span> <br />
            <span>Share of voice</span> <br />
            <span>Customer Usage & Awareness</span> <br />
            <span>Market Access / Formulary inclusion</span> <br />
            <span>Sales Analytics</span>
          </div>
          {/* <ul className='list-disc list-inside '>
            <li>Sales Analytics</li>
            <li>Longitudinal Rx / Patient Journey</li>
            <li>Share of voice</li>
            <li>Customer Usage & Awareness</li>
            <li>Market Access / Formulary inclusion</li>
            <li>Supply chain analytics (Stock, SISO)</li>
          </ul> */}
        </div>
      }
    />
  );

  const dataManagement = (
    <Template
      // header='Data Management'
      description={
        <div className='mb-8'>
          <img
            src='/assets/images/services-page/pop-up/Insider_Icon_Data_Management.png'
            alt=''
          />
          <p className='font-bold text-black text-3xl py-4'>Data Management</p>
          <div>
            Our data management services help businesses understand their internal and
            external painpoints and the ways to resolve them. We have data capability in
            all the stages of data lifecycle beginning from data integration , data
            governance , unstructured data management , data architecture and design ,
            data ware housing , database administration and data warehousing and business
            intelligence. This provides an organization a wider view into their process
            with profound perspective.
          </div>
        </div>
      }
    />
  );

  const competitiveIntelligence = (
    <Template
      // header='Competitive Intelligence'
      description={
        <div className='mb-8'>
          <img
            src='/assets/images/services-page/pop-up/Insider_Icon_Comeptitive_Intelligence.png'
            alt=''
          />
          <p className='font-bold text-black text-3xl py-4'>Competitive Intelligence</p>
          <div>
            Competitive intelligence is market and competitor intelligence combined
            together. A good quality provider should always provide competitor
            intelligence as part of services. Our team assists customers in their critical
            decision making, assessing the combined factors of the market, product
            performance and competition
          </div>
          <div className='mt-4 text-yellow-500'>
            <span>Therapeutic Area Assessment</span>
            <br />
            <span>Pipeline Assessment</span> <br />
            <span>Business Development Support</span> <br />
            <span>Geographic and Therapeutic Optimization</span> <br />
          </div>
          {/* <ul className='list-disc list-inside'>
            <li>Therapeutic Area Assessment</li>
            <li>Pipeline Assessment</li>
            <li>Business Development Support</li>
            <li>Geographic and Therapeutic Optimization</li>
          </ul> */}
        </div>
      }
    />
  );

  const FFE = (
    <Template
      header='Field Force Effectiveness'
      description={
        <div className='mb-8'>
          <img
            src='/assets/images/services-page/pop-up/Insider_Icon_Field_Force_Effectivness.png'
            alt=''
          />
          <p className='font-bold text-black text-3xl py-4'>Field Force Effectiveness</p>
          <div>
            Field force effectiveness: Solutionec caters to various aspects of field force
            reporting such as segmentation of customers, incentive planning, design and
            calculation and call planning. Our focus remains on designing solutions that
            provide a 360-view of the business while keeping in mind the following aspects
            - - Managing the entire visit: Number of calls to customers, product
            positioning, frequency of visits to high valued customers, targeted vs non
            targeted customers Generating Comprehensive Reports: They are present in the
            form of informational dashboards and specific KPI analysis graphs. Timely
            calculation of days in field, product message time, duration of calls Measure
            and improve productivity: Incentive design for plan (Sales Vs target,
            Commission, Market Share growth), Bonus , Awards
          </div>
        </div>
      }
    />
  );

  const multichannelEngagement = (
    <Template
      header='Multichannel Engagement'
      description={
        <div className='mb-8'>
          <img
            src='/assets/images/services-page/pop-up/Insider_Icon_Multi_Channel_Engagement.png'
            alt='image'
          />
          <p className='font-bold text-black text-3xl py-4'>Multichannel Engagement</p>
          <div>
            Our goal is simple - deliver the right message at the right time devising the
            right solution for our customer. We devise robust touchpoints by glancing and
            evaluating the myriad of channels being used by our customers and
            simultaneously form an interactive experience to create a more holistic
            experience. Our solutions are based on pharmaceutical sales , patient ,
            finance, distributor, CRM data to name a few and HCP 360 view is one of custom
            designed message for our customers.
          </div>
        </div>
      }
    />
  );

  const forecasting = (
    <Template
      header='Forecasting'
      description={
        <div className='mb-8'>
          <img
            src='/assets/images/services-page/pop-up/Insider_Icon_Forecasting.png'
            alt='image'
          />
          <p className='font-bold text-black text-3xl py-4'>Forecasting</p>
          <div>
            We have a greater understanding of forecast diversity, which ultimately helps
            us tailor our methodologies according to the customer needs. Our robust
            validations on the statistics and trend forecast, market evaluating factors,
            ensure high quality and reliable forecasting
          </div>
          <div className='mt-4 text-yellow-500'>
            <span>Patient-Based Model</span>
            <br />
            <span>Demand sales rolling Forecast</span> <br />
            <span>Collaborative Forecasting platform</span> <br />
            <span>Epidemiologic Study</span> <br />
            <span>Trend analysis</span>
            <br />
            <span>Risk Assessment</span>
          </div>
          {/* <ul className='list-disc list-inside'>
            <li>Patient-Based Model</li>
            <li>Demand sales rolling Forecast</li>
            <li>Collaborative Forecasting platform</li>
            <li>Epidemiologic Study</li>
            <li>Trend analysis</li>
            <li>Risk Assessment</li>
          </ul> */}
        </div>
      }
    />
  );

  const config = {
    1: marketingAnalytics,
    2: dataManagement,
    3: competitiveIntelligence,
    4: FFE,
    5: multichannelEngagement,
    6: forecasting
  };

  const Block = ({ imgSrc, contentHeader, contentSubHeader, id, width, height }) => {
    return (
      <div className='bg-black  text-light border-2 border-[#0b0b0b] px-12 w-[32rem] h-[32rem]  relative rounded-[60px] hover:border-[#383838]'>
        <div className='grid'>
          <div className='mt-12 '>
            <img
              className={`${width} ${height}` + 'bg-cover bg-no-repeat'}
              src={imgSrc}
              alt='block logo'
            />
          </div>
          <div className='absolute top-44'>
            <div className='text-3xl font-light mt-12'>{contentHeader}</div>
            <div className='text-base mt-4'>
              {contentSubHeader
                ? contentSubHeader.map((content, index) => (
                    <div key={`${contentHeader}#${index}`}>{content}</div>
                  ))
                : ''}
            </div>
          </div>
          {/* <div>
            <div className='h-px bg-yellow-500 w-1/4 mt-24'></div>
          </div> */}
        </div>
        <div className='absolute bottom-6 right-8'>
          <div
            className='cursor-pointer'
            onClick={() => {
              setShowModal(true);
              setSelectedCard(id);
              // document.body.style.overflow = 'hidden';
            }}
            title='Click to know more'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-20 w-20'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth={0.5}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            {/* three dots icon */}
            {/* <img src='/assets/images/services-page/view-more.png' alt='image' /> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        title={''}
        show={showModal}
        theme={'light'}
        styles={{
          modalMainStyles: {
            // width: '51.04rem',
            // height: '45.875rem'
            width: '46%',
            height: 'auto'
          }
        }}
        closeAction={() => {
          setShowModal(false);
        }}
      >
        {config[selectedCard]}
      </Modal>
      <div className='pt-32 bg-black text-white pl-6 lg:pl-32 xl:pl-48 4k:pl-72'>
        <div className={smallHeader + 'text-yellow-500'}>consulting management</div>
        <div className={headingTextClass + 'mt-7'} style={{ color: 'white' }}>
          <div>Step into a world</div>
          <div>of digitally-fueled solutions</div>
        </div>
        <div className={subHeader + 'mt-8 text-light'}>
          <div>
            In a digital-first world, nimble automation and grand user experience stand
            above all.
          </div>
          <div>
            Experience an array of such digital advisory services and achieve your
          </div>
          <div> short-term and long-term goals.</div>
        </div>
        <div className='grid xl:grid-cols-2 sm:grid-cols-1   gap-y-10 mt-24  w-[95%] '>
          <Block
            imgSrc={'/assets/images/services-page/Marketing_Analysis.png'}
            contentHeader={'Marketing Analytics'}
            contentSubHeader={[
              'Market data analysis can be very resourceful',
              'unit in order to keep a track on the product',
              'sales performance.'
            ]}
            id='1'
            width='w-24'
            height='h-24'
          />
          <Block
            imgSrc={'/assets/images/services-page/Data_Management.png'}
            contentHeader={'Data Management'}
            contentSubHeader={[
              'Our data management services help businesses ',
              'understand their internal and external painpoints',
              'and the ways to resolve them.'
            ]}
            id='2'
            width='w-28'
            height='h-24'
          />
          <Block
            imgSrc={'/assets/images/services-page/Competitive_Intelligence.png'}
            contentHeader={'Competitive Intelligence'}
            contentSubHeader={[
              'Competitive intelligence is Market intelligence +',
              'Competitor intelligence. A good quality provider',
              'should always provide competitor intelligence as',
              'part of services.'
            ]}
            id='3'
            width='w-28'
            height='h-15'
          />
          <Block
            imgSrc={'/assets/images/services-page/Field_Force_Effectiveness.png'}
            contentHeader={'Field Force Effectiveness'}
            contentSubHeader={[
              'Solutionec caters to various aspects of field force reporting',
              'such as segmentation of customers, incentive planning,',
              'design and calculation and call planning.'
            ]}
            id='4'
            width='w-28'
            height='h-15'
          />
          <Block
            imgSrc={'/assets/images/services-page/Multi_Channel.png'}
            contentHeader={'Multichannel Engagement'}
            contentSubHeader={[
              'Our solutions are based on pharmaceutical sales , patient , ',
              'finance,distributor, CRM data to name a few and HCP 360 ',
              'view is one of custom designed message for our customers.'
            ]}
            id='5'
            width='w-24'
            height='h-24'
          />
          <Block
            imgSrc={'/assets/images/services-page/Forecasting.png'}
            contentHeader={'Forecasting'}
            contentSubHeader={[
              'We at Solutionec understand Forecast diversity and',
              'this realization helps us tailor the methodologies ',
              'according to the customer needs.'
            ]}
            id='6'
            width='w-24'
            height='h-24'
          />
        </div>
      </div>
    </>
  );
};

export default ConsultingManagement;
