import React from 'react';
import '../../styles/slider.css';

const Blogs = ({ cards }) => (
  <div className='slider-container ml-auto lg:ml-0 pb-20'>
    <div id='blogs-card' className='cards-container mt-10 4k:mt-28'>
      {cards.map((card, index) => (
        <div key={`blog#${index}`} className={`${index === 0 ? '' : 'ml-6 xl:ml-10'}`}>
          <div
            className='w-72 h-64 lg:w-80 lg:h-64 xxl:w-96 xxl:h-80 4k:w-blog-img csr-card-image 4k:h-blog-img bg-cover bg-center bg-no-repeat'
            style={{ backgroundImage: `url("${card.imgSrc}")` }}
          ></div>
        </div>
      ))}
    </div>
  </div>
);

const Visions = ({ cards }) => (
  <div className='slider-container ml-auto xl:ml-0'>
    <div id='visions-card' className='cards-container mt-8 lg:mt-2 xl:mt-12'>
      {cards.map((card, index) => (
        <div
          key={`vision#${index}`}
          className={`text-black p-6 4k:p-10 4k:pb-16 workspace-card-bg 4k:grid ${
            index === 0 ? '' : 'ml-6 4k:ml-10'
          }`}
        >
          <div className='4k:flex 4k:flex-col 4k:justify-end pt-40'>
            <div className='font-semibold text-xl 4k:text-4xl pt-40'>{card.header}</div>
            {/* change text-xs to text-base later */}
            <div className='mt-3 text-xs 4k:text-2xl vision-card-font'>
              {card.description?.map((description, index) => {
                return <div key={`description#${index}`}>{description}</div>;
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const Other = ({ cards, targetId = 'cards' }) => (
  <div className='slider-container'>
    <div id={targetId.trim()} className='cards-container gap-6'>
      {cards}
    </div>
  </div>
);

const CardSlider = ({ source, children, targetId }) => {
  const blogs = [
    {
      id: 1,
      imgSrc: '/assets/images/career/CSR.jpg'
    },
    {
      id: 2,
      imgSrc: '/assets/images/career/CSR.jpg'
    },
    {
      id: 3,
      imgSrc: '/assets/images/career/CSR.jpg'
    },
    {
      id: 4,
      imgSrc: '/assets/images/career/CSR.jpg'
    },
    {
      id: 5,
      imgSrc: '/assets/images/career/CSR.jpg'
    },
    {
      id: 6,
      imgSrc: '/assets/images/career/CSR.jpg'
    },
    {
      id: 7,
      imgSrc: '/assets/images/career/CSR.jpg'
    },
    {
      id: 8,
      imgSrc: '/assets/images/career/CSR.jpg'
    }
  ];

  const visions = [
    {
      id: 1,
      imageUrl: '/assets/images/career/Video-01.png',
      header: 'Workspace',
      description: ['Loreum ipsum dolor sit amet, consectetuer']
    },
    {
      id: 2,
      header: 'Business Meet',
      description: ['Loreum ipsum dolor sit amet, consectetuer']
    },
    {
      id: 3,
      header: 'Workspace',
      description: ['Loreum ipsum dolor sit amet, consectetuer']
    },
    {
      id: 4,
      header: 'Business Meet',
      description: ['Loreum ipsum dolor sit amet, consectetuer']
    }
  ];

  const renderComponent = {
    blogs: <Blogs cards={blogs} />,
    visions: <Visions cards={visions} />
  };

  return renderComponent[source] || <Other cards={children} targetId={targetId} />;
};

export default CardSlider;
