import React from 'react';
import Feedback from '../components/products-page/flashcard/sections/Feedback';
// import QnA from '../components/products-page/flashcard/sections/QnA';
import ConsultingManagement from '../components/service-page/ConsultingManagement';
import Digital from '../components/service-page/Digital';
import Section1 from '../components/service-page/Section1';
// import ServicesOurClients from '../components/service-page/ServicesOurClients';

const Services = () => {
  return (
    <>
      <div className='relative h-[200vh] sol-dark-scroll'>
        <img
          className='bg-cover bg-no-repeat absolute w-full h-full'
          src='/assets/images/services-page/Service_Header_Artwork_x72_Opt.jpg'
          alt=''
        />
      </div>
      <div className='sol-dark-scroll'>
        <Section1 />
        <ConsultingManagement />
        <Digital />
        {/* <ServicesOurClients /> */}
        <Feedback cardColor='text-[#ffba07]' />
        {/* <QnA /> */}
      </div>
    </>
  );
};

export default Services;
