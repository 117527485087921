import React from 'react';
import { headingTextClass, smallHeader, subHeader } from '../../../helpers/constants';
import Button from '../../Button';

const Unbox = () => {
  return (
    <div className='pl-6 lg:pl-60 xl:pl-96 4k:pl-left-space mt-24 lg:mt-48 xl:mt-60 '>
      <div className={smallHeader}>unbox</div>
      <div className={headingTextClass + 'mt-6 xl:mt-7'}>
        <div className='hidden lg:block'>
          <div>Our Leading Edge</div>
          <div>Products to Experience</div>
        </div>
        <div className='block lg:hidden'>
          <div>Our Leading</div>
          <div>Edge Products</div>
          <div>To Experience</div>
        </div>
      </div>
      <div className={subHeader + 'mt-4'}>
        <div>When products are simpler, results are comprehensive.</div>
      </div>
      <div className='mt-10 xl:my-12'>
        <Button
          title={'explore'}
          primaryColor='border-black'
          secondaryColor='hover:bg-black hover:text-white'
        />
      </div>
      <div className='mt-16 grid place-items-end w-[97.5%]'>
        <img
          className='object-cover w-full'
          src='/assets/images/landing-page/Unboxing_Optimised.jpg'
          alt='light coming from different directions. signifies different view points'
        />
      </div>
    </div>
  );
};

export default Unbox;
