import Section1 from '../components/Join-Us/Section1';
import JobOpening from '../components/Join-Us/jobopening-section';
import PeopleWorkspace from '../components/Join-Us/people-oriented-workspace-section';
import TestiMonial from '../components/Join-Us/TestiMonial';
import CSR from '../components/Join-Us/CSR';
import MoreDiversity from '../components/Join-Us/moreDiversity';
import FlipCard from '../components/Join-Us/flip-card-section';

const CareerPage = () => {
  return (
    <>
      <div className='sol-dark-scroll'>
        <Section1 />
        <JobOpening />
        <PeopleWorkspace />
        <MoreDiversity />
        {/* <FlipCard /> */}
        <TestiMonial />
        {/* <CSR /> */}
      </div>
    </>
  );
};

export default CareerPage;
