import React from 'react';
import { headingTextClass, smallHeader, subHeader } from '../../helpers/constants';

const Section1 = () => {
  return (
    <div className='bg-black text-white pl-6 lg:pl-32 xl:pl-48 4k:pl-72'>
      <div className={smallHeader + 'text-yellow-500'}>services</div>
      {/* <div className={headingTextClass + 'mt-7'} style={{ color: 'white' }}>
        <div>Unearth the complexities</div>
        <div>of data silos with our expertise</div>
      </div> */}
      {/* <div className={subHeader + 'mt-8 text-light'}>
        <div>
          We deploy our expertise to help you navigate through convoluted hays of data
        </div>
        <div>and make innovation an everyday achievement.</div>
      </div> */}
      <div className={headingTextClass + 'mt-7'} style={{ color: 'white' }}>
        <div>When you join hands with Solutionec, you get</div>
        <div>The best of cross-functional expertise</div>
        <div>To cater to your needs across multiple verticals</div>
        <div>Involving Sales data analysis, Forecasting, Market</div>
        <div>Assessment and Competitive intelligence,</div>
        <div>Among many more.</div>
      </div>
      <div className='h-px w-11/12 bg-gray-100 my-6'></div>
      <div className={subHeader + 'text-xl flex gap-24 text-light'}>
        <div>
          <div>While consumers are varied</div>
          <div>in the healthcare domain,</div>
          <div>so are their demands.</div>
          <div>With the ever-evolving market,</div>
          <div>if you want to win and retain</div>
          <div>new customers while also</div>
          <div>maintaining a healthy</div>
          <div>operational cost you need</div>
          <div>to dive deeper into the funnels.</div>
        </div>
        <div>
          <div>As daunting as this sounds,</div>
          <div>well-structured data,</div>
          <div>meaningful insights, and</div>
          <div>expert opinion can make</div>
          <div>it a cake-walk.</div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
