import React, { useEffect, useRef } from 'react';
import { useOutsideAlerter } from '../helpers/hooks/useOutsideClickAlerter';

const Modal = ({
  show,
  title,
  children,
  styles,
  closeAction,
  closeWhenCLickedOutside = true,
  theme = 'light'
}) => {
  const wrapperRef = useRef(null);
  const themeConfig = {
    light: 'bg-white text-black',
    dark: 'bg-[#2d2c2c] text-white'
  };

  const callClose = () => {
    if (typeof closeAction === 'function') {
      closeAction();
    }
    document.body.style.overflow = 'unset';
  };

  useOutsideAlerter(
    closeWhenCLickedOutside
      ? { ref: wrapperRef, outsideAction: callClose }
      : { ref: null, outsideAction: null }
  );

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';

      // document.body.classList.add('bloor');
    } else {
      document.body.style.overflow = 'unset';
      // document.body.classList.remove('bloor');
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        callClose();
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='modal  ' style={show ? { display: 'block' } : { display: 'none' }}>
        <div
          className={`${themeConfig[theme]}  modal-main `}
          style={styles.modalMainStyles}
          ref={closeWhenCLickedOutside ? wrapperRef : null}
        >
          <div className='modal-header split-header'>
            {/* <span>{title}</span> */}
            <img
              src='/assets/images/services-page/pop-up/Close.png'
              className='cursor-pointer'
              onClick={callClose}
              width='30px'
              height='30px'
              alt='Close Icon'
            />

            {/* <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 cursor-pointer'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth={2}
              onClick={callClose}
              title='Close'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg> */}
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
