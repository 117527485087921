import React from 'react';

const NewsIcon = ({ theme = 'light' }) => {
  const path = window.location.pathname;

  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={`h-10 w-10 4k:h-20 4k:w-20 ${
          path === '/products'
            ? 'text-white'
            : theme === 'dark'
            ? 'text-white'
            : 'text-black'
        }`}
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z'
        />
      </svg>
    </div>
  );
};

export default NewsIcon;
