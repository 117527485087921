import React from 'react';
import Section1 from './sections/Section1';
import CertificatesDetailsForm from './sections/CertificatesDetailsForm';

const CertificateDetails = ({ setStatus }) => {
  return (
    <>
      <Section1
        subTitle='step 5'
        title='Certificates'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
      />
      <CertificatesDetailsForm setStatus={setStatus} />
    </>
  );
};

export default CertificateDetails;
