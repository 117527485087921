import React, { useState } from 'react';
import { headingTextClass, smallHeader } from '../../helpers/constants';
import { getCurrentPageTheme } from '../../helpers/util';
//import SocialIcons from './SocialIcons';
import SocialIcons from '../SocialIcons';
import { routes } from '../../helpers/constants';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';

const SolutionecInsider = () => {
  const darkTheme = getCurrentPageTheme() === 'dark';
  const [copied, setCopied] = useState(false);
  const [counter, setCounter] = useState(3);
  const copy = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  const handleCounter = (x) => {
    setCounter(counter + x);
  };

  const blogs = [
    {
      id: 1,
      imgSrc: '/assets/images/landing-page/Pandemic_Leaders.png',
      date: 'FEB 21, 2022',
      title: ['The Post', 'Pandemic Leaders'],
      description:
        'In the post-pandemic world, although your leaders might be the same, their style of leadership has unquestionably taken a 360-degree turn. The onset of Covid-19 changed the way the leadership community envisioned team operations at the workplace.'
    },
    {
      id: 2,
      imgSrc: '/assets/images/landing-page/Value_Driven_Workplace.png',
      date: 'APRIL 27, 2022',
      title: ['Value-Driven Workplace:', 'The New Shade of Corporate'],
      description:
        'Gone are the days when interviews were a one-sided desk show where the interviewer would inspect a candidate’s CV to decide their future in the company.'
    },
    {
      id: 3,
      imgSrc: '/assets/images/landing-page/Care.png',
      date: 'MAR 24, 2022',
      title: ['The New Age', 'of Palliative Care'],
      description:
        'The post-retirement period often comes with a bag of mixed feelings. For some, the anxiety of a completely new life takes over, whereas some are excited to explore every aspect of it. '
    },
    {
      id: 4,
      imgSrc: '/assets/images/landing-page/BI.png',
      date: 'JUNE 02, 2022',
      title: ['The Business Intelligence', 'Know-Hows of the Modern World'],
      description:
        'For longer than I can remember, the term “spreadsheets” has always brought a frenzy among many working in the field of data. '
    }
  ];
  return (
    <>
      <div>
        <div className='pl-6 lg:pl-32 xl:pl-48 pb-10 '>
          <div className='ml-36'>
            <div className={smallHeader + 'uppercase pt-40 pb-30 text-dark-3 '}>
              Solutionec Insider
            </div>
            <div className={headingTextClass + 'mt-6  '}>
              <div className='lg:block pt-5'>
                <div>Pinch of Expertise :</div>
                <div>Hear our Visionaries talk</div>
              </div>
            </div>
            <div className='mt-8 text-base lg:text-xl '>
              <div className='lg:block'>
                <div>Engage with the thought-provoking and industry-relevant pieces</div>
                <div>by our leaders shaping the minds of many.</div>
              </div>
            </div>
          </div>
          {blogs.map((card, index) =>
            index < counter ? (
              <div className='flex width-full-section  mt-14 ' key={index}>
                <div className='width-first-section '>
                  <a
                    href={`${routes.blog_inner_page}/${card.id}`}
                    className='flex flex-col items-center  md:flex-row'
                  >
                    <img
                      className='object-cover h-[435px] md:h-auto md:w-48 left-image-height-width'
                      src={card.imgSrc}
                      alt='insider card'
                    />
                  </a>
                </div>
                <div className='right-text-section ml-16 relative'>
                  <div className='flex flex-col justify-between p-2 leading-normal '>
                    <div className={'mt-12 mb-2'}>
                      <div className='lg:block  text-[30px] text-black'>
                        <div>{card.title[0]}</div>
                        <div>{card.title[1]}</div>
                      </div>
                    </div>
                    <p className=' font-normal text-gray-700 dark:text-gray-400 date-font-size'>
                      {card.date}
                    </p>

                    <div className='mt-4 w-full flex lg:w-2/5 h-10 justify-between  '>
                      <div>
                        <FacebookShareButton
                          url={`https://solutionec-web.netlify.app${window.location.pathname}`}
                        >
                          <div className=' w-full  h-full flex content-center items-center font-bold '>
                            <img
                              src='/assets/images/social-icons/Facebook_Icon.svg'
                              className='w-6 h-6 lg:w-7 lg:h-7'
                              alt='facebook'
                            />
                          </div>
                        </FacebookShareButton>
                      </div>
                      <div>
                        <LinkedinShareButton
                          title='Solutionec Carrers'
                          url={`https://solutionec-web.netlify.app${window.location.pathname}`}
                          hashtag={'#solutionec'}
                        >
                          <div className='flex content-center h-full items-center justify-between font-bold'>
                            <img
                              src='/assets/images/social-icons/Linkedln_Icon.svg'
                              className='w-6 h-6 lg:w-7 lg:h-7'
                              alt='linkedin'
                            />
                          </div>
                        </LinkedinShareButton>
                      </div>
                      <div>
                        <TwitterShareButton
                          url={`https://solutionec-web.netlify.app${window.location.pathname}`}
                        >
                          <div className='flex content-center h-full items-center justify-between font-bold'>
                            <img
                              src='/assets/images/social-icons/Twitter_Icon.svg'
                              className='w-6 h-6 lg:w-7 lg:h-7'
                              alt='twitter'
                            />
                          </div>
                        </TwitterShareButton>
                      </div>

                      <div>
                        <button onClick={copy} className='text-black font-bold'>
                          <div className='flex content-center h-full items-center justify-between'>
                            <img
                              src='/assets/images/social-icons/Copy.svg'
                              className='w-6 h-6 lg:w-7 lg:h-7'
                              alt='copy'
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className='mt-3 text-base lg:text-xl card-text-size'>
                      {card.description}
                    </div>
                    <div class='mt-6 absolute bottom-5 '>
                      <a
                        href={`${routes.blog_inner_page}/${card.id}`}
                        class='capitalize text-dark-3 4k:text-xl hover:text-header-primary select-none'
                      >
                        read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          )}
          {counter < blogs.length ? (
            <div class='mt-20 ml-12 explore-center  mb-40'>
              <button
                className='text-black hover:underline hover:text-blue-700'
                onClick={() => {
                  handleCounter(3);
                }}
              >
                Explore More
              </button>
            </div>
          ) : blogs.length > 3 ? (
            <div class='mt-20 ml-12 explore-center  mb-40'>
              <button
                className='text-black hover:underline hover:text-blue-700'
                onClick={() => {
                  setCounter(3);
                }}
              >
                Explore Less
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SolutionecInsider;
