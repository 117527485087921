import React from 'react';
import { aboutUsBigText, smallHeader } from '../../../helpers/constants';

const OurStory = () => {
  return (
    <div
      className='pl-6 lg:pl-32 xl:pl-48 pb-32 bg-cover bg-center relative h-screen lg:h-[50rem] xxl:h-screen'
      style={{ backgroundImage: 'url("/assets/images/about-us/Move_Forward_OPT.jpg")' }}
    >
      <div className='absolute bottom-12 lg:bottom-24'>
        <div className={smallHeader + 'pt-96 lg:pt-0 text-dark-3'}>our story</div>
        <div className={`${aboutUsBigText} mt-[1.563rem]`}>
          <div className='lg:hidden'>
            <div>At Solutionec, the goal is largely centred</div>
            <div>around human progress because we</div>
            <div>truly believe that data is at the heart of</div>
            <div>growth. They say, “Knowledge is Power,”</div>
            <div>and we agree, being informed goes a long</div>
            <div>way! With the correct insight by your side,</div>
            <div>we provide you the power to conquer it all</div>
            <div>by making well-informed and forward-</div>
            <div>looking decisions.</div>
          </div>
          <div className='hidden lg:block'>
            <div>At Solutionec, the goal is largely centred around human progress</div>
            <div>because we truly believe that data is at the heart of growth.</div>
            <div>They say, “Knowledge is Power,” and we agree, being informed</div>
            <div>goes a long way! With the correct insight by your side, we provide</div>
            <div>you the power to conquer it all by making well-informed and</div>
            <div>forward-looking decisions.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
