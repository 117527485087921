import React, { useState, useEffect } from 'react';
import { headingTextClass, smallHeader } from '../helpers/constants';
import axios from 'axios';
import { JobCard } from '../components/job-list/JobCard';
import { Link } from 'react-router-dom';
import { appConfig } from '../appConfig';
import { jobTypeOptions, locationOptions } from '../helpers/constants';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

const JobList = () => {
  const [jobOpenings, setjobOpenings] = useState({
    totalData: [],
    filteredData: []
  });
  const [filter, setFilter] = useState(false);
  const [input, setInput] = useState({ title: '', jobType: '', location: '' });
  const [title, setTitle] = useState('');
  const [flag, showFlag] = useState(() => {
    return window.location.pathname.split('/')[3] === undefined ||
      (window.location.pathname.split('/')[3] !== 'freshers' &&
        window.location.pathname.split('/')[3] !== 'experts') ||
      window.location.pathname.split('/').length > 4
      ? false
      : true;
  });

  const handleSetFilter = () => {
    setInput({ ...input, jobType: '', location: '' });
    filter === true ? setFilter(false) : setFilter(true);
  };

  useEffect(() => {
    // console.log(window.location.pathname.split('/'));
    let str = window.location.pathname;
    let value = str.split('/')[3];
    value === 'freshers' ? setTitle('Fresher') : setTitle('Expert');

    // if (value === 'freshers') {
    //   axios.get('/fresher_openings.json').then((res) => {
    //     setjobOpenings({
    //       ...jobOpenings,
    //       totalData: res.data
    //     });
    //   });
    // } else {
    //   axios.get('/expert_openings.json').then((res) => {
    //     setjobOpenings({
    //       ...jobOpenings,
    //       totalData: res.data
    //     });
    //   });
    // }

    // Fetching openings from backend api.

    if (value === 'freshers') {
      axios
        .get(`${appConfig.api}/openings`)
        .then((res) => {
          console.log(res);
          setjobOpenings({
            ...jobOpenings,
            totalData: res.data.freshers
          });
        })
        .catch((err) => {
          toast.error(
            'Please try again later.Something went wrong while fetching openings.',
            { autoClose: 3000 }
          );
        });
    } else {
      axios
        .get(`${appConfig.api}/openings`)
        .then((res) => {
          setjobOpenings({
            ...jobOpenings,
            totalData: res.data.experts
          });
        })
        .catch((err) => {
          toast.error(
            'Please try again later.Something went wrong while fetching openings.',
            { autoClose: 3000 }
          );
        });
    }
  }, [window.location.pathname]);

  return (
    <>
      {flag ? (
        <div className='mt-[60px] text-black lg:mt-[155px] '>
          <div className='lg:pl-32 xl:pl-48 pb-10'>
            <div className={smallHeader + 'uppercase pt-40 ml-4 text-dark-3'}>Search</div>
            <div className={headingTextClass + 'mt-6 ml-4'}>
              <div className='lg:block pt-5'>
                <div>All the </div>
                <div>Current Openings</div>
              </div>
            </div>
            <div className='mt-8 ml-4 lg:text-xl sm:text-lg'>
              <div
                className='w-11/12 h-12 lg:h-16 flex  rounded-full xl:w-3/4 lg:text-3xl sm:text-2xl '
                style={{ border: '1px solid black' }}
              >
                <div className='w-[30%] xl:w-[20%] h-full '>
                  <Link
                    to={
                      title === 'Fresher'
                        ? '/join-us/joblist/experts'
                        : '/join-us/joblist/freshers'
                    }
                  >
                    <button className='h-full xl:w-full lg:w-1/4 bg-black rounded-l-full text-white sm:w-full'>
                      <div className='flex justify-evenly  items-center'>
                        <p className='text-base lg:text-3xl ml-4 mr-1'>{title}</p>
                        <div className='flex flex-col m-0 h-9'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6 lg:h-10 lg:w-10 '
                            viewBox='0 0 20 20'
                            fill='currentColor'
                          >
                            <path
                              fillRule='evenodd'
                              d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
                              clipRule='evenodd'
                            />
                          </svg>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6 lg:h-10 lg:w-10 '
                            viewBox='0 0 20 20'
                            fill='currentColor'
                          >
                            <path
                              fillRule='evenodd'
                              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                              clipRule='evenodd'
                            />
                          </svg>
                        </div>
                      </div>
                    </button>
                  </Link>
                </div>
                <input
                  type='text'
                  value={input.title}
                  placeholder='Job Title, Job Type, or Location etc.'
                  className='w-[80%] h-full rounded-r-full  pl-2 lg:pl-6 outline-none sm:text-xl xl:text-2xl '
                  onInput={(e) => {
                    setInput({
                      ...input,
                      title: e.target.value.toLowerCase()
                    });
                  }}
                />
              </div>
              <div className='flex  py-2 mb-8 mt-8 lg:mt-16 lg:mb-8 lg:ml-8 xl:w-1/2 sm:w-10/12'>
                {input.jobType === '' && input.location === '' ? (
                  <button
                    className='rounded-full border-black border-solid border-2 w-20 h-10 font-bold hover:bg-black hover:text-white'
                    onClick={handleSetFilter}
                  >
                    Filter
                  </button>
                ) : (
                  <button
                    className='rounded-full border-black border-solid border-2 w-32 h-10 font-bold text-red-600 hover:bg-black hover:text-white '
                    onClick={handleSetFilter}
                  >
                    Clear Filter
                  </button>
                )}

                {filter ? (
                  <div className='flex justify-evenly w-3/4  '>
                    <select
                      className='cursor-pointer outline-none font-bold  px-2'
                      onChange={(e) => {
                        setInput({ ...input, jobType: e.target.value });
                      }}
                    >
                      <option selected disabled>
                        Job Type
                      </option>
                      {jobTypeOptions.map((item, index) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                    <select
                      className='cursor-pointer outline-none font-bold px-2'
                      onChange={(e) => {
                        setInput({ ...input, location: e.target.value });
                      }}
                    >
                      <option selected disabled>
                        Location
                      </option>
                      {locationOptions.map((item, index) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='lg:ml-8 flex flex-col lg:w-4/5 '>
              {title === 'Fresher' ? (
                <JobCard data={jobOpenings} type='freshers' input={input} />
              ) : (
                <JobCard data={jobOpenings} type='experts' input={input} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Redirect to='/404' />
      )}
    </>
  );
};

export default JobList;
