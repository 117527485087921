import React from 'react';
import SliderButtons from '../SliderButtons';
import CardSlider from '../CardSlider';
import { smallHeader, headingTextClass, subHeader } from '../../../helpers/constants';

const ArtnScience = () => {
  const firstLines = (
    <span>
      <div>At Solutionec, we believe Data Science is more than just statistics.</div>
      <div>
        Data Science stands at the intersection where the science of discovery meets the
        art of creation.
      </div>
    </span>
  );

  return (
    <div className='pl-6 lg:pl-32 xl:pl-48 4k:pl-72  mt-24 lg:mt-40'>
      <div className={smallHeader + 'w-max'}>art & science</div>
      <div className={headingTextClass + 'mt-7 4k:mt-12 '}>
        <div className='block lg:hidden'>
          <div>"The Greatest</div>
          <div>Scientists are</div>
          <div>Artists as well"</div>
        </div>
        <div className='hidden lg:block xl:hidden'>
          <div>"The greatest</div>
          <div>scientists are artists as well"</div>
        </div>
        <div className='hidden xl:block'>
          "The Greatest Scientists are
          <div>Artists as well" - Albert Einstien</div>
        </div>
      </div>
      <div className={'text-xl xl:text-2xl 4k:text-4xl mt-6 lg:mt-4 xl:hidden'}>
        - Albert Einstien
      </div>
      <input className='read-more-state' id='read-more-controller' type='checkbox' />
      {/* gap-28 4k:gap-36 */}
      <div className='mt-4 xl:mt-0 tracking-[0.010rem]'>
        <div className={subHeader + 'read-more-wrap xl:text-[1.125rem] xxl:text-xl mt-8'}>
          <div className='block lg:hidden'>
            <span>
              <div>At Solutionec, we believe</div>
              <div>Data Science is more than just statistics.</div>
              <div>Data Science stands at the intersection</div>
              <div>where the science of discovery meets</div>
              <span>the art of creation.</span>
            </span>
          </div>
          <div className='hidden lg:block xl:hidden'>
            {firstLines}
            {/* <span>
              <div>
                At Solutionec, we believe Data Science is more than just statistics.
              </div>
              <div>Data Science stands at the intersection where the science of</div>
              <div>discovery meets the art of creation.</div>
            </span> */}
          </div>
          <div className='hidden xl:block 4k:hidden'>
            {firstLines}
            {/* <span>
              <div>
                At Solutionec, we believe Data Science is more than just statistics.
              </div>
              <div>
                Data Science stands at the intersection where the science of discovery
              </div>
            </span> */}
          </div>
          <div className='hidden 4k:block'>
            {firstLines}
            {/* <span>
              <div>
                At Solutionec, we believe Data Science is more than just statistics. Data
                Science stands
              </div>
              <div>
                at the intersection where the science of discovery meets the art of
                creation.
              </div>
            </span> */}
          </div>
          <span className='read-more-target'>
            <div className='mt-[1.25rem]'>
              <div>
                To unlock the true power of data, your business requires a data management
                partner who combines
              </div>
              <div>
                both the expertise of a scientist to identify the right data points and
                the visual ingenuity of an artist
              </div>
              <div>to interpret and display those data points.</div>
            </div>
            <div className='mt-[1.25rem]'>
              <div>
                By uniting these core competencies, we build advanced and accessible
                analytics/business intelligence
              </div>
              <div>
                solutions that transform your data into critical business insights and
                actions. With strong foundation in
              </div>
              <div>
                business intelligence within life science, we at Solutionec find our roots
                in solving the difficulties of
              </div>
              <div>
                bringing together all your data into compelling and accessible yet
                advanced analytics solutions.
              </div>
              <div>We specialize in the field of life sciences.</div>
            </div>
          </span>
        </div>
      </div>
      <div className='grid lg:flex justify-between mt-4 xl:mt-0 lg:items-center xl:items-end'>
        <label
          className='read-more-trigger hidden xl:inline-block text-header-primary text-lg leading-[1rem] 4k:text-4xl mt-6'
          htmlFor='read-more-controller'
        ></label>
        {/* <div className='hidden lg:block lg:pr-16 xl:pr-[15.313rem] 4k:pr-52'>
          <SliderButtons
            mainContainerClass=' flex items-center gap-5 4k:gap-7'
            buttonClass=' outline-none uppercase text-black border-2 border-black bg-light rounded-full hover:bg-black hover:text-light p-3 '
            svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
            svgContainerClass=' grid place-items-center '
            targetId='visions-card'
          />
        </div> */}
      </div>
      <CardSlider source='visions' />
    </div>
  );
};

export default ArtnScience;
