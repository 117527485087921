import React from 'react';
import { social_links, smallHeader } from '../../helpers/constants';

const FollowUs = () => {
  const copy = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  return (
    <div className=''>
      <div className='w-1/2  ml-[26rem] my-48'>
        <div className={smallHeader + 'text-dark-3 '}>Follow Us</div>
        <div className=' mt-8 '>
          <div
            className={
              ' text-black w-full  text-4xl lg:text-[2.4rem] lg:leading-[3.2rem] 4k:text-7xl font-light'
            }
          >
            <div>Stay tuned for more updates </div>
            <div> on our blog posts. Follow us</div>
            <div> on social media to know more.</div>
            {/* <div> @Solutionec_stories</div> */}
          </div>
        </div>

        <div className='mt-6 flex w-2/6 h-10 justify-between'>
          <div>
            <a
              href={social_links.facebook}
              target={'_blank'}
              className='text-black hover:text-black hover:no-underline lg:text-base'
              rel='noreferrer'
            >
              <div className='flex content-center h-full items-center'>
                <img
                  src='/assets/images/social-icons/Facebook_Icon.svg'
                  className='w-8 h-8'
                  alt='facebook'
                />
              </div>
            </a>
          </div>
          <div>
            <a
              href={social_links.linkedin}
              target={'_blank'}
              className='text-black hover:text-black hover:no-underline lg:text-base'
              rel='noreferrer'
            >
              <div className='flex content-center h-full items-center justify-between'>
                <img
                  src='/assets/images/social-icons/Linkedln_Icon.svg'
                  className='w-8 h-8'
                  alt='linkedin'
                />
              </div>
            </a>
          </div>
          <div>
            <a
              href={social_links.twitter}
              target={'_blank'}
              className='text-black  hover:text-black hover:no-underline lg:text-base'
              rel='noreferrer'
            >
              <div className='flex content-center h-full items-center justify-between'>
                <img
                  src='/assets/images/social-icons/Twitter_Icon.svg'
                  className='w-8 h-8'
                  alt='twitter'
                />
              </div>
            </a>
          </div>
          <div>
            <button
              onClick={copy}
              className='text-black hover:text-black hover:no-underline lg:text-base  mt-[3px]'
            >
              <div className='flex content-center h-full items-center justify-between'>
                <img
                  src='/assets/images/social-icons/Copy.svg'
                  className='w-8 h-8'
                  alt='copy'
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUs;
