import React from 'react';
import Section1 from './sections/Section1';
import EducationDetailsForm from './sections/EducationDetailsForm';

const EducationDetails = ({ setStatus }) => {
  return (
    <>
      <Section1
        subTitle='step 3'
        title='Education Details'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
      />
      <EducationDetailsForm setStatus={setStatus} />
    </>
  );
};

export default EducationDetails;
