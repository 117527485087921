import React, { useState, useEffect } from 'react';
import { setDataToLocalStorage, getDataFromLocalStorage } from '../../../helpers/util';
import { genderOptions, flagOptions, maritalOptions } from '../../../helpers/constants';
import Dropdown from './Dropdown';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';

/**
 * @desc  This is the step-1 of job application form which contains basic details of the job applicant.
 * @param {function} setStatus It is a useState function used to update the current form name.
 * @param {object} editable   It is used to enable/disable the fields and edit button based on the respective section in the review page.
 * @param {function} setEditable  It is a useState function used to update respective section status in the review page.
 * @param {string} value Name of the current form
 */
const ProfileInfoForm = ({ setStatus, editable, setEditable, value }) => {
  const [details, setDetails] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    gender: '',
    nationality: '',
    maritalStatus: '',
    speciallyAbled: '',
    primaryContactNumber: '',
    primaryEmailId: '',
    pan: '',
    currentAddress: '',
    permanentAddress: '',
    checkbox: false,
    linkedin: '',
    github: '',
    other: ''
  });

  const [error, setError] = useState(false);
  const [temp, setTemp] = useState();

  const regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/; // regex for PAN number validation.

  // Function to validate the form values
  const validateFormValues = () => {
    let {
      firstName,
      middleName,
      lastName,
      dob,
      gender,
      nationality,
      maritalStatus,
      speciallyAbled,
      primaryContactNumber,
      primaryEmailId,
      pan,
      currentAddress,
      permanentAddress
    } = details;

    if (
      firstName &&
      lastName &&
      dob &&
      gender &&
      nationality &&
      maritalStatus &&
      speciallyAbled &&
      primaryContactNumber &&
      primaryEmailId &&
      pan &&
      currentAddress &&
      permanentAddress
    ) {
      if (validator.isEmail(primaryEmailId) && pan.length === 10 && regex.test(pan)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  // Function to save the current form data to session storage and redirect the user to the next page i.e. education details
  const handleNext = () => {
    if (validateFormValues()) {
      setError(true);
    } else {
      let Data = getDataFromLocalStorage('formData');
      Data.profile_info = details;
      setDataToLocalStorage('formData', Data);
      setDataToLocalStorage('formStatus', 'Education Details');
      setStatus('Education Details');
    }
  };

  // Function to save the current form data to session storage and update the editable status.
  const handleSave = () => {
    let Data = getDataFromLocalStorage('formData');
    Data.profile_info = details;
    setDataToLocalStorage('formData', Data);
    setEditable({ ...editable, [value]: true });
  };

  useEffect(() => {
    // We are inserting data to local stoarge if user opens the form for the first time or else we will retrieve the data from the session storage.
    let Data = getDataFromLocalStorage('formData');
    setDetails(Data.profile_info);

    let dobInput = document.getElementById('dob-date');
    dobInput.max = new Date().toISOString().split('T')[0];
  }, []);

  // Whenever any field is updated we are updating the same in the session storage as well.
  useEffect(() => {
    let LocalStorageData = getDataFromLocalStorage('formData');
    LocalStorageData.profile_info = details;
    setDataToLocalStorage('formData', LocalStorageData);
  }, [details]);

  return (
    <>
      <div className='pb-12 xl:pl-48  text-black lg:pb-24 lg:mt-8  w-screen'>
        <div className='w-11/12 m-auto  lg:w-[70%] lg:m-0'>
          <div>
            <label className='text-lg font-bold lg:text-2xl'>
              Name <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
            </label>
            <div className='flex justify-between mt-6'>
              <input
                type='text'
                name='firstName'
                value={details.firstName}
                onInput={(e) => {
                  setDetails({ ...details, [e.target.name]: e.target.value });
                }}
                className={
                  'text-sm border-gray w-[30%] h-14 rounded-full px-4  outline-none lg:text-lg' +
                  (error && !details.firstName
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                placeholder='First Name'
                disabled={editable?.profile_info}
              />
              <input
                type='text'
                name='middleName'
                value={details.middleName}
                onInput={(e) => {
                  setDetails({ ...details, [e.target.name]: e.target.value });
                }}
                className={
                  'text-sm border-gray w-[30%] h-14 rounded-full px-4  outline-none lg:text-lg border-2'
                }
                placeholder='Middle Name'
                disabled={editable?.profile_info}
              />
              <input
                type='text'
                name='lastName'
                value={details.lastName}
                onInput={(e) => {
                  setDetails({ ...details, lastName: e.target.value });
                }}
                className={
                  'text-sm border-gray w-[30%] h-14 rounded-full px-4  outline-none lg:text-lg' +
                  (error && !details.lastName
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                placeholder='Last Name'
                disabled={editable?.profile_info}
              />
            </div>
          </div>
          <div className='flex flex-col justify-between mt-12 lg:flex-row'>
            <div className='flex flex-col w-full lg:w-[48%] '>
              <label className='text-lg font-bold lg:text-2xl'>
                DOB <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <div className='w-full  h-14 mt-6 rounded-full flex items-center  '>
                <input
                  id='dob-date'
                  type='date'
                  name='dob'
                  value={details.dob}
                  onInput={(e) => {
                    setDetails({ ...details, dob: e.target.value });
                  }}
                  className={
                    'session-date text-small w-full  h-full px-4 rounded-full  outline-none lg:text-lg ' +
                    (error && !details.dob
                      ? ' border-[1px] border-red-500'
                      : ' border-2 ')
                  }
                  disabled={editable?.profile_info}
                />
              </div>
            </div>
            <div className='flex flex-col w-full lg:w-[48%] '>
              <label className='text-lg font-bold lg:text-2xl'>
                Gender <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <div className='mt-6'>
                <Dropdown
                  name='gender'
                  value={details.gender}
                  setDetails={setDetails}
                  details={details}
                  error={error}
                  setValue='gender'
                  options={genderOptions}
                  placeholder='Select Option'
                  editableFlag={editable?.profile_info}
                  currentForm='Profile_Info'
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between mt-12 lg:flex-row'>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                Nationality <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <input
                type='text'
                name='nationality'
                value={details.nationality}
                onInput={(e) => {
                  setDetails({ ...details, nationality: e.target.value });
                }}
                className={
                  ' text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                  (error && !details.nationality
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                placeholder='Nationality'
                disabled={editable?.profile_info}
              />
            </div>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                Marital Status
                <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              {/* <input
                type='text'
                name='maritalStatus'
                value={details.maritalStatus}
                onInput={(e) => {
                  setDetails({ ...details, maritalStatus: e.target.value });
                }}
                className={
                  'text-small mt-6 border-gray  h-14 rounded-full px-4 outline-none lg:text-lg' +
                  (error && !details.maritalStatus
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                placeholder='Martial Status'
                disabled={editable?.profile_info}
              /> */}
              <div className='mt-6'>
                <Dropdown
                  name='maritalStatus'
                  value={details.maritalStatus}
                  setDetails={setDetails}
                  details={details}
                  error={error}
                  setValue='maritalStatus'
                  options={maritalOptions}
                  placeholder='Select Option'
                  editableFlag={editable?.profile_info}
                  currentForm='Profile_Info'
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between mt-12 lg:flex-row'>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                Specially Abled
                <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <div className='mt-6 '>
                <Dropdown
                  name='speciallyAbled'
                  value={details.speciallyAbled}
                  setDetails={setDetails}
                  details={details}
                  error={error}
                  setValue='speciallyAbled'
                  options={flagOptions}
                  placeholder='Select Option'
                  editableFlag={editable?.profile_info}
                  currentForm='Profile_Info'
                />
              </div>
            </div>
            <div className='flex flex-col  w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                Primary Contact No.
                <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <div
                className={
                  'mt-6 rounded-full' +
                  (error && !details.primaryContactNumber
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
              >
                <PhoneInput
                  country={'in'}
                  placeholder='enter your number'
                  disabled={editable?.profile_info}
                  // onlyCountries={['in', 'fr']}
                  value={details.primaryContactNumber}
                  inputStyle={{
                    border: 'none',
                    height: '52px',
                    width: '100%',
                    fontSize: '18px',
                    borderRadius: '50px',
                    marginLeft: '0px'
                  }}
                  buttonStyle={{
                    backgroundColor: 'white',
                    borderTopLeftRadius: '50%',
                    borderBottomLeftRadius: '50%',
                    border: 'none',
                    height: '85%',
                    marginLeft: '10px',
                    marginTop: '3px'
                  }}
                  dropdownStyle={{
                    width: window.innerWidth <= 500 ? '320px' : '380px',
                    borderRadius: '5px'
                    // border: '1px solid red',
                  }}
                  onChange={(number) => {
                    console.log(number);
                    setDetails({ ...details, primaryContactNumber: number });
                  }}
                  countryCodeEditable={false}
                />
              </div>
              {/* <input
                type='number'
                name='primaryContactNumber'
                value={details.primaryContactNumber}
                onInput={(e) => {
                  setDetails({ ...details, primaryContactNumber: e.target.value });
                }}
                className={
                  'text-small mt-6 border-gray  h-14 rounded-full px-4 outline-none lg:text-lg' +
                  (error && !details.primaryContactNumber
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                placeholder='Phone Number'
                disabled={editable?.profile_info}
              /> */}
            </div>
          </div>
          <div className='flex flex-col justify-between mt-12 lg:flex-row'>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                Primary Email ID
                <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <input
                type='email'
                name='primaryEmailId'
                value={details.primaryEmailId}
                onInput={(e) => {
                  setDetails({ ...details, primaryEmailId: e.target.value });
                }}
                className={
                  'text-small mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg' +
                  (error &&
                  (!details.primaryEmailId || !validator.isEmail(details.primaryEmailId))
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                placeholder='Email Address'
                disabled={editable?.profile_info}
              />
              {error &&
              details.primaryEmailId &&
              !validator.isEmail(details.primaryEmailId) ? (
                <span className='text-red-500 font-normal text-base  pl-[17px] mt-1 '>
                  Please enter the valid email id
                </span>
              ) : (
                ''
              )}
            </div>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                PAN <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <input
                type='text'
                name='pan'
                value={details.pan}
                onInput={(e) => {
                  if (e.target.value.length <= 10)
                    setDetails({ ...details, pan: e.target.value.toUpperCase() });
                }}
                className={
                  'text-small mt-6 border-gray  h-14 rounded-full px-4 outline-none lg:text-lg' +
                  (error && (!details.pan || !regex.test(details.pan))
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                placeholder='PAN Number'
                disabled={editable?.profile_info}
              />
              {error && details.pan && !regex.test(details.pan) ? (
                <span className='text-red-500 font-normal text-base  pl-[17px] mt-1 '>
                  Please enter valid PAN number
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='flex flex-col justify-between mt-12 lg:flex-row'>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                Current Address
                <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <textarea
                className={
                  ' rounded-[25px] h-24 mt-6 px-4 py-4 text-small resize-none outline-none lg:text-lg' +
                  (error && !details.currentAddress
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                rows='4'
                cols='50'
                name='currentAddress'
                value={details.currentAddress}
                onInput={(e) => {
                  setDetails({ ...details, currentAddress: e.target.value });
                }}
                placeholder='Enter your current address.....'
                disabled={editable?.profile_info}
              ></textarea>
            </div>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>
                Permanent Address
                <span style={{ color: 'red', fontWeight: 'normal' }}>*</span>
              </label>
              <textarea
                className={
                  'rounded-[25px] h-24 mt-6 px-4 py-4 text-small resize-none outline-none lg:text-lg' +
                  (error && !details.permanentAddress
                    ? ' border-[1px] border-red-500'
                    : ' border-2 ')
                }
                rows='4'
                cols='50'
                name='permanentAddress'
                value={details.permanentAddress}
                onInput={(e) => {
                  setDetails({ ...details, permanentAddress: e.target.value });
                }}
                placeholder='Enter your permanent address.....'
                disabled={editable?.profile_info}
              ></textarea>
            </div>
          </div>
          <div className=' w-full mt-2 '>
            <div className='flex w-[48%] pl-1 float-right'>
              <input
                type='checkbox'
                className='w-4 h-4 mt-[5px] cursor-pointer'
                onChange={(e) => {
                  if (e.target.checked) {
                    setDetails({ ...details, permanentAddress: details.currentAddress });
                  } else {
                    setDetails({ ...details, permanentAddress: '' });
                  }
                }}
              />
              <p className='text-lg ml-2  '>Same as the current address.</p>
            </div>
          </div>
          <div className='flex flex-col justify-between mt-12 lg:flex-row'>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>Linkedin</label>
              <input
                type='text'
                name='linkedin'
                value={details.linkedin}
                onInput={(e) => {
                  setDetails({ ...details, linkedin: e.target.value });
                }}
                className={
                  ' text-small border-2 mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg'
                }
                placeholder='Please enter the profile link'
                disabled={editable?.profile_info}
              />
            </div>
            <div className='flex flex-col w-full lg:w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>Github</label>
              <input
                type='text'
                name='github'
                value={details.github}
                onInput={(e) => {
                  setDetails({ ...details, github: e.target.value });
                }}
                className={
                  'text-small border-2 mt-6 border-gray  h-14 rounded-full px-4 outline-none lg:text-lg'
                }
                placeholder='Please enter the profile link'
                disabled={editable?.profile_info}
              />
            </div>
          </div>
          <div className='flex justify-between mt-12'>
            <div className='flex flex-col w-[48%]'>
              <label className='text-lg font-bold lg:text-2xl'>Other</label>
              <input
                type='text'
                name='other'
                value={details.other}
                onInput={(e) => {
                  setDetails({ ...details, other: e.target.value });
                }}
                className={
                  ' text-small border-2 mt-6 border-gray  h-14 rounded-full px-4  outline-none lg:text-lg'
                }
                placeholder='Please enter any other link'
                disabled={editable?.profile_info}
              />
            </div>
          </div>
          {error &&
          (!details.firstName ||
            !details.lastName ||
            !details.dob ||
            !details.gender ||
            !details.nationality ||
            !details.maritalStatus ||
            !details.speciallyAbled ||
            !details.primaryContactNumber ||
            !details.primaryEmailId ||
            !details.pan ||
            !details.currentAddress ||
            !details.permanentAddress) ? (
            <p className='mt-4 text-red-500 text-lg'>
              <span
                style={{
                  color: 'red',
                  fontWeight: 'normal',
                  marginRight: '4px',
                  fontSize: '20px'
                }}
              >
                *
              </span>
              Highlighted fields are mandatory
            </p>
          ) : error &&
            (!validator.isEmail(details.primaryEmailId) || !regex.test(details.pan)) ? (
            <p className='mt-4 text-red-500 text-lg'>
              <span
                style={{
                  color: 'red',
                  fontWeight: 'normal',
                  marginRight: '4px',
                  fontSize: '20px'
                }}
              >
                *
              </span>
              Please check the highlighted fields.
            </p>
          ) : (
            ''
          )}

          {setStatus ? (
            <div className='flex w-[330px]  h-full justify-between'>
              <button
                onClick={() => {
                  setDataToLocalStorage('formStatus', 'Documents');
                  setStatus('Documents');
                }}
                className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-4 lg:text-2xl lg:mt-16'
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handleNext();
                }}
                className='bg-black text-white text-xl mt-8 font-bold rounded-full px-10 py-3 lg:text-2xl lg:mt-16'
              >
                Next
              </button>
            </div>
          ) : (
            <button
              onClick={() => {
                handleSave();
              }}
              disabled={editable?.profile_info}
              className={
                'text-white text-xl mt-8 font-bold rounded-full px-10 py-3 lg:text-2xl lg:mt-16' +
                (editable?.profile_info ? ' bg-gray-400' : ' bg-black')
              }
            >
              Save
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileInfoForm;
