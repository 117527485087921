import Heading from '../components/landing-page/sucess_stories_inner/Heading';
import Body from '../components/landing-page/sucess_stories_inner/Body';
import Description from '../components/landing-page/sucess_stories_inner/Description';
import NewsLetter from '../components/landing-page/sucess_stories_inner/NewsLetter';
import { Redirect } from 'react-router-dom';

const SuccessStoriesInner = () => {
  const id = window.location.pathname.split('/').pop() - 1;

  const blogs = [
    {
      id: 1,
      imgSrc: '/assets/images/landing-page/blogs/Pandemic_Leaders.jpg',
      date: '21, Feb 2022',
      mainTitle: 'TOPIC',
      title1: 'Heading goes on',
      title2: 'Ends in Second line.',
      articleLink:
        'https://www.linkedin.com/pulse/post-pandemic-leaders-solutionec/?trackingId=EZOA8rfoTUm76cGQCE1erw%3D%3D',
      description: (
        <>
          <div className='mt-6 lg:ml-48 lg:w-4/5 w-11/12 m-auto'>
            <div className='lg:text-xl font-bold  '>
              In the post-pandemic world, although your leaders might be the same, their
              style of leadership has unquestionably taken a 360-degree turn. The onset of
              Covid-19 changed the way the leadership community envisioned team operations
              at the workplace. The past two years beyond the shadow of a doubt have
              pushed teams and their leaders over the limit, both physically and mentally.
              Some of us have lost our near and dear ones while some suffered huge
              financial losses, which has kickstarted economic turmoil at a larger level.
            </div>
            <div className='mt-6 lg:text-xl'>
              <div>
                Businesses, both private and government sectors have also taken a beating
                with people losing jobs or pay cuts leading to a state of imbalance in our
                day-to-day lives. Amidst this, the biggest challenge faced by corporates,
                especially by the leadership team has been – Management.
              </div>
            </div>
            <div className='mt-6 lg:text-xl'>
              With the “work from home” culture taking a front seat, leaders and managers
              have found themselves at a tough crossroad where they have to manage people
              and projects, while also striking the right balance of maintaining
              productivity and being empathetic to their resources.
            </div>
            <div className='mt-6 lg:text-xl'>
              As managers and leaders, it is crucial to understand the mental and physical
              state of their teams, especially during times like these, and engage with
              every individual accordingly. Today, this becomes pivotal in ensuring a
              smooth and effective workflow. It is also important to remind resources of
              their goals and help them work towards achieving them. Having regular
              touchpoints, involving in discussions on daily tasks and issues, guiding the
              team, and providing continuous feedback has helped maintain the momentum.
            </div>
            <div className='mt-6 lg:text-xl'>
              Though there is a sharp decline in F2F connect between colleagues and
              leaders, teams have developed a better bonding (with online meetings) by
              initiating various concepts such as ice-breaking events, fun activities,
              role-plays, etc., into their work culture. This is seldom seen in office
              culture.
              <div></div>
            </div>
            <div className='mt-6 lg:text-xl'>
              This has led to a better understanding, collaboration, teamwork, and overall
              effective management. Though this pandemic has been devastating on a larger
              scale, it has somehow brought people closer, mitigating the usual
              human-human disputes and a sense of peer values.
            </div>
            <div className='mt-6 lg:text-xl'>
              According to the experts from WHO and Anthony Fauci - Chief Medical Advisor
              to the President of the United States, we should not expect an end to this
              pandemic anytime soon since we are only in the first phase of the Covid
              pandemic. There are four more stages to come - deceleration, control,
              elimination, and finally eradication.
            </div>
            <div className='mt-6 lg:text-xl'>
              We still have a long way with this pandemic and till then let us all unite
              to fight this collectively, be responsible and make sure we work towards
              achieving our goals for the life we envisioned.
            </div>
          </div>
        </>
      ),
      author: 'Steve',
      role: 'Data Science, Solutionec'
    }
  ];
  const flag = !blogs[window.location.pathname.split('/').pop() - 1] ? false : true;

  return (
    <>
      {flag ? (
        <>
          <Heading
            mainTitle={blogs[id].mainTitle}
            title1={blogs[id].title1}
            title2={blogs[id].title2}
            author={blogs[id].author}
            date={blogs[id].date}
            imgSrc={blogs[id].imgSrc}
          />
          <Body date={blogs[id].date} author={blogs[id].author} role={blogs[id].role} />
          <Description />
          <NewsLetter />
        </>
      ) : (
        <Redirect to='/404' />
      )}
    </>
  );
};

export default SuccessStoriesInner;
