import React from 'react';
import Section1 from './sections/Section1';
import AcknowledgeForm from './sections/AcknowledgeForm';

const Acknowledge = ({ setStatus, jobInfo }) => {
  return (
    <>
      <Section1
        subTitle='step 6'
        title='Acknowledge & Proceed'
        message1='Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil in tenetur ipsa'
        message2='tempore? Accusantium, voluptatem. Quasi perferendis, nostrum reprehenderit sed'
      />
      <AcknowledgeForm setStatus={setStatus} jobInfo={jobInfo} />
    </>
  );
};

export default Acknowledge;
