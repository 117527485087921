import React from "react";
import SliderButtons from "../landing-page/SliderButtons";
import CardSlider from "../landing-page/CardSlider";
import { smallHeader, headingTextClass, subHeader } from '../../helpers/constants';

const PhotoLife = () => {



	return (
		<>

			<div className="bg-black w-full pb-12 pt-16 sol-dark-scroll">


				<div className='pl-16 lg:pl-52 xl:pl-60 4k:pl-72 text-light '>
					<img src="/assets/images/life-solutionec/photo.png" className="mb-20" >

					</img>
					<span className={smallHeader + ' text-light mb-8 '}>Photo</span>

					<div className={'mt-7 mb-5' + headingTextClass}>
						<div>
							<div>Company Section</div>
						</div>
					</div>
					<div className='grid lg:flex lg:justify-between lg:items-end pb-20'>
						<div className='mt-8 text-xl lg:text-lg text-white font-light '>
							<div>Our motto forever remains to become the better version </div>
							<div>of ourselves,every day, in a sustainable manner.</div>
						</div>

						<div className='hidden lg:block lg:pr-32 4k:pr-52'>
							<SliderButtons
								mainContainerClass=' flex items-end gap-5 4k:gap-7'
								buttonClass=' outline-none uppercase cursor-pointer text-white border-2 border-white bg-transparent rounded-full hover:bg-white hover:text-black p-3 '
								svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
								svgContainerClass=' grid place-items-center '
								targetId='photos'
							/>
						</div>
					</div>
					<div className='mt-10'>
						<CardSlider targetId={'photo'} source={'photos'}></CardSlider>
					</div>

				</div>
			</div>

		</>
	)
}

export default PhotoLife