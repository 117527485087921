import emailjs from 'emailjs-com';
import React, { useRef, useState } from 'react';
import { headingTextClass, smallHeader } from '../../../helpers/constants';
import Button from '../../Button';
import axios from 'axios';
import { appConfig } from '../../../appConfig';
import { toast } from 'react-toastify';
import {RiArrowDropDownLine} from 'react-icons/ri'
import { Loader } from 'rsuite';
const Messege = () => {
  const defaultData = {

    email: '',
    message: '',
    category: 'Please select the subject'
  };
  const [formData, setFormData] = useState(defaultData);
  const [formLoading, setFormLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([
    'Business',
    'Queries',
    'Careers'
  ]);
  const formRef = useRef();

  const handleChange = (name, value) => {
    setFormData(Object.assign({}, formData, { [name]: value }));
  };

  /**
   * checks if all the values in the data object are valid
   * and does not contain falsy values
   * @returns {boolean}
   */
  const checkForValidData = () => {
    return Object.values(formData).every(
      (value) =>
        value !== '' &&
        value !== undefined &&
        value !== null &&
        value !== 'Please select the subject'
    );
  };
  const checkEmailValue = () => {
    const emailPattern = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    /* eslint-enable */
    return (!emailPattern.test(formData.email))
      
  }
  const sendEmail = (e) => {
    e.preventDefault();
    if (checkForValidData()) {
      /* eslint-disable */
      if (checkEmailValue()) {
        return toast.error('Please enter a valid email address', {
          autoClose: 2000
        });

      }

      setFormLoading(true);

      /**
       * service_id, template_id, form, public_key
       */
      // emailjs
      //   .sendForm(
      //     'service_skudy2g',
      //     'template_4j2bi47',
      //     formRef.current,
      //     'GbMHA6Q8OKJq88qAm'
      //   )
      //   .then((result) => {
      //     if (result.text === 'OK') {
      //       setFormData(defaultData);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     setFormLoading(false);
      //   });

      console.log(formData, 'formData');
      let data = formData;
      // data['name'] = formData.from_name;

      axios
        .post(`${appConfig.api}/contact-us`, data)
        .then((result) => {
          console.log(result, 'results');
          if (result.data.message === 'Success') {
            setFormData(defaultData);
            toast.success('Message sent successfully.', { autoClose: 2000 });
          }
        })
        .catch((error) => {
          toast.error('Something went wrong please try again later.', {
            autoClose: 2000
          });
          console.log(error);
        })
        .finally(() => {
          setFormLoading(false);
          window.location.reload(false);
        });
    } else {
      alert('Please enter all the details');
    }
  };

  return (
    <div className='relative grid place-items-start mt-28 xl:mt-56 h-fit'>
      <div className='xl:ml-auto xl:w-55rem pl-6 xl:pl-0'>
       <img src='./assets/images/contacts-page/message_logo.png' className='w-16 h-8'></img>
        <div className={smallHeader}>LEAVE A MESSAGE</div>
        <div className={headingTextClass + 'mt-4 xl:mt-10 capitalize text-white'}>
          <div>To Understand</div>
          <div>Even Better than Before</div>
        </div>
      </div>
      <div className='grid gap-3 mt-12 xl:mt-24 w-full  relative'>
        {formLoading ? (
          <div className='absolute left-0 top-0 grid place-items-center sol-dark-scroll bg-[#242323f2] w-full h-full'>
            <div className='text-2xl text-white'>
            <Loader size="md" speed='slow' content="Sending..." />
            </div>
          </div>
        ) : null}

        <div className='w-2/5  ml-auto mr-auto flex justify-center ' >
        <RiArrowDropDownLine style={{position:'absolute',width:'5%',top:'17%',right:'35%',height:'9%'}} />
          <form className='w-4/5' ref={formRef}>
            <input
              type='email'
              name='email'
              value={formData.email}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              disabled={formLoading}
              className={'w-full rounded-3xl h-12  mt-4 px-8 focus:outline-none focus:shadow-outline' +
                (formData.email === ''
                  ? ' text-[#a7a7a7] bg-[#f4f4f4]'
                  :  formData.category !=='Please select the subject'
                  ? !checkEmailValue()?' text-black bg-white-400 border-solid border-black-400 border-2'
                  : ' text-red-600 bg-white-400 border-solid border-red-600 border-2':' text-black bg-white-400 border-solid border-black-400 border-2'
                  )
                }
              placeholder='Enter your email address'
            />
            <select
              name='category'
              id=''
              defaultValue={formData.category}
              className={
                'w-full rounded-3xl h-12  mt-4 border-solid  pl-8 leading-tight focus:outline-none focus:shadow-outline' +
                (formData.category == 'Please select the subject'
                  ? ' text-[#a7a7a7] bg-[#f4f4f4]'
                  : ' text-black bg-white-400 border-solid border-black-400 border-2')
              }
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              style={{
                appearance:'none'
              }}
            >
              <option value='Please select the subject' disabled>
                Please select the subject
              </option>
              {dropdownOptions.map((item, index) => (
                <option key={index} value={item} className='text-[#959595] bg-[#f4f4f4] text-base  hover:bg-black-400 hover:text-white-400'>
                  {item}
                </option>
              ))}
            </select>
            <textarea
              name='message'
              //  className='shadow appearance-none w-80 py-3 px-8 text-gray-200 bg-gray-900 leading-tight focus:outline-none focus:shadow-outline h-96'
              value={formData.message}
              className={'w-full rounded-3xl h-40  mt-4 px-8 py-2 leading-tight focus:outline-none focus:shadow-outline' +
                (formData.message === ''
                  ? ' text-[#a7a7a7] bg-[#f4f4f4]'
                  : ' text-black bg-white-400 border-solid border-black-400 border-2')}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              disabled={formLoading}
              placeholder='Message'
            ></textarea>
            <button
                  className={'border-2 rounded-full py-4 px-6 lg:py-4 lg:px-6 4k:px-12 4k:py-5 text-sm lg:text-base 4k:text-2xl lg:leading-4 lg:tracking-[0.050rem] w-max mt-8 mb-24 hover:bg-gray-900 hover:text-slate-200 hover:border-gray-400' +
                (checkForValidData() ? ('border-2 border-black text-white bg-black ') : ('text-[#a7a7a7] bg-[#f4f4f4]'))
              }
              disabled={formLoading || !checkForValidData() || checkEmailValue() }
              onClick={(e) => {
                sendEmail(e);
              }}
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Messege;
